define("labs-ceqr/fragments/public-schools/FutureResidentialDevelopment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    ps_students: Ember.computed('multipliers', function () {
      return Math.round(this.total_units * this.multipliers.ps);
    }),
    is_students: Ember.computed('multipliers', function () {
      return Math.round(this.total_units * this.multipliers.is);
    }),
    hs_students: Ember.computed('multipliers', function () {
      return Math.round(this.total_units * this.multipliers.hs);
    })
  });

  _exports.default = _default;
});