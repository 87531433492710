define("labs-ceqr/components/project-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    onPublicSchools: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName').includes('public-schools');
    }),
    onSummary: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName').includes('summary');
    })
  });

  _exports.default = _default;
});