define("labs-ceqr/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    projectPermissions: _emberData.default.hasMany('project-permissions'),
    editable_and_viewable_projects: _emberData.default.hasMany('projects'),
    editable_projects: _emberData.default.hasMany('project', {
      inverse: 'editors'
    }),
    viewable_projects: _emberData.default.hasMany('project', {
      inverse: 'viewers'
    })
  });

  _exports.default = _default;
});