define("labs-ceqr/templates/project/show/community-facilities/analysis-threshold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KIqAdgiy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n    \"],[5,\"project/detailed-analysis-banner\",[],[[\"@analysisNeeded\"],[[24,[\"model\",\"communityFacilitiesAnalysis\",\"potentialLibraryImpact\"]]]],{\"statements\":[[0,\"\\n      Does this project require a detailed analysis of potential impacts to library capacity?\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"project/detailed-analysis-banner\",[],[[\"@analysisNeeded\"],[[24,[\"model\",\"communityFacilitiesAnalysis\",\"potentialChildCareImpact\"]]]],{\"statements\":[[0,\"\\n      Does this project require a detailed analysis of potential impacts to child care center capacity?\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n    \"],[5,\"community-facilities/detailed-analysis-thresholds\",[],[[\"@analysis\",\"@project\"],[[24,[\"model\",\"communityFacilitiesAnalysis\"]],[24,[\"model\",\"project\"]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/community-facilities/analysis-threshold.hbs"
    }
  });

  _exports.default = _default;
});