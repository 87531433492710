define("labs-ceqr/templates/project/show/community-facilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Iu5unXyJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"[Community Facilities]\"],[[\"prepend\"],[false]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"showAnalysisSteps\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n      \"],[5,\"community-facilities/analysis-steps\",[],[[\"@project\"],[[22,\"project\"]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/community-facilities.hbs"
    }
  });

  _exports.default = _default;
});