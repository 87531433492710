define("labs-ceqr/helpers/get-layer-category-colors", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "labs-ceqr/layer-styles"], function (_exports, _slicedToArray2, _layerStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayerCategoryColors = getLayerCategoryColors;
  _exports.default = void 0;

  /**
   * Returns the colors for a given layer's styles as an iterable array of
   * category/color objects (to be used for formatting map legend).
   */
  function getLayerCategoryColors(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        layer = _params[0];

    return _layerStyles.colors[layer] ? Object.keys(_layerStyles.colors[layer]).map(function (category) {
      return {
        category: category,
        color: _layerStyles.colors[layer][category]
      };
    }) : [];
  }

  var _default = Ember.Helper.helper(getLayerCategoryColors);

  _exports.default = _default;
});