define("labs-ceqr/utils/sumMapBy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sumMapBy;

  function sumMapBy(array) {
    return array.reduce(function (acc, value) {
      if (value === undefined) return acc;
      return acc + parseFloat(value);
    }, 0);
  }
});