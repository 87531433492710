define("labs-ceqr/helpers/async-hash", ["exports", "ember-needs-async/helpers/async-hash"], function (_exports, _asyncHash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _asyncHash.default;
    }
  });
  Object.defineProperty(_exports, "asyncHash", {
    enumerable: true,
    get: function get() {
      return _asyncHash.asyncHash;
    }
  });
});