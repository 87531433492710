define("labs-ceqr/components/public-schools/existing-conditions/recently-built-schools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // ceqr_school_buildings dataset is a combination of two datasets with different metadata: lcgms dataset and bluebook dataset
    lcgmsMetadata: Ember.computed('analysis.dataPackage.schemas.ceqr_school_buildings.sources', function () {
      return this.analysis.dataPackage.schemas.ceqr_school_buildings.sources.find(function (source) {
        return source.name === 'lcgms';
      });
    }),
    actions: {
      save: function save() {
        var _this = this;

        this.set('saving', true);
        this.get('project').save().then(function () {
          return _this.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});