define("labs-ceqr/helpers/get-aggregate-percent", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAggregatePercent = getAggregatePercent;
  _exports.default = void 0;

  /**
   * Helper that calculates an aggregate modal split percent
   * for a full set of modal split data for all census tracts in a study selection.
   * Accepts multiple variables, so modal splits for a random subset of modes can be calculated.
   * @param {Object[]} allModalSplitData - array of Census Tract ModalSplits
   * @param {String[]} variables - array of mode variable codes
   * @param {Bool} includePctSign -- set to True to return a string with % symbol appended.
   * False for integer value. Defaults to True;
   */
  function getAggregatePercent(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 3),
        allModalSplitData = _params[0],
        variables = _params[1],
        includePctSign = _params[2];

    if (includePctSign == null) includePctSign = true;

    if (allModalSplitData && Array.isArray(allModalSplitData) && variables && Array.isArray(variables)) {
      // calculate the aggregate of the sums of given variables for all modal splits
      var partTotal = allModalSplitData.reduce(function (runningSum, modalSplit) {
        return runningSum + variables.reduce(function (runningSum, variable) {
          return modalSplit[variable] ? runningSum + modalSplit[variable].value : runningSum;
        }, 0);
      }, 0); // calculate the aggregate total for all modal splits

      var wholeTotal = allModalSplitData.reduce(function (runningSum, modalSplit) {
        return (// TODO: Write some alert for if any mode variables have undefined value?
          // Also, investigate whether users would like the wholeTotal to be invalidated if there's a modalSplit
          // with missing trans_commuter_total value.
          modalSplit.trans_commuter_total ? runningSum + modalSplit.trans_commuter_total.value : runningSum
        );
      }, 0); // calculate to percent

      var percent = partTotal / wholeTotal * 100; // return formatted percent

      return isNaN(percent) ? '-' : includePctSign ? "".concat(percent.toFixed(1), " %") : percent.toFixed(1);
    }
  }

  var _default = Ember.Helper.helper(getAggregatePercent);

  _exports.default = _default;
});