define("labs-ceqr/templates/components/analysis/data-package-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V2PKWSJe",
    "block": "{\"symbols\":[\"package\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui column\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"newDataAvailable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"margin-bottom: 10px;\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui label\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"exclamation orange circle icon\"],[8],[9],[0,\" New data available\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"margin-bottom: 10px;\"],[8],[0,\"\\n    \"],[5,\"ui-dropdown\",[[12,\"class\",\"selection fluid\"]],[[\"@selected\"],[[24,[\"currentPackage\",\"version\"]]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"availablePackages\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[11,\"data-value\",[23,1,[\"version\"]]],[10,\"class\",\"item\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"changeDataPackage\",[23,1,[]]],null]],[8],[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/analysis/data-package-selector.hbs"
    }
  });

  _exports.default = _default;
});