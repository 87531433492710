define("labs-ceqr/utils/boroughToAbbr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = boroughToAbbr;

  function boroughToAbbr(borough) {
    switch (borough) {
      case 'Manhattan':
        return 'mn';

      case 'Bronx':
        return 'bx';

      case 'Brooklyn':
        return 'bk';

      case 'Queens':
        return 'qn';

      case 'Staten Island':
        return 'si';

      default:
        return null;
    }
  }
});