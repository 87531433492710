define("labs-ceqr/templates/components/transportation/tdf/trip-generation-rates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zyU6UkDx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"ui header\"],[10,\"style\",\"margin-bottom: 10px\"],[8],[0,\"Trip Generation Rates\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"ui celled table\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"eight wide\"],[8],[0,\"Day\"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"eight wide\"],[11,\"data-test-total-per\",[24,[\"factor\",\"landUse\"]]],[8],[0,\"Total (Per \"],[1,[24,[\"factor\",\"tripGenRatePerUnit\"]],false],[0,\" \"],[1,[24,[\"factor\",\"unitName\"]],false],[0,\")\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"data-label\",\"Day\"],[8],[1,[24,[\"data\",\"weekday\",\"label\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"data-label\",\"Total\"],[8],[1,[24,[\"data\",\"weekday\",\"rate\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"data-label\",\"Day\"],[8],[1,[24,[\"data\",\"saturday\",\"label\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"data-label\",\"Total\"],[8],[1,[24,[\"data\",\"saturday\",\"rate\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n          \"],[5,\"transportation/tdf/table-note\",[],[[\"@tableName\",\"@factor\"],[\"tripGenerationRates\",[22,\"factor\"]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/tdf/trip-generation-rates.hbs"
    }
  });

  _exports.default = _default;
});