define("labs-ceqr/mirage/factories/project", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    viewOnly: false,
    name: _faker.default.address.streetName,
    buildYear: 2018,
    bbls: function bbls() {
      return ['1019730001'];
    },
    bblsGeojson: function bblsGeojson() {
      return {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [[-73.95944768975842, 40.80929214876363], [-73.96112392735277, 40.80699583564126], [-73.9635969491312, 40.808032096187006], [-73.96192107071839, 40.81033240266901], [-73.95944768975842, 40.80929214876363]]
          }
        }]
      };
    },
    totalUnits: 1000,
    seniorUnits: 0,
    affordableUnits: 0,
    commercialLandUse: function commercialLandUse() {
      return [];
    },
    industrialLandUse: function industrialLandUse() {
      return [];
    },
    communityFacilityLandUse: function communityFacilityLandUse() {
      return [];
    },
    parkingLandUse: function parkingLandUse() {
      return [];
    }
  });

  _exports.default = _default;
});