define("labs-ceqr/components/public-schools/summary", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeSchoolsLevel: 'ps',
    EC_active: false,
    NA_resdev: false,
    NA_schools: false,
    NA_utilchange: false,
    WA_schools: false,
    levelTotals: Ember.computed('activeSchoolsLevel', function () {
      switch (this.activeSchoolsLevel) {
        case 'ps':
          return this.analysis.psLevelTotals;

        case 'is':
          return this.analysis.isLevelTotals;

        case 'hs':
          return this.analysis.hsLevelTotals;

        default:
          return null;
      }
    }),
    EC_newSchoolsOpened: Ember.computed('activeSchoolsLevel', 'analysis.school_buildings', function () {
      // ceqr_school_buildings dataset is a combination of lcgms and bluebook datasets
      // lcgms dataset represents schools that were opened recently
      var lcgmsSchools = this.analysis.school_buildings.filter(function (school) {
        return school.source === 'lcgms';
      });
      var activeLevelSchools = lcgmsSchools.filterBy('level', this.activeSchoolsLevel);
      var enrollment = activeLevelSchools.mapBy('enroll').reduce(function (a, v) {
        return a + parseFloat(v);
      }, 0);
      var capacity = activeLevelSchools.mapBy('capacity').reduce(function (a, v) {
        return a + parseFloat(v);
      }, 0);
      return {
        enrollment: enrollment,
        capacity: capacity,
        activeLevelSchools: activeLevelSchools
      };
    }),
    NA_newResidentialDevelopment: Ember.computed('activeSchoolsLevel', function () {
      var _this = this;

      var developments = this.analysis.futureResidentialDev.map(function (b) {
        b.set('enrollment', b.get("".concat(_this.activeSchoolsLevel, "_students")));
        return b;
      });
      var enrollment = developments.mapBy("".concat(this.activeSchoolsLevel, "_students")).reduce(function (a, v) {
        return a + parseFloat(v);
      }, 0);
      return {
        enrollment: enrollment,
        developments: developments
      };
    }),
    NA_plannedSchools: Ember.computed('activeSchoolsLevel', 'levelTotals', function () {
      var _this2 = this;

      var capacity = this.levelTotals.scaCapacityIncrease;
      var schools = this.analysis.scaProjects.map(function (b) {
        return (0, _objectSpread2.default)({}, b, {
          capacity: b["".concat(_this2.activeSchoolsLevel, "_capacity")]
        });
      }).filter(function (b) {
        return b.includeInCapacity && b.capacity > 0;
      });
      return {
        capacity: capacity,
        schools: schools
      };
    }),
    NA_significantUtilChanges: Ember.computed('activeSchoolsLevel', 'analysis.buildings.[]', function () {
      var _this3 = this;

      var schools = this.analysis.buildings.map(function (b) {
        return (0, _objectSpread2.default)({}, b, {
          capacityDelta: parseFloat(b.capacityFuture) - parseFloat(b.capacity)
        });
      }).filter(function (b) {
        return 'capacityFuture' in b && parseFloat(b.capacity) !== parseFloat(b.capacityFuture) && b.level === _this3.activeSchoolsLevel;
      });
      var capacityDelta = schools.reduce(function (a, b) {
        return a + b.capacityDelta;
      }, 0);
      return {
        capacityDelta: capacityDelta,
        schools: schools
      };
    }),
    WA_newSchools: Ember.computed('activeSchoolsLevel', 'analysis.schoolsWithAction.[]', function () {
      var _this4 = this;

      var schools = this.analysis.schoolsWithAction.map(function (b) {
        return (0, _objectSpread2.default)({}, b, {
          capacity: parseFloat(b["".concat(_this4.activeSchoolsLevel, "_seats")])
        });
      });
      var capacity = schools.mapBy('capacity').reduce(function (a, v) {
        return a + parseFloat(v);
      }, 0);
      return {
        capacity: capacity,
        schools: schools
      };
    }),
    actions: {
      toggle: function toggle(prop) {
        this.toggleProperty(prop);
      }
    }
  });

  _exports.default = _default;
});