define("labs-ceqr/layer-styles/project-bbl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.projectBblStyles = _exports.projectBblColors = void 0;
  var projectBblColors = {
    line: '#d7191c'
  };
  _exports.projectBblColors = projectBblColors;
  var projectBblStyles = {
    bbls: {
      paint: {
        'line-color': projectBblColors.line,
        'line-width': 4,
        'line-blur': 1
      }
    }
  };
  _exports.projectBblStyles = projectBblStyles;
});