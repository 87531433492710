define("labs-ceqr/transforms/public-schools/sca-projects", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-data", "labs-ceqr/fragments/public-schools/ScaProject"], function (_exports, _objectSpread2, _emberData, _ScaProject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized.map(function (b) {
        return _ScaProject.default.create(b);
      });
    },
    serialize: function serialize(deserialized) {
      return deserialized.map(function (b) {
        return (0, _objectSpread2.default)({}, b);
      });
    }
  });

  _exports.default = _default;
});