define("labs-ceqr/components/public-schools/analysis-threshold/threshold-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    thresholdPsIsStudents: Ember.computed.alias('analysis.multipliers.thresholdPsIsStudents'),
    thresholdHsStudents: Ember.computed.alias('analysis.multipliers.thresholdHsStudents'),
    esMsEffectPopupText: Ember.computed('thresholdPsIsStudents', function () {
      return "Greater than ".concat(this.thresholdPsIsStudents, " elementary and middle school students triggers a detailed analysis.");
    }),
    hsEffectPopupText: Ember.computed('thresholdHsStudents', function () {
      return "Greater than ".concat(this.thresholdHsStudents, " high school students triggers a detailed analysis.");
    })
  });

  _exports.default = _default;
});