define("labs-ceqr/templates/project/show/transportation/existing-conditions/journey-to-work/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gSo7UdVE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[5,\"transportation/jtw-map\",[],[[\"@project\"],[[23,0,[\"project\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/transportation/existing-conditions/journey-to-work/map.hbs"
    }
  });

  _exports.default = _default;
});