define("labs-ceqr/helpers/humanize-geoid", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanizeGeoid = humanizeGeoid;
  _exports.default = void 0;

  function humanizeGeoid(params) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        geoid = _params[0];

    var tractId = geoid.substring(5, 9);
    var blockId = geoid.substring(9, 11);
    return parseFloat("".concat(tractId, ".").concat(blockId));
  }

  var _default = Ember.Helper.helper(humanizeGeoid);

  _exports.default = _default;
});