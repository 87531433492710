define("labs-ceqr/templates/project/show/transportation/tdf/trip-results/total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ds7M7OSt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"ui header\"],[10,\"style\",\"margin-bottom: 10px;\"],[8],[0,\"AM\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n  \"],[5,\"transportation/tdf/trip-results/person-trips\",[],[[\"@modes\",\"@results\"],[[24,[\"model\",\"transportationAnalysis\",\"transportationPlanningFactors\",\"firstObject\",\"activeModes\"]],[24,[\"model\",\"transportationAnalysis\",\"tripTotals\",\"personTrips\",\"am\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n  \"],[5,\"transportation/tdf/trip-results/vehicle-trips\",[],[[\"@vehicleTrips\"],[[24,[\"model\",\"transportationPlanningFactor\",\"tripResults\",\"vehicleTrips\",\"am\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/transportation/tdf/trip-results/total.hbs"
    }
  });

  _exports.default = _default;
});