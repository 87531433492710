define("labs-ceqr/helpers/mode-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modeLabel = modeLabel;
  _exports.default = void 0;

  function modeLabel(params) {
    switch (params[0]) {
      case 'auto':
        return Ember.String.htmlSafe('<i class="car icon"></i> Auto (Car, Truck, Van)');

      case 'taxi':
        return Ember.String.htmlSafe('<i class="taxi icon"></i> Taxicab');

      case 'bus':
        return Ember.String.htmlSafe('<i class="bus icon"></i> Bus (or Trolleybus)');

      case 'subway':
        return Ember.String.htmlSafe('<i class="subway icon"></i> Subway (or Elevated)');

      case 'railroad':
        return Ember.String.htmlSafe('<i class="train icon"></i> Railroad');

      case 'walk':
        return Ember.String.htmlSafe('Walked');

      case 'ferry':
        return Ember.String.htmlSafe('<i class="ship icon"></i> Ferryboat');

      case 'streetcar':
        return Ember.String.htmlSafe('Streetcar or Trolley Car');

      case 'bicycle':
        return Ember.String.htmlSafe('<i class="bicycle icon"></i> Bicycle');

      case 'motorcycle':
        return Ember.String.htmlSafe('<i class="motorcycle icon"></i> Motorcycle');

      case 'other':
        return Ember.String.htmlSafe('Other Means');

      case 'truck':
        return Ember.String.htmlSafe('<i class="truck icon"></i> Truck (delivery)');

      default:
        return params[0].capitalize();
    }
  }

  var _default = Ember.Helper.helper(modeLabel);

  _exports.default = _default;
});