define("labs-ceqr/helpers/percentage", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "labs-ceqr/utils/round"], function (_exports, _slicedToArray2, _round) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.percentage = percentage;
  _exports.default = void 0;

  function percentage(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref3[0];

    var _ref2$decimals = _ref2.decimals,
        decimals = _ref2$decimals === void 0 ? 0 : _ref2$decimals;
    return "".concat((0, _round.default)(value * 100, decimals), "%");
  }

  var _default = Ember.Helper.helper(percentage);

  _exports.default = _default;
});