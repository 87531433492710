define("labs-ceqr/templates/components/password-reset-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G5k/xks5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid container\"],[10,\"data-test-password-reset-form\",\"\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"six wide centered column\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui blue header\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        Reset your password\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui message\"],[8],[0,\"\\n      Enter a new password for your account.\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n      \"],[7,\"form\",false],[12,\"class\",[29,[\"ui large form \",[28,\"if\",[[24,[\"error\"]],\"error\"],null]]]],[3,\"action\",[[23,0,[]],\"resetPassword\",[24,[\"password\"]]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"ui left icon input\"],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"lock icon\"],[8],[9],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"password\",\"password\",[24,[\"password\"]],\"Password\"]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ui left error message\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n            Error\\n          \"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"\\n            \"],[1,[24,[\"error\",\"message\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"ui fluid large primary submit button\"],[10,\"type\",\"submit\"],[8],[0,\"\\n          Reset Password\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/password-reset-form.hbs"
    }
  });

  _exports.default = _default;
});