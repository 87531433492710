define("labs-ceqr/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    load: function load() {
      var _this = this;

      var userId = this.get('session.data.authenticated.tokenData.user_id');

      if (!Ember.isEmpty(userId)) {
        return this.get('store').findRecord('user', userId).then(function (user) {
          _this.set('user', user);
        });
      }

      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});