define("labs-ceqr/components/tr-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tablehover: Ember.inject.service(),
    tagName: 'tr',
    classNameBindings: ['hover'],
    hover: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.get('tablehover').on('hover', this, 'setHover');
      this.get('tablehover').on('unhover', this, 'removeHover');
    },
    willDestroyElement: function willDestroyElement() {
      this.get('tablehover').off('hover', this, 'setHover');
      this.get('tablehover').off('unhover', this, 'removeHover');
    },
    setHover: function setHover(_ref) {
      var source = _ref.source,
          id = _ref.id;
      if (id === this.get('id') && source === this.get('source')) this.set('hover', true);
    },
    removeHover: function removeHover() {
      this.set('hover', false);
    },
    mouseEnter: function mouseEnter() {
      this.get('tablehover').trigger('hover', {
        source: this.get('source'),
        id: this.get('id')
      });
    },
    mouseLeave: function mouseLeave() {
      this.get('tablehover').trigger('unhover', {
        source: this.get('source'),
        id: this.get('id')
      });
    }
  });

  _exports.default = _default;
});