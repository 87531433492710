define("labs-ceqr/templates/components/maintenance-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/5ixxcg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"hasUpcomingMaintenance\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"maintenance-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"maintenance-banner\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"maintenance-notice\"],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n          Please be advised that there will be scheduled maintenance from \"],[1,[23,0,[\"maintenanceStart\"]],false],[0,\" to \"],[1,[23,0,[\"maintenanceEnd\"]],false],[0,\". During that time, you may not be able to sign in to your account and/or possibly encounter errors using CEQR. We apologize for the inconvenience and thank you for your understanding. For help, please email CEQRApp@planning.nyc.gov.\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isMaintenancePeriod\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"maintenance-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"maintenance-banner\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"maintenance-notice\"],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n          Please be advised that we are currently performing scheduled maintenance from \"],[1,[23,0,[\"maintenanceStart\"]],false],[0,\" to \"],[1,[23,0,[\"maintenanceEnd\"]],false],[0,\". During that time, you may not be able to sign in to your account and/or possibly encounter errors using CEQR. We apologize for the inconvenience and thank you for your understanding. For help, please email CEQRApp@planning.nyc.gov.\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/maintenance-notice.hbs"
    }
  });

  _exports.default = _default;
});