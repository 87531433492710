define("labs-ceqr/utils/censusTractVariableForMode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.censusTractVariableForMode = censusTractVariableForMode;
  _exports.MODE_VARIABLE_LOOKUP = _exports.MODES = void 0;
  var MODES = ['auto', 'taxi', 'bus', 'subway', 'railroad', 'walk', 'ferry', 'streetcar', 'bicycle', 'motorcycle', 'other'];
  _exports.MODES = MODES;
  var MODE_VARIABLE_LOOKUP = {
    auto: 'trans_auto_total',
    taxi: 'trans_taxi',
    bus: 'trans_public_bus',
    subway: 'trans_public_subway',
    railroad: 'trans_public_rail',
    walk: 'trans_walk',
    ferry: 'trans_public_ferry',
    streetcar: 'trans_public_streetcar',
    bicycle: 'trans_bicycle',
    motorcycle: 'trans_motorcycle',
    other: 'trans_other'
  };
  _exports.MODE_VARIABLE_LOOKUP = MODE_VARIABLE_LOOKUP;

  function censusTractVariableForMode(mode) {
    return MODE_VARIABLE_LOOKUP[mode];
  }
});