define("labs-ceqr/helpers/get-split-moe", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSplitMoe = getSplitMoe;
  _exports.default = void 0;

  function getSplitMoe(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        modalSplitData = _params[0],
        variable = _params[1];

    var split = modalSplitData[variable];

    if (split && split.moe) {
      return "\xB1".concat(split.moe.toFixed(0));
    }

    return '';
  }

  var _default = Ember.Helper.helper(getSplitMoe);

  _exports.default = _default;
});