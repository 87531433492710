define("labs-ceqr/templates/ceqr-intro-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XJnAjoCM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"cell route-\",[22,\"currentRouteName\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-container\"],[8],[0,\"\\n\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"Welcome to CEQR App!\"],[9],[0,\"\\n\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"CEQR App is a new product from the NYC Department of City Planning that's here to help you work through the CEQR process more quickly\\n    and efficiently. By using the most up-to-date data sources, we have developed dynamic tools for several CEQR chapters to speed up the process\\n    and improve your workflow. Please don't hesitate with feedback, we're excited to improve this process together!\"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"Let's get started by creating a new project.\"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"data-test-new-project\",\"route\"],[\"ui green button\",\"intro page\",\"project.new\"]],{\"statements\":[[0,\"        New Project\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"mailto:Labs_DL@planning.nyc.gov?subject=CEQR App Feedback\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n      \"],[7,\"font\",true],[10,\"color\",\"#3232ff\"],[8],[0,\"Email Us\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"/imgs/ceqr_manual_cover_814x539.png\"],[10,\"alt\",\"ceqr manual cover\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/ceqr-intro-page.hbs"
    }
  });

  _exports.default = _default;
});