define("labs-ceqr/components/public-schools/existing-conditions/existing-conditions-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    displayWarning: Ember.computed('analysis.{esSchoolChoice,isSchoolChoice,subdistrictsFromDb}', function () {
      return this.analysis.esSchoolChoice || this.analysis.isSchoolChoice || this.analysis.subdistrictsFromDb.length > 1;
    })
  });

  _exports.default = _default;
});