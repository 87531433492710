define("labs-ceqr/helpers/get-layer-colors", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "labs-ceqr/layer-styles"], function (_exports, _slicedToArray2, _layerStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayerColors = getLayerColors;
  _exports.default = void 0;

  function getLayerColors(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        layer = _params[0];

    return _layerStyles.colors[layer] || {};
  }

  var _default = Ember.Helper.helper(getLayerColors);

  _exports.default = _default;
});