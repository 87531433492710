define("labs-ceqr/templates/project/show/public-schools/no-action/utilization-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCB47dEw",
    "block": "{\"symbols\":[],\"statements\":[[5,\"public-schools/no-action/doe-significant-util-changes\",[],[[\"@project\",\"@analysis\"],[[22,\"project\"],[24,[\"model\",\"publicSchoolsAnalysis\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/public-schools/no-action/utilization-changes.hbs"
    }
  });

  _exports.default = _default;
});