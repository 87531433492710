define("labs-ceqr/helpers/get-layer-style", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "labs-ceqr/layer-styles"], function (_exports, _slicedToArray2, _layerStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayerStyle = getLayerStyle;
  _exports.default = void 0;

  function getLayerStyle(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        layer = _params[0],
        type = _params[1];

    return _layerStyles.styles[layer][type];
  }

  var _default = Ember.Helper.helper(getLayerStyle);

  _exports.default = _default;
});