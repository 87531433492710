define("labs-ceqr/templates/components/project/chapter-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lvpYRT4d",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ui-dropdown\",[[12,\"class\",\"chapter-dropdown\"]],[[\"@selected\"],[[22,\"currentChapter\"]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[0,\"\\n    Select Chapter\\n  \"],[9],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"data-value\",\"community-facilities\"],[10,\"class\",\"item\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"gotoChapter\",\"community-facilities\"],null]],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"university icon\"],[8],[9],[0,\"\\n        Community Facilities\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"data-value\",\"public-schools\"],[10,\"class\",\"item\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"gotoChapter\",\"public-schools\"],null]],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"graduation cap icon\"],[8],[9],[0,\"\\n        Public Schools\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"data-value\",\"transportation\"],[10,\"class\",\"item\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"gotoChapter\",\"transportation\"],null]],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"car icon\"],[8],[9],[0,\"\\n        Transportation\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/project/chapter-dropdown.hbs"
    }
  });

  _exports.default = _default;
});