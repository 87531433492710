define("labs-ceqr/fragments/public-schools/SubdistrictTotals", ["exports", "labs-ceqr/utils/round"], function (_exports, _round) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * SubdistrictTotals is an EmberObject that runs the fundamental calculations of a public schools analysis.
   * It accepts a number attributes that come from the database, stored on the public-schools-analysis model.
   * A SubdistrictTotals object is created for every ps and is school, per subdistrict. And one SubdistrictTotals
   * for the borough wide hs analysis. (@todo a different object should probably exist specifically for hs analysis)
   *
   * @constructor
   * @param {string} level - ps, is, or hs
   *
   * @param {string} borough - Borough if level is hs
   * @param {integer} district - School district if level is ps or is
   * @param {integer} subdistrict - School subdistrict if level is ps or is
   *
   * @param {School[]} allBuildings - Array of all Schools received from the db
   *
   * @param {integer} studentMultiplier - Multiplier for the given level
   * @param {integer} enroll - Future enrollment for given level, subdistrict, and build year
   * @param {integer} students - Future students from sca enrollment projections and any user-inputed future housing development
   * @param {integer} scaCapacityIncrease - Additional school seats provided by future schools (from sca capital plan) user has included in analysis
   * @param {integer} newCapacityWithAction - Additional school seats provided by school built with project
   *
   * @todo rename `buildings` attribut to `schools`
   * @todo experiment with typescript for calculation fragments
   */
  var _default = Ember.Object.extend({
    buildings: Ember.computed('allBuildings', function () {
      var _this = this;

      if (this.level === 'hs') {
        return this.allBuildings.filter(function (b) {
          return b.level === 'hs';
        });
      }

      return this.allBuildings.filter(function (b) {
        return b.district === _this.district && b.subdistrict === _this.subdistrict && b.level === _this.level;
      });
    }),
    enrollmentTotal: Ember.computed('buildings.@each.enroll', function () {
      return this.buildings.mapBy('enroll').reduce(function (acc, value) {
        if (value === undefined) return acc;
        return acc + parseFloat(value);
      }, 0);
    }),
    capacityTotal: Ember.computed('buildings.@each.capacity', function () {
      return this.buildings.map(function (b) {
        return b.excluded ? 0 : b.capacity;
      }).reduce(function (acc, value) {
        if (value === undefined) return acc;
        return acc + parseFloat(value);
      }, 0);
    }),
    capacityTotalNoAction: Ember.computed('buildings.@each.capacityFuture', function () {
      return this.buildings.map(function (b) {
        return b.excluded ? 0 : b.capacityFuture;
      }).reduce(function (acc, value) {
        if (value === undefined) return acc;
        return acc + parseFloat(value);
      }, 0);
    }),
    seatsTotal: Ember.computed('buildings.@each.seats', function () {
      return this.buildings.mapBy('seats').reduce(function (acc, value) {
        if (value === undefined) return acc;
        return acc + parseFloat(value);
      }, 0);
    }),
    utilizationTotal: Ember.computed('enrollmentTotal', 'capacityTotal', function () {
      return (0, _round.default)(this.enrollmentTotal / this.capacityTotal, 3);
    }),
    // Totals across all subdistricts
    enrollmentMetaTotal: Ember.computed('allBuildings', function () {
      return this.allBuildings.filterBy('level', this.level).mapBy('enroll').reduce(function (acc, value) {
        if (value === undefined) return acc;
        return acc + parseFloat(value);
      }, 0);
    }),
    capacityMetaTotal: Ember.computed('allBuildings', function () {
      return this.allBuildings.filterBy('level', this.level).map(function (b) {
        return b.excluded ? 0 : b.capacity;
      }).reduce(function (acc, value) {
        if (value === undefined) return acc;
        return acc + parseFloat(value);
      }, 0);
    }),
    seatsMetaTotal: Ember.computed('allBuildings', function () {
      return this.allBuildings.filterBy('level', this.level).mapBy('seats').reduce(function (acc, value) {
        if (value === undefined) return acc;
        return acc + parseFloat(value);
      }, 0);
    }),
    utilizationMetaTotal: Ember.computed('enrollmentMetaTotal', 'capacityMetaTotal', function () {
      return (0, _round.default)(this.enrollmentMetaTotal / this.capacityMetaTotal, 3);
    }),
    // Aggregate Totals
    enrollExistingConditions: Ember.computed.alias('enrollmentTotal'),
    enrollNoAction: Ember.computed('enroll', 'students', function () {
      return this.enroll + this.students;
    }),
    enrollNoActionDelta: Ember.computed('enrollNoAction', 'enrollExistingConditions', function () {
      return this.enrollNoAction - this.enrollExistingConditions;
    }),
    capacityExisting: Ember.computed.alias('capacityTotal'),
    capacityFuture: Ember.computed.alias('capacityTotalNoAction'),
    capacityNoAction: Ember.computed('capacityFuture', 'scaCapacityIncrease', function () {
      return this.capacityFuture + this.scaCapacityIncrease;
    }),
    capacityNoActionDelta: Ember.computed('capacityExisting', 'capacityNoAction', function () {
      return this.capacityNoAction - this.capacityExisting;
    }),
    capacityWithAction: Ember.computed('capacityNoAction', 'newCapacityWithAction', function () {
      return this.capacityNoAction + this.newCapacityWithAction;
    }),
    capacityWithActionDelta: Ember.computed('capacityExisting', 'capacityNoAction', function () {
      return this.capacityWithAction - this.capacityExisting;
    }),
    capacityDifference: Ember.computed('capacityNoAction', 'capacityWithAction', function () {
      return this.capacityWithAction - this.capacityNoAction;
    }),
    capacityDeltaDifference: Ember.computed('capacityNoActionDelta', 'capacityWithActionDelta', function () {
      return this.capacityWithActionDelta - this.capacityNoActionDelta;
    }),
    seatsNoAction: Ember.computed('capacityNoAction', 'enrollNoAction', function () {
      return this.capacityNoAction - this.enrollNoAction;
    }),
    utilizationNoAction: Ember.computed('enrollNoAction', function () {
      return (0, _round.default)(this.enrollNoAction / this.capacityNoAction, 3);
    })
  });

  _exports.default = _default;
});