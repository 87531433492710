define("labs-ceqr/initializers/on-error", ["exports", "labs-ceqr/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var shouldThrowOnError = _environment.default.shouldThrowOnError;

  function initialize() {
    if (shouldThrowOnError) {
      Ember.onerror = function (err) {
        throw err;
      };

      Ember.RSVP.on('error', function (err) {
        throw err;
      });
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});