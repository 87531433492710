define("labs-ceqr/templates/components/mapbox/carto-vector-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wtj3fBvm",
    "block": "{\"symbols\":[\"registry\",\"&default\"],\"statements\":[[5,\"mapbox/layer-registry\",[],[[\"@didUpdateLayersRegistry\"],[[28,\"action\",[[23,0,[]],\"handleUpdatedLayersRegistry\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"mapboxSourceOptions\",\"tiles\"]]],null,{\"statements\":[[0,\"    \"],[5,\"mapbox-gl-source\",[],[[\"@map\",\"@sourceId\",\"@options\"],[[24,[\"map\",\"instance\"]],[28,\"if\",[[23,0,[\"sourceId\"]],[23,0,[\"sourceId\"]],[23,0,[\"elementId\"]]],null],[23,0,[\"mapboxSourceOptions\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"layer\"],[[28,\"component\",[\"mapbox/carto-layer\"],[[\"registerWithParent\",\"unregisterWithParent\",\"map\",\"tiles\",\"_parentElementId\"],[[28,\"action\",[[23,0,[]],[23,1,[\"registerLayer\"]]],null],[28,\"action\",[[23,0,[]],[23,1,[\"unregisterLayer\"]]],null],[24,[\"map\"]],[23,0,[\"mapboxSourceOptions\",\"tiles\"]],[28,\"if\",[[23,0,[\"sourceId\"]],[23,0,[\"sourceId\"]],[23,0,[\"elementId\"]]],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/mapbox/carto-vector-source.hbs"
    }
  });

  _exports.default = _default;
});