define("labs-ceqr/templates/components/transportation/analysis-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JjcwmaBB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui tiny steps\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"step\",\"project.show.transportation.analysis-threshold\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        Analysis Threshold\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n        Need for detailed analysis\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"data-test-transportation-step\",\"route\",\"model\"],[\"step\",\"planning-factors\",\"project.show.transportation.tdf.planning-factors\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        Planning Factors\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"data-test-transportation-step\",\"route\",\"model\"],[\"step\",\"trip-results\",\"project.show.transportation.tdf.trip-results\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        Trip Results\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/analysis-steps.hbs"
    }
  });

  _exports.default = _default;
});