define("labs-ceqr/templates/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ae5F6gu9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui centered grid\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui message\"],[8],[0,\"\\n        Woops! What you're looking for isn't here.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/four-oh-four.hbs"
    }
  });

  _exports.default = _default;
});