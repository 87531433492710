define("labs-ceqr/templates/components/transportation/data-source-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SwH1/Wzv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui basic top attached segment\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui secondary menu\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"class\",[29,[\"item \",[28,\"unless\",[[23,0,[\"switch\"]],\"active\"],null]]]],[12,\"data-test-censustracts-table-isrjtw\",\"false\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"switch\"]]],null],false]],[8],[0,\"\\n      \"],[1,[23,0,[\"falseLabel\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",false],[12,\"class\",[29,[\"item \",[28,\"if\",[[23,0,[\"switch\"]],\"active\"],null]]]],[12,\"data-test-censustracts-table-isrjtw\",\"true\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"switch\"]]],null],true]],[8],[0,\"\\n      \"],[1,[23,0,[\"trueLabel\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/data-source-toggle.hbs"
    }
  });

  _exports.default = _default;
});