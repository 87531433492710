define("labs-ceqr/utils/round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = round;

  // Found online: http://www.jacklmoore.com/notes/rounding-in-javascript/
  function round(value, decimals) {
    return Number("".concat(Math.round("".concat(value, "e").concat(decimals)), "e-").concat(decimals));
  }
});