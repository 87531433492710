define("labs-ceqr/templates/project/show/public-schools/analysis-threshold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uS5E9ltj",
    "block": "{\"symbols\":[],\"statements\":[[5,\"public-schools/analysis-threshold\",[],[[\"@analysis\"],[[24,[\"model\",\"publicSchoolsAnalysis\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/public-schools/analysis-threshold.hbs"
    }
  });

  _exports.default = _default;
});