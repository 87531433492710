define("labs-ceqr/templates/components/mapbox/feature-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMerJA/T",
    "block": "{\"symbols\":[\"mapMouseEvent\",\"intersectingFeatures\",\"&default\"],\"statements\":[[5,\"mapbox/current-mouse-event\",[],[[\"@mapboxEventName\",\"@map\"],[\"click\",[23,0,[\"map\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"mapbox/intersecting-features\",[],[[\"@map\",\"@point\",\"@options\"],[[23,0,[\"map\"]],[23,1,[\"point\"]],[28,\"hash\",null,[[\"layers\"],[[28,\"array\",[[23,0,[\"layerId\"]]],null]]]]]],{\"statements\":[[0,\"\\n    \"],[14,3,[[23,2,[]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/mapbox/feature-selector.hbs"
    }
  });

  _exports.default = _default;
});