define("labs-ceqr/routes/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (this.session.isAuthenticated || !transition.to.queryParams.token) {
        this.transitionTo('index');
      }
    },
    model: function model(params) {
      return {
        id: params.id
      };
    }
  });

  _exports.default = _default;
});