define("labs-ceqr/components/login-form", ["exports", "fetch", "labs-ceqr/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.login = {};
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.validate) {
        (0, _fetch.default)("".concat(_environment.default.host, "/auth/v1/validate"), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: this.validate
          })
        }).catch(function (err) {
          return _this.set('error', {
            message: err
          });
        }).then(function (res) {
          if (res.status === 200) {
            _this.flashMessages.add({
              message: 'Your account has been validated. Login to access.',
              type: 'success',
              sticky: true
            });
          } else {
            _this.flashMessages.add({
              message: 'Could not activate account. Invalid token.',
              type: 'error',
              sticky: true
            });
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.login').form({
        fields: {
          email: 'email',
          password: 'empty'
        }
      });
    },
    actions: {
      logIn: function logIn(user) {
        var _this2 = this;

        var authenticator = 'authenticator:jwt'; // or 'authenticator:jwt'

        this.get('session').authenticate(authenticator, user).catch(function (error) {
          var _JSON$parse = JSON.parse(error.text),
              message = _JSON$parse.message;

          _this2.set('error', {
            message: message
          });
        });
      }
    }
  });

  _exports.default = _default;
});