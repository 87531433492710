define("labs-ceqr/templates/components/community-facilities/analysis-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oha2XxOf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui tiny steps fluid\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"step\",\"project.show.community-facilities.analysis-threshold\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        Analysis Threshold\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n        Need for detailed analysis\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"step disabled\",\"project.show.community-facilities\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        Existing Conditions\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"step disabled\",\"project.show.community-facilities\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        No Action Scenario\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"step disabled\",\"project.show.community-facilities\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        With Action Scenario\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/community-facilities/analysis-steps.hbs"
    }
  });

  _exports.default = _default;
});