define("labs-ceqr/services/mapservice", ["exports", "@turf/bbox"], function (_exports, _bbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('map', {});
    },
    fitToSubdistricts: function fitToSubdistricts(geojson) {
      this.get('map').fitBounds((0, _bbox.default)(geojson));
    }
  });

  _exports.default = _default;
});