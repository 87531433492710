define("labs-ceqr/templates/components/transportation/tdf/modal-splits/census-tracts-map/census-tract-popup/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "me3Cnmlv",
    "block": "{\"symbols\":[\"geoid\",\"modalSplitData\",\"&default\"],\"statements\":[[5,\"throttle-property\",[],[[\"@property\"],[[23,0,[\"geoid\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"needs-async\",[],[[\"@needs\"],[[28,\"find-modal-split\",[[23,1,[]]],null]]],{\"statements\":[[0,\"\\n    \"],[14,3,[[23,2,[]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/tdf/modal-splits/census-tracts-map/census-tract-popup/data.hbs"
    }
  });

  _exports.default = _default;
});