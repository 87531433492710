define("labs-ceqr/templates/signup/in-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PS4sdJg8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"six wide centered center aligned column\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui blue header\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        Your account is in review\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      The CEQR App team has been notified of your request and will respond shortly.\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Note: please give the team a week to review.\\n    \"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"ui button primary\",\"login\"]],{\"statements\":[[0,\"      Return to Login\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/signup/in-review.hbs"
    }
  });

  _exports.default = _default;
});