define("labs-ceqr/mirage/factories/transportation-analysis", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    trafficZone: 2,
    requiredCensusTractsSelection: function requiredCensusTractsSelection() {
      return ['36061020300'];
    },
    censusTractsSelection: function censusTractsSelection() {
      return ['36061020500', '36061021100', '36061019701', '36061020701', '36061020101', '36061019900'];
    },
    censusTractsCentroid: function censusTractsCentroid() {
      return {
        features: [{
          geometry: {
            coordinates: [-73.964251, 40.8080809]
          }
        }]
      };
    },
    modesForAnalysis: function modesForAnalysis() {
      return ['auto', 'taxi', 'bus', 'subway', 'walk', 'railroad'];
    },
    inOutDists: function inOutDists() {
      return {
        am: {
          in: 50,
          out: 50
        },
        md: {
          in: 50,
          out: 50
        },
        pm: {
          in: 50,
          out: 50
        },
        saturday: {
          in: 50,
          out: 50
        }
      };
    },
    taxiVehicleOccupancy: function taxiVehicleOccupancy() {
      return null;
    }
  });

  _exports.default = _default;
});