define("labs-ceqr/mirage/factories/bbl", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    bbl: '1001440001',
    version: 'mappluto_18v2'
  });

  _exports.default = _default;
});