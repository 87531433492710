define("labs-ceqr/components/public-schools/with-action/new-schools", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.school = {};
    },
    actions: {
      addSchool: function addSchool(_ref) {
        var name = _ref.name,
            subdistrict = _ref.subdistrict,
            ps_seats = _ref.ps_seats,
            is_seats = _ref.is_seats,
            hs_seats = _ref.hs_seats;
        this.get('analysis.schoolsWithAction').pushObject((0, _objectSpread2.default)({}, subdistrict, {
          name: name,
          ps_seats: ps_seats || 0,
          is_seats: is_seats || 0,
          hs_seats: hs_seats || 0
        }));
        this.get('analysis').save();
        this.set('school', {});
      },
      removeSchool: function removeSchool(school) {
        this.get('analysis.schoolsWithAction').removeObject(school);
        this.get('analysis').save();
      }
    }
  });

  _exports.default = _default;
});