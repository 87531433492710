define("labs-ceqr/services/readonly-ceqr-data-store", ["exports", "labs-ceqr/utils/modalSplit"], function (_exports, _modalSplit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A datastore for readonly CEQR data that does not fit nicely into the normal EmberData model.
   * Specifically: modal-split data is composed of multiple records from the backend rails
   * service, but conceptually is easier to treat as a single object with geoid as identifier.
   *
   * At present, requires a custom fetchAndSave[OBJECT] function for any data-types that will be stored.
   * fetchAndSave[OBJECT] should return a promise that resolves to the requested object. It should take
   * a reference to this store as an argument, and add the requested object to the store before returning.
   */
  var _default = Ember.Service.extend({
    /**
     * Session service, required to make authenticated calls to the backend rails service
     */
    session: Ember.inject.service('session'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('storeHash', {});
    },

    /**
     * Adds an object to the store
     * @param type The object type
     * @param id The object's identifier
     * @param value The object
     */
    add: function add(type, id, value) {
      var store = this.get('storeHash');

      if (!store[type]) {
        store[type] = {};
      }

      store[type][id] = value;
    },

    /**
     * Returns a promise that resolves to an array of objects
     * @param type The object type
     * @param ids The objects' identifiers
     * @returns Promise
     */
    findByIds: function findByIds(type, ids) {
      var _this = this;

      return Promise.all(ids.map(function (id) {
        return _this.find(type, id);
      }));
    },

    /**
     * Returns a promise that resolves to the record, either from the local store
     * or from the backend via _fetch().
     * @param type The object's type
     * @param id The object's identifier
     * @returns Promise
     */
    find: function find(type, id) {
      var record = this.getRecord(type, id);

      if (record) {
        return new Promise(function (resolve) {
          resolve(record);
        });
      }

      return this._fetch(type, id);
    },

    /**
     * Retrieves a record from the local store, or false if it does not exist yet.
     * @param type The object type
     * @param id The object's identifier
     * @returns The object or false
     */
    getRecord: function getRecord(type, id) {
      if (this.get("storeHash.".concat(type))) {
        return this.get("storeHash.".concat(type, ".").concat(id));
      }
    },

    /**
     * Retrieves the resource from the rails backend, if fetch has been implemented
     * for the given object type.
     * @param type The object type
     * @param id The obejct's identifier
     * @returns Promise that resolves to the formatted object, or rejects with error message if not implemented
     */
    _fetch: function _fetch(type, id) {
      var session = this.get('session');

      if (type === 'ACS-modal-split') {
        return (0, _modalSplit.fetchAndSaveModalSplit)('ACS', id, session, this);
      }

      if (type === 'CTPP-modal-split') {
        return (0, _modalSplit.fetchAndSaveModalSplit)('CTPP', id, session, this);
      }

      return new Promise(function (resolve, reject) {
        reject("Fetch for ".concat(type, " not implemented"));
      });
    }
  });

  _exports.default = _default;
});