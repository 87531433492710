define("labs-ceqr/templates/request-password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XckN/97C",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"auth/request-password-reset-form\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/request-password-reset.hbs"
    }
  });

  _exports.default = _default;
});