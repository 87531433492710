define("labs-ceqr/mirage/factories/community-facilities-analysis", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    childCareImpact: (0, _emberCliMirage.trait)({
      project: (0, _emberCliMirage.association)({
        bbls: ['2024130001'],
        affordableUnits: 142
      })
    }),
    noChildCareImpact: (0, _emberCliMirage.trait)({
      project: (0, _emberCliMirage.association)({
        bbls: ['2024130001'],
        affordableUnits: 1,
        totalUnits: null
      })
    }),
    libraryImpact: (0, _emberCliMirage.trait)({
      project: (0, _emberCliMirage.association)({
        bbls: ['2024130001'],
        totalUnits: 683
      })
    }),
    noLibraryImpact: (0, _emberCliMirage.trait)({
      project: (0, _emberCliMirage.association)({
        bbls: ['2024130001'],
        totalUnits: 1
      })
    })
  });

  _exports.default = _default;
});