define("labs-ceqr/templates/components/public-schools/with-action/steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4JJ3tSv5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui pointing menu\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"item\",\"project.show.public-schools.with-action.scenario\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    With Action Scenario\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"right menu\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"item\",\"project.show.public-schools.with-action.new-schools\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"      With Action\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      Additional Schools\\n      \"],[7,\"div\",true],[10,\"class\",\"ui label blue\"],[8],[0,\"\\n        \"],[1,[24,[\"analysis\",\"schoolsWithAction\",\"length\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/public-schools/with-action/steps.hbs"
    }
  });

  _exports.default = _default;
});