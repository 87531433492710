define("labs-ceqr/models/project-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    project: _emberData.default.belongsTo('project'),
    user: _emberData.default.belongsTo('user'),
    permission: _emberData.default.attr('string'),
    userId: _emberData.default.attr('number'),
    projectId: _emberData.default.attr('number')
  });

  _exports.default = _default;
});