define("labs-ceqr/components/top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    actions: {
      saveProject: function saveProject() {
        this.get('project').save();
      },
      logOut: function logOut() {
        this.get('session').invalidate();
      }
    }
  });

  _exports.default = _default;
});