define("labs-ceqr/templates/components/general-disclaimer-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cO/PeGTq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"strong\",true],[8],[0,\"\\n  Disclaimer\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"disclaimer\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    You are viewing a prototype tool that can assist city agencies and project teams to conduct certain quantitative analyses required by City Environmental Quality Review (CEQR).\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    The tool is based on the 2014 CEQR Technical Manual (CTM) and uses datasets developed in consultation between DCP and expert agencies. Because the CTM provides guidelines for analysis rather than rules, the conclusions displayed in the tool are preliminary and will not reflect departures in analytical approach from CTM guidelines when deemed appropriate by the lead agency.\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/general-disclaimer-message.hbs"
    }
  });

  _exports.default = _default;
});