define("labs-ceqr/validations/project", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validateLength)({
      min: 3
    }),
    buildYear: (0, _validators.validatePresence)(true),
    bbls: (0, _validators.validateLength)({
      min: 1,
      message: 'The project should have at least one BBL'
    })
  };
  _exports.default = _default;
});