define("labs-ceqr/templates/signup/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xx5/jvJ3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"six wide centered center aligned column\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui blue header\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        Almost there!\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      We've sent a confirmation email to your account.\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      Find it and click through to confirm your email address.\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Note: it might take a minute or two to arrive.\\n    \"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"ui button primary\",\"login\"]],{\"statements\":[[0,\"      Log In\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/signup/email.hbs"
    }
  });

  _exports.default = _default;
});