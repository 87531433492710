define("labs-ceqr/components/transportation/traffic-zone-map", ["exports", "mapbox-gl", "@turf/centroid"], function (_exports, _mapboxGl, _centroid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    map: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('zonePopup', new _mapboxGl.default.Popup({
        closeButton: false,
        closeOnClick: false
      }));
    },
    moveTransportationZones: function moveTransportationZones(data) {
      if (data.dataType === 'source' && data.isSourceLoaded && data.source.id === 'transportation-zones') {
        this.get('map').moveLayer('transportation-zones', 'bbls');
        this.get('map').off('data', this.get('moveTransportationZones'));
      }
    },
    actions: {
      zoneHover: function zoneHover(e) {
        this.get('map').getCanvas().style.cursor = 'default';
        this.get('zonePopup').setLngLat(e.lngLat).setHTML("<div class=\"traffic-zone-popup\">\n          Traffic Zone <div class=\"ui grey circular label\">".concat(e.features[0].properties.zone, "</div>\n        </div>")).addTo(this.get('map'));
      },
      zoneUnhover: function zoneUnhover() {
        this.get('map').getCanvas().style.cursor = '';
        this.get('zonePopup').remove();
      },
      handleMapLoad: function handleMapLoad(map) {
        map.addControl(new _mapboxGl.default.ScaleControl({
          unit: 'imperial'
        }), 'bottom-right');
        map.on('data', this.get('moveTransportationZones').bind(this));
        map.flyTo({
          center: (0, _centroid.default)(this.project.get('bblsGeojson.features.firstObject')).geometry.coordinates,
          zoom: 14
        });
        this.set('map', map);
      }
    }
  });

  _exports.default = _default;
});