define("labs-ceqr/components/public-schools/no-action/doe-significant-util-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.analysis.doeUtilChangesPerBldg) this.set('bldg_id', this.analysis.doeUtilChangesPerBldg[0].bldg_id);
    },
    hasSigUtils: Ember.computed('analysis.doeUtilChangesPerBldg', function () {
      return this.analysis.doeUtilChangesPerBldg.length !== 0;
    }),
    actions: {
      showBldg: function showBldg(bldg_id) {
        this.set('bldg_id', bldg_id);
      },
      save: function save() {
        var _this = this;

        this.set('saving', true);
        this.get('analysis').save().then(function () {
          return _this.set('saving', false);
        });
      }
    }
    /*
    [
      {
        bldg_id: 'K298',
        buildings: [** all buildings with id],
        doe_notices: [** all notices with id]
      }
    ]
    */

  });

  _exports.default = _default;
});