define("labs-ceqr/templates/components/mapbox/current-mouse-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ahcfy+N8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"mapbox-gl-on\",[],[[\"@event\",\"@action\",\"@eventSource\"],[\"mousemove\",[28,\"action\",[[23,0,[]],\"handleMouseMove\"],null],[24,[\"map\",\"instance\"]]]]],[0,\"\\n\\n\"],[5,\"mapbox-gl-on\",[],[[\"@event\",\"@action\",\"@eventSource\"],[\"mouseout\",[28,\"action\",[[23,0,[]],\"handleMouseLeave\"],null],[24,[\"map\",\"instance\"]]]]],[0,\"\\n\\n\"],[14,1,[[23,0,[\"mousePosition\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/mapbox/current-mouse-position.hbs"
    }
  });

  _exports.default = _default;
});