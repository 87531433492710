define("labs-ceqr/components/td-hover", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tablehover: Ember.inject.service(),
    tagName: 'td',
    classNameBindings: ['hover'],
    attributeBindings: ['rowspan'],
    hover: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.get('tablehover').on('hover', this, 'setHover');
      this.get('tablehover').on('unhover', this, 'removeHover');
    },
    willDestroyElement: function willDestroyElement() {
      this.get('tablehover').off('hover', this, 'setHover');
      this.get('tablehover').off('unhover', this, 'removeHover');
    },
    setHover: function setHover(payload) {
      if ((0, _lodash.isEqual)(payload, this.get('payload'))) this.set('hover', true);
    },
    removeHover: function removeHover(payload) {
      if ((0, _lodash.isEqual)(payload, this.get('payload'))) this.set('hover', false);
    },
    mouseEnter: function mouseEnter() {
      this.get('tablehover').trigger('hover', this.get('payload'));
    },
    mouseLeave: function mouseLeave() {
      this.get('tablehover').trigger('unhover', this.get('payload'));
    }
  });

  _exports.default = _default;
});