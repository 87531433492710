define("labs-ceqr/components/transportation/traffic-zone-thresholds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.trafficZonesView = 'thresholds';
    }
  });

  _exports.default = _default;
});