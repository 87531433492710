define("labs-ceqr/carto-promises-utility/tests/addon.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | addon');
  QUnit.test('addon/utils/carto.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/carto.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/normalize-carto-vectors.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/normalize-carto-vectors.js should pass ESLint\n\n');
  });
});