define("labs-ceqr/templates/components/transportation/tdf/table-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gma0HxmZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"strong\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"emptyNote\"]],[28,\"not\",[[24,[\"addingNote\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"style\",\"cursor: pointer\"],[10,\"class\",\"ui link blue\"],[10,\"data-test-button\",\"add note\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"addingNote\"]]],null],true],null]],[8],[0,\"\\n      Add Note\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"addingNote\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"style\",\"cursor: pointer\"],[10,\"class\",\"ui link blue\"],[10,\"data-test-button\",\"save note\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],[24,[\"saveNote\"]]],null]],[8],[0,\"\\n      Save Note\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"style\",\"cursor: pointer\"],[10,\"class\",\"ui link blue\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"addingNote\"]]],null],true],null]],[8],[0,\"\\n      Edit Note\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"addingNote\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n      \"],[1,[28,\"textarea\",null,[[\"name\",\"value\",\"rows\",\"data-test-text-area\"],[\"note\",[24,[\"tableNote\"]],\"4\",\"table note\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"emptyNote\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"style\",\"color: #767676\"],[10,\"data-test-text\",\"table note\"],[8],[1,[22,\"tableNote\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/tdf/table-note.hbs"
    }
  });

  _exports.default = _default;
});