define("labs-ceqr/layer-styles/index", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "labs-ceqr/layer-styles/subway", "labs-ceqr/layer-styles/selectable-feature", "labs-ceqr/layer-styles/project-bbl", "labs-ceqr/layer-styles/transit-zone", "labs-ceqr/layer-styles/land-use", "labs-ceqr/layer-styles/pluto-line", "labs-ceqr/layer-styles/pluto-labels"], function (_exports, _objectSpread2, _subway, _selectableFeature, _projectBbl, _transitZone, _landUse, _plutoLine, _plutoLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.colors = _exports.styles = void 0;
  var styles = (0, _objectSpread2.default)({}, _subway.subwayStyles, {}, _selectableFeature.selectableFeatureStyles, {}, _projectBbl.projectBblStyles, {}, _transitZone.transitZoneStyles, {}, _landUse.landUseStyles, {}, _plutoLine.plutoLineStyles, {}, _plutoLabels.plutoLabelsStyles);
  _exports.styles = styles;
  var colors = {
    'transit-zone': _transitZone.transitZoneColors,
    'land-use': _landUse.landUseColors,
    'selectable-features': _selectableFeature.selectableFeatureColors,
    'project-bbls': _projectBbl.projectBblColors
  };
  _exports.colors = colors;
});