define("labs-ceqr/templates/components/project/detailed-analysis-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CKfSZ8Tc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"analysisNeeded\"]],\"yellow\",\"grey\"],null],\" segment\"]]],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"ui big horizontal label \",[28,\"if\",[[24,[\"analysisNeeded\"]],\"yellow\",\"grey\"],null]]]],[8],[0,\"\\n      \"],[1,[28,\"if\",[[24,[\"analysisNeeded\"]],\"Yes\",\"No\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/project/detailed-analysis-banner.hbs"
    }
  });

  _exports.default = _default;
});