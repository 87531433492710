define("labs-ceqr/templates/signup/approve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZLmgaLZn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"six wide centered center aligned column\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"status\"]],200],null]],null,{\"statements\":[[0,\"      \"],[7,\"h2\",true],[10,\"class\",\"ui blue header\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n          Successful account activation\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        The account for user \"],[7,\"strong\",true],[8],[1,[24,[\"model\",\"body\",\"email\"]],false],[9],[0,\" has been approved. They have been notified by email.\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"h2\",true],[10,\"class\",\"ui blue header\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n          An error occured\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        We were not able to approve this user.\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/signup/approve.hbs"
    }
  });

  _exports.default = _default;
});