define("labs-ceqr/templates/project/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EycNRHfm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"\\n      New Project\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"project-details\",[],[[\"@project\",\"@mapplutoVersions\",\"@save\"],[[28,\"changeset\",[[24,[\"model\",\"project\"]],[24,[\"ProjectValidations\"]]],null],[24,[\"model\",\"mapplutoVersions\"]],[28,\"route-action\",[\"createProject\"],null]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/new.hbs"
    }
  });

  _exports.default = _default;
});