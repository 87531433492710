define("labs-ceqr/templates/project/show/public-schools/existing-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gs4IWuJc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[5,\"public-schools/existing-conditions/existing-conditions-steps\",[],[[\"@analysis\",\"@project\"],[[24,[\"model\",\"publicSchoolsAnalysis\"]],[22,\"project\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/public-schools/existing-conditions.hbs"
    }
  });

  _exports.default = _default;
});