define("labs-ceqr/templates/project/show/public-schools/existing-conditions/schools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oKHwojJ6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"publicSchoolsAnalysis\",\"dataPackage\",\"schemas\"]]],null,{\"statements\":[[0,\"  \"],[5,\"public-schools/existing-conditions/table-existing-conditions\",[],[[\"@analysis\"],[[24,[\"model\",\"publicSchoolsAnalysis\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/public-schools/existing-conditions/schools.hbs"
    }
  });

  _exports.default = _default;
});