define("labs-ceqr/templates/project/show/public-schools/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ysSz9a36",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[5,\"public-schools/students-generated\",[],[[\"@project\",\"@analysis\"],[[22,\"project\"],[24,[\"model\",\"publicSchoolsAnalysis\"]]]]],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[5,\"public-schools/summary\",[],[[\"@project\",\"@analysis\"],[[22,\"project\"],[24,[\"model\",\"publicSchoolsAnalysis\"]]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui inverted dimmer\\n      \",[28,\"if\",[[24,[\"public-schools\",\"fullReload\",\"isRunning\"]],\"active\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui text loader\"],[8],[0,\"\\n      Loading\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/public-schools/summary.hbs"
    }
  });

  _exports.default = _default;
});