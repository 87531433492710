define("labs-ceqr/mirage/helpers/get-transportation-census-estimate-response", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "labs-ceqr/utils/modalSplit", "faker"], function (_exports, _toConsumableArray2, _modalSplit, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTransportationCensusEstimateResponse;

  function getTransportationCensusEstimateResponse(type) {
    var estimates = createTransportationCensusEstimates(type);
    var attributes = estimates.map(function (estimate) {
      return {
        attributes: estimate
      };
    });
    return {
      data: (0, _toConsumableArray2.default)(attributes)
    };
  } // Ideally a unique geoid is passed in for each call to this function.


  function createTransportationCensusEstimates(type, geoid) {
    var fakeGeoid = geoid || "".concat((Math.random().toFixed(2) * 100).toString().padEnd(11, '0'));
    var totalVariable = type === 'ACS' ? 'population' : 'workers';
    var variables = [totalVariable, 'trans_total', 'trans_auto_total', 'trans_public_total'].concat((0, _toConsumableArray2.default)(_modalSplit.COMMUTER_VARIABLES), (0, _toConsumableArray2.default)(_modalSplit.AUTO_BREAKDOWN_VARIABLES));
    var censusEstimates = variables.map(function (variable) {
      return {
        geoid: fakeGeoid,
        variable: variable,
        value: _faker.default.random.number({
          min: 10,
          max: 1000
        }),
        moe: _faker.default.random.number({
          min: 1,
          max: 20
        })
      };
    });
    return censusEstimates;
  }
});