define("labs-ceqr/controllers/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    'project-orchestrator': Ember.inject.service(),
    project: Ember.computed.alias('model.project'),
    onSummary: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName.includes('summary');
    }),
    showAnalysisSteps: Ember.computed('onSummary', 'project.viewOnly', function () {
      return !(this.project.viewOnly || this.onSummary);
    }),
    showMap: Ember.computed('router.currentRouteName', function () {
      var current = this.router.currentRouteName;
      return current.includes('existing-conditions') || current.includes('no-action');
    })
  });

  _exports.default = _default;
});