define("labs-ceqr/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/bbl.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/bbl.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/community-facilities-analysis.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/community-facilities-analysis.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-package.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-package.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/project.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/public-schools-analysis.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/public-schools-analysis.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/transportation-analysis.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/transportation-analysis.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/transportation-planning-factor.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/transportation-planning-factor.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/carto-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/carto-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/cartoresponses.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/cartoresponses.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/dummy-mapbox-style.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/dummy-mapbox-style.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/helpers/get-transportation-census-estimate-response.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/get-transportation-census-estimate-response.js should pass ESLint\n\n5:21 - \'createTransportationCensusEstimates\' was used before it was defined. (no-use-before-define)');
  });
  QUnit.test('mirage/helpers/mirage-mapbox-gl-monkeypatch.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/mirage-mapbox-gl-monkeypatch.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/bbl.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/bbl.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/community-facilities-analysis.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/community-facilities-analysis.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/data-package.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/data-package.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/project.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/public-schools-analysis.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/public-schools-analysis.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/transportation-analysis.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/transportation-analysis.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/transportation-planning-factor.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/transportation-planning-factor.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/project.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/public-schools-analysis.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/public-schools-analysis.js should pass ESLint\n\n');
  });
});