define("labs-ceqr/templates/project/show/transportation/existing-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7jgQ7yXy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"project\"]]],null,{\"statements\":[[0,\"      \"],[5,\"transportation/census-tracts-map\",[],[[\"@project\"],[[23,0,[\"model\",\"project\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"transportation/existing-conditions/existing-conditions-steps\",[],[[\"@project\"],[[23,0,[\"model\",\"project\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/transportation/existing-conditions.hbs"
    }
  });

  _exports.default = _default;
});