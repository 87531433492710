define("labs-ceqr/components/signup-form", ["exports", "fetch", "labs-ceqr/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.user = {};
    },
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.form').form({
        fields: {
          email: 'email',
          password: ['minLength[6]', 'empty']
        }
      });
    },
    actions: {
      createUser: function createUser(user) {
        var _this = this;

        (0, _fetch.default)("".concat(_environment.default.host, "/auth/v1/signup"), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user: user
          })
        }).then(function (res) {
          if (res.status === 201) {
            _this.get('router').transitionTo('signup.email');
          } else if (res.status === 202) {
            _this.get('router').transitionTo('signup.in-review');
          } else {
            _this.set('error', {
              message: 'The account could not be created'
            });
          }
        }).catch(function (err) {
          return _this.set('error', {
            message: err
          });
        });
      }
    }
  });

  _exports.default = _default;
});