define("labs-ceqr/layer-styles/pluto-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.plutoLineStyles = void 0;
  var plutoLineStyles = {
    'pluto-line': {
      paint: {
        'line-width': 0.5,
        'line-color': 'rgba(130, 130, 130, 1)',
        'line-opacity': {
          stops: [[15, 0], [16, 1]]
        }
      }
    }
  };
  _exports.plutoLineStyles = plutoLineStyles;
});