define("labs-ceqr/fragments/public-schools/ScaProject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * @constructor
   * @param {string} id - Aggregate unique identifier combining source, org_id, bldg_id, and level
   */
  var _default = Ember.Object.extend({
    id: Ember.computed('uid', function () {
      return this.uid;
    })
  });

  _exports.default = _default;
});