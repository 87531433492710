define("labs-ceqr/components/password-reset-form", ["exports", "fetch", "labs-ceqr/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.form').form({
        fields: {
          password: ['minLength[6]', 'empty']
        }
      });
    },
    actions: {
      resetPassword: function resetPassword(password) {
        var _this = this;

        (0, _fetch.default)("".concat(_environment.default.host, "/auth/v1/password-reset"), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            password: password,
            token: this.token
          })
        }).then(function (res) {
          if (res.status === 200) {
            _this.flashMessages.add({
              message: 'Password reset successfully.',
              type: 'success',
              sticky: true
            });

            _this.router.transitionTo('login');
          } else if (res.status === 422) {
            res.json().then(function (_ref) {
              var message = _ref.message;
              return _this.set('error', {
                message: message
              });
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});