define("labs-ceqr/templates/components/transportation/tdf/modal-splits/census-tracts-map/census-tract-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zlEKBhCj",
    "block": "{\"symbols\":[\"censusData\",\"data\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"census-tract-popup ui card\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      \"],[1,[24,[\"feature\",\"properties\",\"boroname\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"meta\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"\\n        Census Tract ID:\\n        \"],[1,[24,[\"feature\",\"properties\",\"geoid\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n      \"],[5,\"transportation/tdf/modal-splits/census-tracts-map/census-tract-popup/data\",[],[[\"@geoid\"],[[24,[\"feature\",\"properties\",\"geoid\"]]]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"loading\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"spinner icon\"],[8],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\\n        \"],[6,[23,1,[\"loaded\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[5,\"transportation/tdf/modal-splits/census-tracts-map/census-tract-popup/modal-split-formatter\",[],[[\"@acsData\",\"@ctppData\"],[[28,\"get\",[[23,2,[]],0],null],[28,\"get\",[[23,2,[]],1],null]]]],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/tdf/modal-splits/census-tracts-map/census-tract-popup.hbs"
    }
  });

  _exports.default = _default;
});