define("labs-ceqr/templates/components/mapbox/basic-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KEF8EIpr",
    "block": "{\"symbols\":[\"map\",\"&default\"],\"statements\":[[5,\"mapbox-gl\",[],[[\"@id\",\"@mapLoaded\",\"@initOptions\"],[[23,0,[\"name\"]],[23,0,[\"mapLoaded\"]],[23,0,[\"initOptions\"]]]],{\"statements\":[[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"instance\"],[[23,1,[\"instance\"]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/mapbox/basic-map.hbs"
    }
  });

  _exports.default = _default;
});