define("labs-ceqr/helpers/school-year", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.schoolYear = schoolYear;
  _exports.default = void 0;

  function schoolYear(schema) {
    // helper functions take their arguments as an array
    // we pass in an object here, but it's changed to [{}]
    if (schema[0]) {
      return "".concat(schema[0].minYear, "-").concat(schema[0].maxYear);
    }

    return '';
  }

  var _default = Ember.Helper.helper(schoolYear);

  _exports.default = _default;
});