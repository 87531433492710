define("labs-ceqr/templates/project/show/transportation/existing-conditions/trip-generation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kQAlfvvk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"transportation/data-source-toggle\",[],[[\"@switch\",\"@falseLabel\",\"@trueLabel\"],[[23,0,[\"isRJTW\"]],\"Residential\",\"Offices\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"transportation/trip-generation-tables/modal-splits-vehicle-occupancy\",[],[[\"@analysis\",\"@selectedCensusTractData\",\"@modeLookup\",\"@commuterModes\",\"@modalSplitVariablesSubset\"],[[23,0,[\"model\",\"transportationAnalysis\"]],[28,\"await\",[[23,0,[\"selectedCensusTractData\"]]],null],[23,0,[\"modeLookup\"]],[23,0,[\"commuterModes\"]],[22,\"modalSplitVariablesSubset\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"transportation/trip-generation-tables/trip-generation-rates\",[],[[\"@analysis\"],[[23,0,[\"model\",\"transportationAnalysis\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"transportation/trip-generation-tables/trip-generation-results\",[],[[\"@analysis\",\"@selectedCensusTractData\",\"@modeLookup\",\"@modalSplitVariablesSubset\"],[[23,0,[\"model\",\"transportationAnalysis\"]],[28,\"await\",[[23,0,[\"selectedCensusTractData\"]]],null],[23,0,[\"modeLookup\"]],[22,\"modalSplitVariablesSubset\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/transportation/existing-conditions/trip-generation.hbs"
    }
  });

  _exports.default = _default;
});