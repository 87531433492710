define("labs-ceqr/templates/components/mapbox/feature-hoverer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z0gATMr4",
    "block": "{\"symbols\":[\"mousePoint\",\"intersectingFeatures\",\"&default\"],\"statements\":[[5,\"mapbox/current-mouse-position\",[],[[\"@map\",\"@handleMousePosition\"],[[23,0,[\"map\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"_point\"]]],null]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"mapbox/intersecting-features\",[],[[\"@map\",\"@point\",\"@options\",\"@handleIntersectingFeatures\"],[[23,0,[\"map\"]],[23,1,[]],[28,\"hash\",null,[[\"layers\"],[[28,\"array\",[[23,0,[\"layerId\"]]],null]]]],[23,0,[\"setFeatures\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[]],[28,\"and\",[[23,2,[]],[23,0,[\"_features\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[14,3,[[28,\"hash\",null,[[\"features\",\"point\"],[[23,0,[\"_features\"]],[23,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/mapbox/feature-hoverer.hbs"
    }
  });

  _exports.default = _default;
});