define("labs-ceqr/helpers/get-color-style", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getColorStyle = getColorStyle;
  _exports.default = void 0;

  /**
   * Returns an html-safe CSS color style attribute string for a given color string.
   * Mostly exists to get rid of an ember warning about binding style attributes.
   */
  function getColorStyle(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        color = _params[0];

    return Ember.String.htmlSafe("color:".concat(color));
  }

  var _default = Ember.Helper.helper(getColorStyle);

  _exports.default = _default;
});