define("labs-ceqr/components/public-schools/no-action/sca-under-construction", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$('.progress').progress();
      this.$('.progress').popup();
    },
    tables: Ember.computed('analysis.subdistricts.[]', function () {
      var _this = this;

      var tables = this.get('analysis.subdistricts').map(function (sd) {
        var buildings = _this.get('analysis.scaProjects').filter(function (b) {
          return b.district === sd.district && b.subdistrict === sd.subdistrict;
        });

        if (Ember.isEmpty(buildings)) return null;
        return (0, _objectSpread2.default)({}, sd, {
          buildings: buildings
        });
      });
      return tables.compact();
    }),
    actions: {
      save: function save() {
        var _this2 = this;

        this.set('saving', true);
        this.get('analysis').save().then(function () {
          return _this2.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});