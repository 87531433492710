define("labs-ceqr/templates/components/mapbox/carto-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGqrtrwv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"tiles\"]]],null,{\"statements\":[[0,\"  \"],[5,\"mapbox-gl-layer\",[],[[\"@map\",\"@layer\"],[[24,[\"map\",\"instance\"]],[22,\"mapboxLayerOptions\"]]]],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"layerId\"],[[24,[\"mapboxLayerOptions\",\"id\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/mapbox/carto-layer.hbs"
    }
  });

  _exports.default = _default;
});