define("labs-ceqr/templates/components/public-schools/version-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7/NEaMv+",
    "block": "{\"symbols\":[\"package\"],\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui medium label\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"analysis\",\"newDataAvailable\"]]],null,{\"statements\":[[0,\"      \"],[5,\"ui-popup\",[[12,\"class\",\"exclamation orange circle icon\"]],[[\"@tagName\",\"@content\"],[\"i\",\"Update available\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    Data Package:\\n    \"],[7,\"div\",true],[10,\"class\",\"detail\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"project\",\"viewOnly\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"humanize\",[[24,[\"analysis\",\"dataVersion\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"ui-dropdown\",[[12,\"class\",\"public-schools-data-dropdown\"]],[[\"@selected\"],[[24,[\"analysis\",\"dataVersion\"]]]],{\"statements\":[[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[9],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"availablePackages\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\",true],[11,\"data-value\",[23,1,[\"version\"]]],[10,\"class\",\"item\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"changeDataPackage\",[23,1,[]]],null]],[8],[0,\"\\n                \"],[1,[23,1,[\"name\"]],false],[0,\"\\n              \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/public-schools/version-selector.hbs"
    }
  });

  _exports.default = _default;
});