define("labs-ceqr/router", ["exports", "labs-ceqr/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeDidChange', function () {
        _this._trackPage();
      });
    },
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    _trackPage: function _trackPage() {
      function trackPage() {
        var page = this.get('url');
        var title = this.getWithDefault('currentRouteName', 'unknown');
        this.metrics.trackPage({
          page: page,
          title: title
        });
      }

      Ember.run.scheduleOnce('afterRender', this, trackPage);
    }
  });
  Router.map(function () {
    this.route('login');
    this.route('password-reset');
    this.route('request-password-reset');
    this.route('signup', function () {
      this.route('email');
      this.route('in-review');
      this.route('approve');
    });
    this.route('project', function () {
      this.route('new'); // we avoided using an underscore (:_id) specifically to
      // not trigger default model lookup for the project/show route.
      // See routes/project/show.js for custom .findRecord() call to include
      // related resources.

      this.route('show', {
        path: '/:id'
      }, function () {
        this.route('edit'); // CEQR chapter routes

        this.route('public-schools', function () {
          this.route('analysis-threshold', {
            path: '/'
          });
          this.route('existing-conditions', function () {
            this.route('schools', {
              path: '/'
            });
            this.route('study-area');
            this.route('new-schools');
          });
          this.route('no-action', function () {
            this.route('scenario', {
              path: '/'
            });
            this.route('under-construction');
            this.route('utilization-changes');
            this.route('residential-development');
          });
          this.route('with-action', function () {
            this.route('scenario', {
              path: '/'
            });
            this.route('new-schools');
          });
          this.route('summary');
        });
        this.route('transportation', function () {
          this.route('analysis-threshold', {
            path: '/'
          });
          this.route('tdf', function () {
            this.route('planning-factors', function () {
              this.route('show', {
                path: '/:transportation_planning_factor_id'
              });
            });
            this.route('trip-results', function () {
              this.route('show', {
                path: '/:transportation_planning_factor_id'
              });
              this.route('total');
            });
          });
        });
        this.route('community-facilities', function () {
          this.route('analysis-threshold', {
            path: '/'
          });
        });
      });
    });
    this.route('user', function () {
      this.route('projects');
    });
    this.route('four-oh-four', {
      path: '*path'
    });
    this.route('ceqr-intro-page');
    this.route('data', function () {
      this.route('air-quality');
      this.route('transportation-trip-generation');
    });
  });
  var _default = Router;
  _exports.default = _default;
});