define("labs-ceqr/components/public-schools/no-action/additional-residential-dev", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "labs-ceqr/fragments/public-schools/FutureResidentialDevelopment"], function (_exports, _objectSpread2, _FutureResidentialDevelopment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.resdev = {};
    },
    actions: {
      addResDev: function addResDev(_ref) {
        var name = _ref.name,
            total_units = _ref.total_units,
            year = _ref.year,
            subdistrict = _ref.subdistrict;

        var residentialDevelopment = _FutureResidentialDevelopment.default.create((0, _objectSpread2.default)({}, subdistrict, {
          name: name,
          total_units: total_units,
          year: year
        }));

        this.get('analysis.residentialDevelopments').pushObject(residentialDevelopment);
        this.get('analysis').save();
        this.set('resdev', {});
      },
      removeResDev: function removeResDev(resdev) {
        this.get('analysis.residentialDevelopments').removeObject(resdev);
        this.get('analysis').save();
      }
    }
  });

  _exports.default = _default;
});