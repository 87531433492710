define("labs-ceqr/helpers/get-split-percent", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSplitPercent = getSplitPercent;
  _exports.default = void 0;

  /**
   * Helper for CensusTractsTable component that calculates a modal split percent for a
   * single modal split. Accepts multiple variables, so modal splits for a random subset of
   * modes can be calculated
   */
  function getSplitPercent(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        modalSplitData = _params[0],
        variables = _params[1];

    if (modalSplitData && variables && Array.isArray(variables)) {
      // calculate the sum of given variables for the modal split
      var partTotal = variables.reduce(function (runningSum, variable) {
        return modalSplitData[variable] ? runningSum + modalSplitData[variable].value : runningSum;
      }, 0); // calculate percent

      var percent = modalSplitData.trans_commuter_total ? partTotal / modalSplitData.trans_commuter_total.value * 100 : undefined; // return formatted percent

      return isNaN(percent) ? '-' : "".concat(percent.toFixed(1), " %");
    }
  }

  var _default = Ember.Helper.helper(getSplitPercent);

  _exports.default = _default;
});