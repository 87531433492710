define("labs-ceqr/components/auth/request-password-reset-form", ["exports", "fetch", "labs-ceqr/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      requestResetPassword: function requestResetPassword(email) {
        var _this = this;

        (0, _fetch.default)("".concat(_environment.default.host, "/auth/v1/password-reset"), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email
          })
        }).catch(function (err) {
          return _this.set('error', {
            message: err
          });
        }).then(function () {
          _this.flashMessages.add({
            message: 'Password reset email sent. It may take 1 or 2 mintues to arrive.',
            type: 'success',
            sticky: true
          });

          _this.router.transitionTo('login');
        });
      }
    }
  });

  _exports.default = _default;
});