define("labs-ceqr/templates/project/show/transportation/existing-conditions/census-tracts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7GgxBnNT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"analysis/data-package-selector\",[],[[\"@dataPackageType\",\"@title\",\"@currentPackage\",\"@updateDataPackageAction\"],[\"nyc_acs\",\"Journey to Work\",[24,[\"analysis\",\"nycAcsDataPackage\"]],[24,[\"analysis\",\"updateDataPackage\"]]]]],[0,\"\\n    \"],[5,\"analysis/data-package-selector\",[],[[\"@dataPackageType\",\"@title\",\"@currentPackage\",\"@updateDataPackageAction\"],[\"ctpp\",\"Reverse Journey to Work\",[24,[\"analysis\",\"ctppDataPackage\"]],[24,[\"analysis\",\"updateDataPackage\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"transportation/data-source-toggle\",[],[[\"@switch\",\"@falseLabel\",\"@trueLabel\"],[[23,0,[\"isRJTW\"]],\"Journey To Work\",\"Reverse Journey To Work\"]]],[0,\"\\n    \"],[5,\"transportation/census-tracts-table\",[],[[\"@isRJTW\",\"@selectedCensusTractIds\",\"@acsModalSplits\",\"@ctppModalSplits\",\"@modeLookup\",\"@commuterModes\"],[[23,0,[\"isRJTW\"]],[23,0,[\"selectedCensusTractIds\"]],[24,[\"analysis\",\"acsModalSplits\"]],[24,[\"analysis\",\"ctppModalSplits\"]],[23,0,[\"modeLookup\"]],[23,0,[\"commuterModes\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/transportation/existing-conditions/census-tracts.hbs"
    }
  });

  _exports.default = _default;
});