define("labs-ceqr/components/project-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'project-orchestrator': Ember.inject.service(),
    buildYearRange: Array.from({
      length: 2040 - 2018
    }, function (v, k) {
      return k + 2018;
    }),
    // noop
    save: function save() {},
    actions: {
      // "save" is a passed in action
      save: function save() {
        this.get('save')(this.get('project'));
      },
      back: function back() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});