define("labs-ceqr/utils/aggregateMarginOfError", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = aggregateMarginOfError;

  function aggregateMarginOfError(moes) {
    return Math.sqrt(moes.reduce(function (summedSquares, moe) {
      return summedSquares + Math.pow(moe, 2);
    }));
  }
});