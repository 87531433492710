define("labs-ceqr/fragments/public-schools/School", ["exports", "labs-ceqr/utils/round"], function (_exports, _round) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The School fragment wraps a JSON object representing an school segmented by level.
   * A school building, for the purposes of public schools analysis in CEQR, represents
   * just one school level in one unique combination of bldg_id and org_id
   *
   * @constructor
   * @param {string} id - Aggregate unique identifier combining source, org_id, bldg_id, and level
   * @param {string} name - Name of school
   * @param {string} bldg_name - Building name, sometimes different from school name for multi-building schools
   * @param {string} address - Address of school
   * @param {integer} district - Building's school district
   * @param {integer} subdistrict - Building's school subdistrict
   * @param {string} org_id - DOE Organization ID of school
   * @param {string} bldg_id - DOE Building ID of school
   * @param {string} level - The level of the individual school. In other words, what level do the capacity and
   *   enroll numbers on this building contribute to. One of: ['ps', 'is', 'hs']
   * @param {string} org_level - The level of the organization
   * @param {string} source - The source of the data. One of: ['bluebook', 'lcgms']
   * @param {string} dataVersion - Version of LCGMS or Bluebook, ceqr_school_buildings dataset is a combination lcgms dataset and bluebook dataset
   * @param {integer} capacity - School's current capacity
   * @param {integer} capacityFuture - School's future capacity, defaults to the same as capacity but a user can change this
   * @param {integer} enroll - School's current enrollment
   * @param {json} geojson - Geojson feature of school's point
   */
  var _default = Ember.Object.extend({
    id: Ember.computed('source', 'org_id', 'bldg_id', 'level', function () {
      return "".concat(this.source, "-").concat(this.org_id, "-").concat(this.bldg_id, "-").concat(this.level);
    }),
    seats: Ember.computed('excluded', 'enroll', 'capacity', function () {
      if (this.excluded) return Math.round(0 - this.enroll);
      if (!this.capacity) return 0;
      return Math.round(this.capacity - this.enroll);
    }),
    utilization: Ember.computed('enroll', 'capacity', function () {
      return (0, _round.default)(this.enroll / this.capacity, 3);
    }),
    capacityDelta: Ember.computed('capacity', 'capacityFuture', function () {
      return this.capacityFuture - this.capacity;
    })
  });

  _exports.default = _default;
});