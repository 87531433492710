define("labs-ceqr/models/public-schools-analysis", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-data", "@turf/helpers", "labs-ceqr/fragments/public-schools/SubdistrictTotals", "labs-ceqr/fragments/public-schools/LevelTotals"], function (_exports, _objectSpread2, _emberData, _helpers, _SubdistrictTotals, _LevelTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    project: _emberData.default.belongsTo('project'),
    dataPackage: _emberData.default.belongsTo('data-package'),
    newDataAvailable: _emberData.default.attr('boolean'),
    // Analysis Model triggers across
    detailedAnalysis: Ember.computed.alias('indirectEffect'),
    // Aliases from project
    borough: Ember.computed.alias('project.borough'),
    netUnits: Ember.computed.alias('project.netUnits'),
    bbls: Ember.computed.alias('project.bbls'),
    buildYear: Ember.computed.alias('project.buildYear'),
    // Public Schools Multipliers
    multipliers: _emberData.default.attr(''),
    multiplierVersion: Ember.computed.alias('multipliers.version'),
    currentMultiplier: Ember.computed('multipliers', 'borough', function () {
      switch (this.multipliers.version) {
        case 'march-2014':
          return this.multipliers.boroughs.findBy('name', this.borough) || {};

        case 'november-2018':
        case 'november-2019':
          return this.multipliers.districts.findBy('csd', parseFloat(this.district)) || {};

        default:
          return {};
      }
    }),
    // Schools Data version
    dataVersion: Ember.computed.alias('dataPackage.version'),
    maxProjection: Ember.computed.alias('dataPackage.schemas.sca_e_projections_by_sd.maxYear'),
    minProjection: Ember.computed.alias('dataPackage.schemas.sca_e_projections_by_sd.minYear'),
    // Derived from map
    esSchoolChoice: _emberData.default.attr('boolean'),
    isSchoolChoice: _emberData.default.attr('boolean'),
    // Effects
    esEffect: Ember.computed('multipliers', 'estEsMsStudents', function () {
      return this.multipliers.thresholdPsIsStudents < this.estEsMsStudents;
    }),
    hsEffect: Ember.computed('multipliers', 'estHsStudents', function () {
      return this.multipliers.thresholdHsStudents < this.estHsStudents;
    }),
    indirectEffect: Ember.computed('esEffect', 'hsEffect', function () {
      return this.esEffect || this.hsEffect;
    }),
    hsAnalysis: Ember.computed.alias('hsEffect'),
    // Estimated Students
    estEsStudents: Ember.computed('netUnits', 'borough', 'currentMultiplier', function () {
      return Math.ceil(this.currentMultiplier.ps * this.netUnits);
    }),
    estIsStudents: Ember.computed('netUnits', 'borough', 'currentMultiplier', function () {
      return Math.ceil(this.currentMultiplier.is * this.netUnits);
    }),
    estEsMsStudents: Ember.computed('estEsStudents', 'estIsStudents', function () {
      return this.estEsStudents + this.estIsStudents;
    }),
    estHsStudents: Ember.computed('netUnits', 'borough', 'currentMultiplier', function () {
      return Math.ceil(this.currentMultiplier.hs * this.netUnits);
    }),
    // School District & Subdistricts
    subdistrictsFromDb: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    subdistrictsFromUser: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    subdistrictsGeojson: _emberData.default.belongsTo('subdistricts-geojson'),
    subdistricts: Ember.computed('subdistrictsFromDb.@each', 'subdistrictsFromUser.@each', function () {
      return this.subdistrictsFromDb.concat(this.subdistrictsFromUser);
    }),
    district: Ember.computed('subdistrictsFromDb', function () {
      return parseFloat(this.subdistrictsFromDb[0].district);
    }),
    multiSubdistrict: Ember.computed('subdistricts', function () {
      return this.get('subdistricts').length > 1;
    }),
    // By Subdistrict
    school_buildings: _emberData.default.attr('public-schools/schools', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    buildingsGeojson: Ember.computed('school_buildings', function () {
      var buildings = this.school_buildings;
      var features = buildings.map(function (building) {
        var geojson = building.geojson;
        geojson.properties = {
          level: building.level,
          name: building.name,
          org_id: building.org_id,
          bldg_id: building.bldg_id,
          source: building.source,
          id: building.id
        };
        return geojson;
      });
      return _helpers.default.featureCollection(features);
    }),
    scaProjects: _emberData.default.attr('public-schools/sca-projects', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    scaProjectsGeojson: Ember.computed('scaProjects', function () {
      var features = this.scaProjects.map(function (b) {
        var geojson = b.geojson;
        geojson.properties = {
          name: b.name,
          org_id: b.org_id,
          source: b.source,
          org_level: b.org_level,
          level: b.org_level,
          id: b.id
        };
        return geojson;
      });
      return _helpers.default.featureCollection(features);
    }),
    buildings: Ember.computed('school_buildings', 'scaProjects', function () {
      return this.get('school_buildings').concat(this.get('scaProjects')).compact();
    }),
    buildingsBldgIds: Ember.computed('buildings', function () {
      return this.get('buildings').mapBy('bldg_id').uniq();
    }),
    // ceqr_school_buildings dataset is a combination of two datasets lcgms and bluebook
    // lcgms dataset represents schools that opened recently
    newlyOpenedSchools: Ember.computed('analysis.school_buildings', function () {
      return this.school_buildings.find(function (school) {
        return school.source === 'lcgms';
      });
    }),
    // Future
    projectionOverMax: Ember.computed('buildYear', function () {
      return this.get('buildYear') > this.get('maxProjection');
    }),
    buildYearMaxed: Ember.computed('projectionOverMax', function () {
      return this.get('projectionOverMax') ? this.get('maxProjection') : this.get('buildYear');
    }),
    doeUtilChanges: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    doeUtilChangesBldgIds: Ember.computed('doeUtilChanges.[]', function () {
      return this.get('doeUtilChanges').mapBy('bldg_id').concat(this.get('doeUtilChanges').mapBy('bldg_id_additional')).without('').uniq();
    }),
    doeUtilChangesPerBldg: Ember.computed('doeUtilChanges', 'buildings', function () {
      var _this = this;

      var buildingsNoHs = this.get('buildings').filter(function (b) {
        return b.level !== 'hs';
      });
      return this.get('doeUtilChangesBldgIds').map(function (bldg_id) {
        var buildings = buildingsNoHs.filterBy('bldg_id', bldg_id);
        if (buildings.length === 0) return;

        var doe_notices = _this.get('doeUtilChanges').filter(function (b) {
          return b.bldg_id === bldg_id || b.bldg_id_additional === bldg_id;
        }).mapBy('title').uniq().map(function (title) {
          return _this.doeUtilChanges.filterBy('title', title);
        });

        return {
          bldg_id: bldg_id,
          buildings: buildings,
          doe_notices: doe_notices
        };
      }).compact();
    }),
    doeUtilChangesCount: Ember.computed('doeUtilChanges', function () {
      return this.doeUtilChangesPerBldg.length;
    }),
    residentialDevelopments: _emberData.default.attr('public-schools/residential-developments', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    futureResidentialDev: Ember.computed('currentMultiplier', 'residentialDevelopments.[]', function () {
      var _this2 = this;

      return this.residentialDevelopments.map(function (d) {
        d.set('multipliers', _this2.currentMultiplier);
        return d;
      });
    }),
    schoolsWithAction: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    hsProjections: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    hsStudentsFromHousing: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    futureEnrollmentProjections: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    futureEnrollmentNewHousing: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // Tables
    allSchools: Ember.computed('school_buildings', function () {
      return this.school_buildings.compact();
    }),
    subdistrictTotals: Ember.computed('allSchools', 'subdistricts', 'currentMultiplier', 'hsProjections', 'futureEnrollmentProjections.@each.{ms,ps}', 'futureEnrollmentNewHousing', 'scaProjects.@each.{includeInCapacity,ps_capacity,is_capacity,hs_capacity}', function () {
      var _this3 = this;

      var tables = [];
      tables.push(_SubdistrictTotals.default.create({
        borough: this.borough,
        level: 'hs',
        allBuildings: this.allSchools,
        studentMultiplier: this.currentMultiplier.hs,
        enroll: this.hsProjections[0] ? this.hsProjections[0].hs : 0,
        students: this.hsStudentsFromHousing + this.futureResidentialDev.reduce(function (acc, value) {
          return acc + value.hs_students;
        }, 0),
        scaCapacityIncrease: this.scaProjects.filterBy('includeInCapacity', true).reduce(function (acc, value) {
          var v = parseFloat(value.hs_capacity);
          if (v) return acc + v;
          return acc;
        }, 0),
        newCapacityWithAction: this.schoolsWithAction.reduce(function (acc, value) {
          return acc + parseFloat(value.hs_seats);
        }, 0)
      }));
      this.subdistricts.map(function (sd) {
        tables.push(_SubdistrictTotals.default.create((0, _objectSpread2.default)({}, sd, {
          level: 'ps',
          allBuildings: _this3.allSchools,
          studentMultiplier: _this3.currentMultiplier.ps,
          enroll: Math.round(_this3.futureEnrollmentProjections.find(function (i) {
            return i.district === sd.district && i.subdistrict === sd.subdistrict;
          }).ps),
          students: // Students from future housing projected by SCA
          _this3.futureEnrollmentNewHousing.find(function (i) {
            return i.district === sd.district && i.subdistrict === sd.subdistrict && i.level === 'PS';
          }).students + // Students from user-inputed additional developments
          _this3.futureResidentialDev.filter(function (i) {
            return i.district === sd.district && i.subdistrict === sd.subdistrict;
          }).reduce(function (acc, value) {
            return acc + value.ps_students;
          }, 0),
          scaCapacityIncrease: _this3.scaProjects.filter(function (b) {
            return b.district === sd.district && b.subdistrict === sd.subdistrict && b.includeInCapacity === true;
          }).reduce(function (acc, value) {
            var v = parseFloat(value.ps_capacity);
            if (v) return acc + v;
            return acc;
          }, 0),
          newCapacityWithAction: _this3.schoolsWithAction.filter(function (b) {
            return b.district === sd.district && b.subdistrict === sd.subdistrict;
          }).reduce(function (acc, value) {
            return acc + parseFloat(value.ps_seats);
          }, 0)
        })));
        tables.push(_SubdistrictTotals.default.create((0, _objectSpread2.default)({}, sd, {
          level: 'is',
          allBuildings: _this3.allSchools,
          studentMultiplier: _this3.currentMultiplier.is,
          enroll: Math.round(_this3.futureEnrollmentProjections.find(function (i) {
            return i.district === sd.district && i.subdistrict === sd.subdistrict;
          }).ms),
          students: // Students from future housing projected by SCA
          _this3.futureEnrollmentNewHousing.find(function (i) {
            return i.district === sd.district && i.subdistrict === sd.subdistrict && i.level === 'MS';
          }).students + // Students from user-inputed additional developments
          _this3.futureResidentialDev.filter(function (i) {
            return i.district === sd.district && i.subdistrict === sd.subdistrict;
          }).reduce(function (acc, value) {
            return acc + value.is_students;
          }, 0),
          scaCapacityIncrease: _this3.scaProjects.filter(function (b) {
            return b.district === sd.district && b.subdistrict === sd.subdistrict && b.includeInCapacity === true;
          }).reduce(function (acc, value) {
            var v = parseFloat(value.is_capacity);
            if (v) return acc + v;
            return acc;
          }, 0),
          newCapacityWithAction: _this3.schoolsWithAction.filter(function (b) {
            return b.district === sd.district && b.subdistrict === sd.subdistrict;
          }).reduce(function (acc, value) {
            return acc + parseFloat(value.is_seats);
          }, 0)
        })));
      });
      return tables;
    }),
    psLevelTotals: Ember.computed('{subdistrictTotals,schoolsWithAction}.@each', function () {
      return _LevelTotals.default.create({
        subdistrictTotals: this.subdistrictTotals.filterBy('level', 'ps'),
        studentsWithAction: this.estEsStudents || 0
      });
    }),
    isLevelTotals: Ember.computed('{subdistrictTotals,schoolsWithAction}.@each', function () {
      return _LevelTotals.default.create({
        subdistrictTotals: this.subdistrictTotals.filterBy('level', 'is'),
        studentsWithAction: this.estIsStudents || 0
      });
    }),
    hsLevelTotals: Ember.computed('{subdistrictTotals,schoolsWithAction}.@each', function () {
      return _LevelTotals.default.create({
        subdistrictTotals: this.subdistrictTotals.filterBy('level', 'hs'),
        studentsWithAction: this.estHsStudents || 0
      });
    })
  });

  _exports.default = _default;
});