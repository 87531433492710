define("labs-ceqr/helpers/peek-record", ["exports", "ember-needs-async/helpers/peek-record"], function (_exports, _peekRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _peekRecord.default;
    }
  });
  Object.defineProperty(_exports, "peekRecord", {
    enumerable: true,
    get: function get() {
      return _peekRecord.peekRecord;
    }
  });
});