define("labs-ceqr/mirage/factories/data-package", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    mapPluto: (0, _emberCliMirage.trait)({
      name: 'MapPLUTO 18v1',
      package: 'mappluto',
      version: '18v1',
      releaseDate: 'Thu Feb 28 2019 19:00:00 GMT-0500 (Eastern Standard Time)',
      schemas: {
        mappluto: {
          carto: 'mappluto_18v2'
        }
      }
    }),
    nycAcs: (0, _emberCliMirage.trait)({
      name: 'ACS 5-year 2017',
      package: 'nyc_acs',
      version: '2017',
      releaseDate: '2018-01-01',
      schemas: {
        nyc_acs: {
          table: '2017'
        },
        nyc_census_tract_boundaries: {
          table: '2010'
        }
      }
    }),
    publicSchools: (0, _emberCliMirage.trait)({
      name: 'November 2017',
      package: 'public_schools',
      version: 'november_2017',
      releaseDate: Date.now,
      schemas: function schemas() {
        return {
          doe_school_subdistricts: {
            table: '2017'
          },
          doe_lcgms: {
            table: '2017',
            version: '2018-09-10',
            minYear: 2017,
            maxYear: 2018
          },
          sca_bluebook: {
            table: '2017',
            minYear: 2016,
            maxYear: 2017
          },
          doe_school_zones_ps: {
            table: '2018'
          },
          doe_school_zones_is: {
            table: '2018'
          },
          doe_school_zones_hs: {
            table: '2018'
          },
          sca_housing_pipeline_by_boro: {
            minYear: 2016,
            maxYear: 2025,
            table: '2017'
          },
          sca_housing_pipeline_by_sd: {
            minYear: 2016,
            maxYear: 2025,
            table: '2017'
          },
          sca_e_projections_by_boro: {
            minYear: 2015,
            maxYear: 2025,
            table: '2017'
          },
          sca_e_projections_by_sd: {
            minYear: 2015,
            maxYear: 2025,
            table: '2017'
          },
          doe_significant_utilization_changes: {
            table: '062018',
            version: '2018-02-01'
          },
          sca_capacity_projects: {
            table: '2018',
            version: '2018-12-04'
          }
        };
      }
    })
  });

  _exports.default = _default;
});