define("labs-ceqr/templates/components/transportation/tdf/in-out-splits/table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RFlC5sJP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[28,\"input\",null,[[\"type\",\"max\",\"min\",\"value\",\"input\",\"data-test-in-out-split\"],[\"number\",\"100\",\"0\",[24,[\"data\",\"in\"]],[28,\"action\",[[23,0,[]],\"syncOut\",[24,[\"data\",\"in\"]]],null],\"in\"]]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[28,\"input\",null,[[\"type\",\"max\",\"min\",\"value\",\"input\"],[\"number\",\"100\",\"0\",[24,[\"data\",\"out\"]],[28,\"action\",[[23,0,[]],\"syncIn\",[24,[\"data\",\"out\"]]],null]]]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/tdf/in-out-splits/table-row.hbs"
    }
  });

  _exports.default = _default;
});