define("labs-ceqr/mirage/fixtures/carto-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    metadata: {
      tilejson: {
        vector: {
          tiles: ['https://labs-mapbox-gl-noop-tiles.nyc3.digitaloceanspaces.com/dummy-tile.mvt']
        }
      }
    }
  };
  _exports.default = _default;
});