define("labs-ceqr/mirage/factories/public-schools-analysis", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // !!!!NOTE!!!!: For creating new tests, DO NOT change the factory directly.
  // Instead, create a trait or edit the factory WITHIN the test.
  // Editing the factory directly might affect other tests.
  var _default = _emberCliMirage.Factory.extend({
    multipliers: function multipliers() {
      return {
        version: 'november-2018',
        districts: [{
          hs: 0.02,
          is: 0.03,
          ps: 0.05,
          csd: 1,
          borocode: 'mn',
          hsThreshold: 7126,
          psisThreshold: 630
        }, {
          hs: 0.02,
          is: 0.02,
          ps: 0.05,
          csd: 2,
          borocode: 'mn',
          hsThreshold: 7126,
          psisThreshold: 725
        }, {
          hs: 0.02,
          is: 0.03,
          ps: 0.06,
          csd: 3,
          borocode: 'mn',
          hsThreshold: 7126,
          psisThreshold: 569
        }, {
          hs: 0.02,
          is: 0.05,
          ps: 0.13,
          csd: 4,
          borocode: 'mn',
          hsThreshold: 7126,
          psisThreshold: 292
        }, {
          hs: 0.02,
          is: 0.06,
          ps: 0.16,
          csd: 5,
          borocode: 'mn',
          hsThreshold: 7126,
          psisThreshold: 225
        }, {
          hs: 0.02,
          is: 0.06,
          ps: 0.15,
          csd: 6,
          borocode: 'mn',
          hsThreshold: 7126,
          psisThreshold: 242
        }, {
          hs: 0.15,
          is: 0.19,
          ps: 0.37,
          csd: 7,
          borocode: 'bx',
          hsThreshold: 980,
          psisThreshold: 90
        }, {
          hs: 0.15,
          is: 0.15,
          ps: 0.31,
          csd: 8,
          borocode: 'bx',
          hsThreshold: 980,
          psisThreshold: 109
        }, {
          hs: 0.15,
          is: 0.11,
          ps: 0.34,
          csd: 9,
          borocode: 'bx',
          hsThreshold: 980,
          psisThreshold: 109
        }, {
          hs: 0.15,
          is: 0.12,
          ps: 0.33,
          csd: 10,
          borocode: 'bx',
          hsThreshold: 980,
          psisThreshold: 111
        }, {
          hs: 0.15,
          is: 0.11,
          ps: 0.26,
          csd: 11,
          borocode: 'bx',
          hsThreshold: 980,
          psisThreshold: 134
        }, {
          hs: 0.15,
          is: 0.13,
          ps: 0.33,
          csd: 12,
          borocode: 'bx',
          hsThreshold: 980,
          psisThreshold: 107
        }, {
          hs: 0.09,
          is: 0.03,
          ps: 0.07,
          csd: 13,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 480
        }, {
          hs: 0.09,
          is: 0.02,
          ps: 0.07,
          csd: 14,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 586
        }, {
          hs: 0.09,
          is: 0.05,
          ps: 0.18,
          csd: 15,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 220
        }, {
          hs: 0.09,
          is: 0.08,
          ps: 0.13,
          csd: 16,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 246
        }, {
          hs: 0.09,
          is: 0.09,
          ps: 0.24,
          csd: 17,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 152
        }, {
          hs: 0.09,
          is: 0.16,
          ps: 0.27,
          csd: 18,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 118
        }, {
          hs: 0.09,
          is: 0.15,
          ps: 0.38,
          csd: 19,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 94
        }, {
          hs: 0.09,
          is: 0.07,
          ps: 0.31,
          csd: 20,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 132
        }, {
          hs: 0.09,
          is: 0.07,
          ps: 0.24,
          csd: 21,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 161
        }, {
          hs: 0.09,
          is: 0.11,
          ps: 0.27,
          csd: 22,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 132
        }, {
          hs: 0.09,
          is: 0.16,
          ps: 0.27,
          csd: 23,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 118
        }, {
          hs: 0.09,
          is: 0.04,
          ps: 0.18,
          csd: 32,
          borocode: 'bk',
          hsThreshold: 1767,
          psisThreshold: 235
        }, {
          hs: 0.13,
          is: 0.1,
          ps: 0.23,
          csd: 24,
          borocode: 'qn',
          hsThreshold: 1172,
          psisThreshold: 150
        }, {
          hs: 0.13,
          is: 0.08,
          ps: 0.23,
          csd: 25,
          borocode: 'qn',
          hsThreshold: 1172,
          psisThreshold: 160
        }, {
          hs: 0.13,
          is: 0.14,
          ps: 0.31,
          csd: 26,
          borocode: 'qn',
          hsThreshold: 1172,
          psisThreshold: 111
        }, {
          hs: 0.13,
          is: 0.14,
          ps: 0.31,
          csd: 27,
          borocode: 'qn',
          hsThreshold: 1172,
          psisThreshold: 111
        }, {
          hs: 0.13,
          is: 0.15,
          ps: 0.31,
          csd: 28,
          borocode: 'qn',
          hsThreshold: 1172,
          psisThreshold: 109
        }, {
          hs: 0.13,
          is: 0.16,
          ps: 0.36,
          csd: 29,
          borocode: 'qn',
          hsThreshold: 1172,
          psisThreshold: 96
        }, {
          hs: 0.13,
          is: 0.04,
          ps: 0.16,
          csd: 30,
          borocode: 'qn',
          hsThreshold: 1172,
          psisThreshold: 250
        }, {
          hs: 0.13,
          is: 0.11,
          ps: 0.28,
          csd: 31,
          borocode: 'si',
          hsThreshold: 1205,
          psisThreshold: 128
        }],
        thresholdHsStudents: 150,
        thresholdPsIsStudents: 50
      };
    },
    // TRAIT for list of schools, including school_buildings and scaProjects
    schoolsForTests: (0, _emberCliMirage.trait)({
      school_buildings: [{
        name: 'I.S. 2 - K',
        org_id: 'K002',
        source: 'bluebook',
        bldg_id: 'K002',
        cartodb_id: 11,
        district: 1,
        subdistrict: 2,
        includeInCapacity: false,
        hs_capacity: 1000,
        ps_capacity: 0,
        is_capacity: 0,
        level: 'hs'
      }, {
        name: 'I.S. 61 - K',
        org_id: 'K061',
        source: 'bluebook',
        bldg_id: 'K022',
        cartodb_id: 12,
        district: 1,
        subdistrict: 3,
        includeInCapacity: true,
        hs_capacity: 0,
        ps_capacity: 300,
        is_capacity: 100,
        level: 'ps'
      }, {
        name: 'Starfruit Sauna',
        org_id: 'K061',
        source: 'bluebook',
        bldg_id: 'K022',
        cartodb_id: 13,
        district: 1,
        subdistrict: 3,
        includeInCapacity: true,
        hs_capacity: 0,
        ps_capacity: 100,
        is_capacity: 300,
        level: 'is'
      }, {
        name: 'P.S. 91 - K',
        org_id: 'K091',
        source: 'bluebook',
        bldg_id: 'K091',
        cartodb_id: 14,
        district: 1,
        subdistrict: 2,
        includeInCapacity: true,
        hs_capacity: 500,
        ps_capacity: 100,
        is_capacity: 200,
        level: 'hs'
      }, {
        name: 'Banana Bonanza',
        bldg_id: 'LCGMS_BB1',
        cartodb_id: 21,
        source: 'lcgms',
        district: 1,
        subdistrict: 2,
        includeInCapacity: false,
        hs_capacity: 0,
        ps_capacity: 300,
        is_capacity: 500,
        level: 'ps'
      }, {
        name: 'Strawberry Sunrise',
        bldg_id: 'LCGMS_SS1',
        cartodb_id: 22,
        source: 'lcgms',
        district: 1,
        subdistrict: 3,
        includeInCapacity: true,
        hs_capacity: 0,
        ps_capacity: 200,
        is_capacity: 500,
        level: 'is'
      }, {
        name: 'Cantelope Castle',
        bldg_id: 'LCGMS_CC1',
        cartodb_id: 23,
        source: 'lcgms',
        district: 1,
        subdistrict: 2,
        includeInCapacity: true,
        hs_capacity: 100,
        ps_capacity: 200,
        is_capacity: 300,
        level: 'is'
      }],
      // SCA PROJECTS
      scaProjects: [// high schools
      {
        name: 'Donuts Delight',
        bldg_id: 'SCA_DD1',
        cartodb_id: 31,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: true,
        hs_capacity: 750,
        ps_capacity: 100,
        is_capacity: 100,
        level: 'hs'
      }, {
        name: 'Avocado Adventure',
        bldg_id: 'SCA_AA1',
        cartodb_id: 32,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: true,
        hs_capacity: 500,
        ps_capacity: 100,
        is_capacity: 100,
        level: 'hs'
      }, {
        name: 'Clementine Canopy',
        bldg_id: 'SCA_CC1',
        cartodb_id: 33,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: false,
        hs_capacity: 1500,
        ps_capacity: 100,
        is_capacity: 100,
        level: 'hs'
      }, // middle schools
      {
        name: 'Peach Party',
        bldg_id: 'SCA_PP1',
        cartodb_id: 34,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: true,
        hs_capacity: 100,
        ps_capacity: 100,
        is_capacity: 1200,
        level: 'is'
      }, {
        name: 'Passionfruit Pavilion',
        bldg_id: 'SCA_PP2',
        cartodb_id: 35,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: false,
        hs_capacity: 100,
        ps_capacity: 100,
        is_capacity: 550,
        level: 'is'
      }, {
        name: 'Tangerine Tent',
        bldg_id: 'SCA_TT1',
        cartodb_id: 36,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: true,
        hs_capacity: 100,
        ps_capacity: 100,
        is_capacity: 600,
        level: 'is'
      }, // primary schools
      {
        name: 'Pineapple Paradise',
        bldg_id: 'SCA_PP2',
        cartodb_id: 37,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: true,
        hs_capacity: 100,
        ps_capacity: 300,
        is_capacity: 100,
        level: 'ps'
      }, {
        name: 'Olive Oasis',
        bldg_id: 'SCA_OO1',
        cartodb_id: 38,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: false,
        hs_capacity: 100,
        ps_capacity: 250,
        is_capacity: 100,
        level: 'ps'
      }, {
        name: 'Grapefruit Garage',
        bldg_id: 'SCA_GG1',
        cartodb_id: 39,
        district: 1,
        subdistrict: 2,
        source: 'scaProjects',
        includeInCapacity: true,
        hs_capacity: 100,
        ps_capacity: 350,
        is_capacity: 100,
        level: 'ps'
      }]
    }),
    // TRAIT for testing subdistrictTotals
    subdistrictTotalsTest: (0, _emberCliMirage.trait)({
      project: (0, _emberCliMirage.association)({
        bbls: ['3023910001'],
        totalUnits: 500,
        seniorUnits: 50
      }),
      subdistrictsFromDb: function subdistrictsFromDb() {
        return [{
          id: 172,
          sdName: 'District 1 - Subdistrict 2',
          district: 1,
          cartodb_id: 25,
          subdistrict: 2
        }];
      },
      multipliers: {
        version: 'november-2018',
        districts: [{
          hs: 0.09,
          is: 0.09,
          ps: 0.24,
          csd: 1,
          borocode: 'bk',
          hsThreshold: 7126,
          psisThreshold: 630
        }],
        thresholdHsStudents: 150,
        thresholdPsIsStudents: 50
      },
      hsProjections: [{
        hs: 15000,
        year: 2020,
        borough: 'Brooklyn'
      }],
      hsStudentsFromHousing: 4000,
      futureEnrollmentProjections: [{
        ms: 4368,
        ps: 9198,
        district: 1,
        school_year: '2020-21',
        subdistrict: 2
      }, {
        ms: 5000,
        ps: 3200,
        district: 2,
        school_year: '2020-21'
      }],
      futureEnrollmentNewHousing: [{
        level: 'PS',
        district: 1,
        students: 838,
        subdistrict: 2
      }, {
        level: 'MS',
        district: 1,
        students: 323,
        subdistrict: 2
      }],
      schoolsWithAction: function schoolsWithAction() {
        return [{
          id: 172,
          name: 'William Gardner School for the Amaaaazing',
          sdName: 'District 17 - Subdistrict 2',
          district: 1,
          hs_seats: 200,
          is_seats: 300,
          ps_seats: 50,
          cartodb_id: 23,
          subdistrict: 2
        }, {
          id: 171,
          name: 'Watermelon Waterfall',
          sdName: 'District 17 - Subdistrict 1',
          district: 1,
          hs_seats: 100,
          is_seats: 500,
          ps_seats: 30,
          cartodb_id: 24,
          subdistrict: 2
        }, {
          id: 173,
          name: 'Lemon Luxury',
          sdName: 'District 17 - Subdistrict 2',
          district: 1,
          hs_seats: 150,
          is_seats: 50,
          ps_seats: 20,
          cartodb_id: 25,
          subdistrict: 2
        }];
      },
      residentialDevelopments: function residentialDevelopments() {
        return [{
          name: 'Hamster Heaven',
          district: 1,
          subdistrict: 2,
          multipliers: {},
          total_units: 60,
          ps_students: 100
        }, {
          name: 'Pig Palace',
          district: 1,
          subdistrict: 2,
          multipliers: {},
          total_units: 50,
          ps_students: 200
        }];
      }
    }),
    esSchoolChoice: false,
    isSchoolChoice: true,
    subdistrictsFromDb: function subdistrictsFromDb() {
      return [{
        id: 172,
        sdName: 'District 17 - Subdistrict 2',
        district: 17,
        cartodb_id: 25,
        subdistrict: 2
      }];
    },
    subdistrictsFromUser: function subdistrictsFromUser() {
      return [];
    },
    school_buildings: function school_buildings() {
      return [{
        x: 997684,
        y: 178345,
        name: 'I.S. 2 - K',
        level: 'is',
        enroll: 493,
        org_id: 'K002',
        source: 'bluebook',
        address: '655 PARKSIDE AVENUE',
        bldg_id: 'K002',
        borocode: 3,
        capacity: 669,
        district: 17,
        excluded: false,
        the_geom: '0101000020E610000016CB4EBBE67C52C01B8201C8FD534440',
        bldg_name: 'I.S. 2 - K',
        hs_enroll: 0,
        ms_enroll: 493,
        org_level: 'IS',
        ps_enroll: 0,
        cartodb_id: 893,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 669,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 669,
        the_geom_webmercator: '0101000020110F0000E42C33224F675FC19D6ADE0878ED5241'
      }, {
        x: 998338,
        y: 181196,
        name: 'I.S. 61 - K',
        level: 'is',
        enroll: 725,
        org_id: 'K061',
        source: 'bluebook',
        address: '400 EMPIRE BLVD',
        bldg_id: 'K061',
        borocode: 3,
        capacity: 1320,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000D04B0E04C07C52C0B6B2672BFE544440',
        bldg_name: 'I.S. 61 - K',
        hs_enroll: 0,
        ms_enroll: 725,
        org_level: 'IS',
        ps_enroll: 0,
        cartodb_id: 895,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 1320,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 1320,
        the_geom_webmercator: '0101000020110F000094EDF05E0D675FC18ADCF21497EE5241'
      }, {
        x: 1000913,
        y: 180425,
        name: 'P.S. 91 - K',
        level: 'ps',
        enroll: 260,
        org_id: 'K091',
        source: 'bluebook',
        address: '532 ALBANY AVENUE',
        bldg_id: 'K091',
        borocode: 3,
        capacity: 675,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000C35B31FA277C52C04786B8ADB8544440',
        bldg_name: 'P.S. 91 - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 260,
        cartodb_id: 896,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 675,
        subdistrict: 1,
        capacityFuture: 675,
        the_geom_webmercator: '0101000020110F00007A773D1E0B665FC1DC5E1F4749EE5241'
      }, {
        x: 997085,
        y: 178295,
        name: 'P.S. 92 - K',
        level: 'ps',
        enroll: 424,
        org_id: 'K092',
        source: 'bluebook',
        address: '601 PARKSIDE AVENUE',
        bldg_id: 'K092',
        borocode: 3,
        capacity: 724,
        district: 17,
        excluded: false,
        the_geom: '0101000020E610000041BA8F1A0A7D52C01AF43650F9534440',
        bldg_name: 'P.S. 92 - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 424,
        cartodb_id: 897,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 724,
        subdistrict: 1,
        capacityFuture: 724,
        the_geom_webmercator: '0101000020110F0000259E75378B675FC11423610873ED5241'
      }, {
        x: 998395,
        y: 181988,
        name: 'P.S. 161 - K',
        level: 'ps',
        enroll: 367,
        org_id: 'K161',
        source: 'bluebook',
        address: '330 CROWN STREET',
        bldg_id: 'K161',
        borocode: 3,
        capacity: 383,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000002788569FBC7C52C058B4556645554440',
        bldg_name: 'P.S. 161 - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 367,
        cartodb_id: 900,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 383,
        subdistrict: 1,
        capacityFuture: 383,
        the_geom_webmercator: '0101000020110F00002E23569B07675FC155F9F0D5E6EE5241'
      }, {
        x: 1004711,
        y: 181768,
        name: 'P.S. 189 - K',
        level: 'ps',
        enroll: 561,
        org_id: 'K189',
        source: 'bluebook',
        address: '1100 EAST NEW YORK AVE',
        bldg_id: 'K189',
        borocode: 3,
        capacity: 610,
        district: 17,
        excluded: false,
        the_geom: '0101000020E610000033B3489F477B52C09D28523531554440',
        bldg_name: 'P.S. 189 - K',
        hs_enroll: 0,
        ms_enroll: 332,
        org_level: 'PSIS',
        ps_enroll: 561,
        cartodb_id: 902,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 361,
        ps_capacity: 610,
        subdistrict: 2,
        capacityFuture: 610,
        the_geom_webmercator: '0101000020110F0000A59344078E645FC19CC3503AD0EE5241'
      }, {
        x: 1004711,
        y: 181768,
        name: 'P.S. 189 - K',
        level: 'is',
        enroll: 332,
        org_id: 'K189',
        source: 'bluebook',
        address: '1100 EAST NEW YORK AVE',
        bldg_id: 'K189',
        borocode: 3,
        capacity: 361,
        district: 17,
        excluded: false,
        the_geom: '0101000020E610000033B3489F477B52C09D28523531554440',
        bldg_name: 'P.S. 189 - K',
        hs_enroll: 0,
        ms_enroll: 332,
        org_level: 'PSIS',
        ps_enroll: 561,
        cartodb_id: 902,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 361,
        ps_capacity: 610,
        subdistrict: 2,
        capacityFuture: 361,
        the_geom_webmercator: '0101000020110F0000A59344078E645FC19CC3503AD0EE5241'
      }, {
        x: 1004711,
        y: 181768,
        name: 'P.S. 189 - K',
        level: 'ps',
        enroll: 101,
        org_id: 'K189',
        source: 'bluebook',
        address: '1100 EAST NEW YORK AVE',
        bldg_id: 'K889',
        borocode: 3,
        capacity: 67,
        district: 17,
        excluded: true,
        the_geom: '0101000020E610000033B3489F477B52C09D28523531554440',
        bldg_name: 'P.S. 189 MINISCHOOL - K',
        hs_enroll: 0,
        ms_enroll: 60,
        org_level: 'PSIS',
        ps_enroll: 101,
        cartodb_id: 903,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 40,
        ps_capacity: 67,
        subdistrict: 2,
        capacityFuture: 67,
        the_geom_webmercator: '0101000020110F0000A59344078E645FC19CC3503AD0EE5241'
      }, {
        x: 1004711,
        y: 181768,
        name: 'P.S. 189 - K',
        level: 'is',
        enroll: 60,
        org_id: 'K189',
        source: 'bluebook',
        address: '1100 EAST NEW YORK AVE',
        bldg_id: 'K889',
        borocode: 3,
        capacity: 40,
        district: 17,
        excluded: true,
        the_geom: '0101000020E610000033B3489F477B52C09D28523531554440',
        bldg_name: 'P.S. 189 MINISCHOOL - K',
        hs_enroll: 0,
        ms_enroll: 60,
        org_level: 'PSIS',
        ps_enroll: 101,
        cartodb_id: 903,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 40,
        ps_capacity: 67,
        subdistrict: 2,
        capacityFuture: 40,
        the_geom_webmercator: '0101000020110F0000A59344078E645FC19CC3503AD0EE5241'
      }, {
        x: 1001992,
        y: 181087,
        name: 'P.S. 221 - K',
        level: 'ps',
        enroll: 200,
        org_id: 'K221',
        source: 'bluebook',
        address: '791 EMPIRE BLVD',
        bldg_id: 'K221',
        borocode: 3,
        capacity: 319,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000A191A93AE87B52C0600B9926F4544440',
        bldg_name: 'P.S. 221 - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 200,
        cartodb_id: 905,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 319,
        subdistrict: 2,
        capacityFuture: '323',
        the_geom_webmercator: '0101000020110F00003D1EE0D59E655FC1C5AB47DD8BEE5241'
      }, {
        x: 1001992,
        y: 181087,
        name: 'P.S. 221 - K',
        level: 'ps',
        enroll: 100,
        org_id: 'K221',
        source: 'bluebook',
        address: '791 EMPIRE BLVD',
        bldg_id: 'K892',
        borocode: 3,
        capacity: 197,
        district: 17,
        excluded: true,
        the_geom: '0101000020E6100000A191A93AE87B52C0600B9926F4544440',
        bldg_name: 'P.S. 221 MINISCHOOL - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 100,
        cartodb_id: 906,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 197,
        subdistrict: 2,
        capacityFuture: 197,
        the_geom_webmercator: '0101000020110F00003D1EE0D59E655FC1C5AB47DD8BEE5241'
      }, {
        x: 995159,
        y: 183137,
        name: 'P.S. 241 - K',
        level: 'ps',
        enroll: 465,
        org_id: 'K241',
        source: 'bluebook',
        address: '976 PRESIDENT STREET',
        bldg_id: 'K241',
        borocode: 3,
        capacity: 616,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000A25F40B47B7D52C0DB014EE4AC554440',
        bldg_name: 'P.S. 241 - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 465,
        cartodb_id: 907,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 616,
        subdistrict: 2,
        capacityFuture: 616,
        the_geom_webmercator: '0101000020110F0000F9EAA22D4C685FC1A91378B75AEF5241'
      }, {
        x: 995159,
        y: 183137,
        name: 'P.S. 241 - K',
        level: 'ps',
        enroll: 56,
        org_id: 'K241',
        source: 'bluebook',
        address: '976 PRESIDENT STREET',
        bldg_id: 'K840',
        borocode: 3,
        capacity: 84,
        district: 17,
        excluded: true,
        the_geom: '0101000020E6100000A25F40B47B7D52C0DB014EE4AC554440',
        bldg_name: 'P.S. 241 MINISCHOOL - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 56,
        cartodb_id: 908,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 84,
        subdistrict: 2,
        capacityFuture: 84,
        the_geom_webmercator: '0101000020110F0000F9EAA22D4C685FC1A91378B75AEF5241'
      }, {
        x: 995616,
        y: 181569,
        name: 'M.S. 352 - K',
        level: 'is',
        enroll: 167,
        org_id: 'K352',
        source: 'bluebook',
        address: '46 MCKEEVER PLACE',
        bldg_id: 'K320',
        borocode: 3,
        capacity: 241,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000EB95AEC1607D52C01BA180D81F554440',
        bldg_name: 'P.S. 375 - K',
        hs_enroll: 0,
        ms_enroll: 167,
        org_level: 'IS',
        ps_enroll: 0,
        cartodb_id: 913,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 241,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 241,
        the_geom_webmercator: '0101000020110F0000CAD0B8671E685FC1D4E998C9BCEE5241'
      }, {
        x: 995616,
        y: 181569,
        name: 'P.S. 375 - K',
        level: 'ps',
        enroll: 387,
        org_id: 'K375',
        source: 'bluebook',
        address: '46 MCKEEVER PLACE',
        bldg_id: 'K320',
        borocode: 3,
        capacity: 900,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000EB95AEC1607D52C01BA180D81F554440',
        bldg_name: 'P.S. 375 - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 387,
        cartodb_id: 916,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 900,
        subdistrict: 2,
        capacityFuture: 900,
        the_geom_webmercator: '0101000020110F0000CAD0B8671E685FC1D4E998C9BCEE5241'
      }, {
        x: 999152,
        y: 179350,
        name: 'P.S. 397 - K',
        level: 'ps',
        enroll: 195,
        org_id: 'K397',
        source: 'bluebook',
        address: '490 FENIMORE STREET',
        bldg_id: 'K397',
        borocode: 3,
        capacity: 243,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005437CC02907C52C0DD844F1858544440',
        bldg_name: 'P.S. 397 - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 195,
        cartodb_id: 919,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 243,
        subdistrict: 2,
        capacityFuture: 243,
        the_geom_webmercator: '0101000020110F0000DE4866D4BB665FC10188EA24DDED5241'
      }, {
        x: 1004514,
        y: 181037,
        name: 'P.S. 398 - K',
        level: 'ps',
        enroll: 297,
        org_id: 'K398',
        source: 'bluebook',
        address: '60 EAST 94 STREET',
        bldg_id: 'K398',
        borocode: 3,
        capacity: 445,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005533E44A537B52C05ABBF279EF544440',
        bldg_name: 'P.S. 398 (UDC) - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 297,
        cartodb_id: 920,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 445,
        subdistrict: 2,
        capacityFuture: 445,
        the_geom_webmercator: '0101000020110F0000C40C05DAA1645FC1F4C476A186EE5241'
      }, {
        x: 1000215,
        y: 179334,
        name: 'I.S. 531 - K',
        level: 'is',
        enroll: 51,
        org_id: 'K531',
        source: 'bluebook',
        address: '600 KINGSTON AVENUE',
        bldg_id: 'K470',
        borocode: 3,
        capacity: 141,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005812543D517C52C0ACC35E9856544440',
        bldg_name: 'GEORGE W. WINGATE HS - K',
        hs_enroll: 390,
        ms_enroll: 51,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 927,
        dataVersion: 'november-2018',
        hs_capacity: 1077,
        ms_capacity: 141,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 141,
        the_geom_webmercator: '0101000020110F00007BF3C93451665FC1525A1277DBED5241'
      }, {
        x: 997797,
        y: 182357,
        name: 'MEDGAR EVERS COLLEGE PREPARATORY SCHOOL',
        level: 'is',
        enroll: 356,
        org_id: 'K590',
        source: 'bluebook',
        address: '1186 CARROLL STREET',
        bldg_id: 'K590',
        borocode: 3,
        capacity: 205,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000008A1A52EDDF7C52C04B5E5A9E66554440',
        bldg_name: 'MIDDLE COLLEGE HS - K',
        hs_enroll: 901,
        ms_enroll: 356,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 936,
        dataVersion: 'november-2018',
        hs_capacity: 518,
        ms_capacity: 205,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 205,
        the_geom_webmercator: '0101000020110F00006973429343675FC148A4CF070CEF5241'
      }, {
        x: 1001900,
        y: 180630,
        name: 'I.S. 722 - K',
        level: 'is',
        enroll: 234,
        org_id: 'K722',
        source: 'bluebook',
        address: '790 EAST NEW YORK AVE',
        bldg_id: 'K391',
        borocode: 3,
        capacity: 725,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000F2CC90AEED7B52C068ACC60DCB544440',
        bldg_name: 'I.S. 391 - K',
        hs_enroll: 0,
        ms_enroll: 234,
        org_level: 'IS',
        ps_enroll: 0,
        cartodb_id: 939,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 725,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 725,
        the_geom_webmercator: '0101000020110F00001A25F518A8655FC1A52FDAD95DEE5241'
      }, {
        x: 1004514,
        y: 181037,
        name: 'P.S. 770 - K',
        level: 'ps',
        enroll: 289,
        org_id: 'K770',
        source: 'bluebook',
        address: '60 EAST 94 STREET',
        bldg_id: 'K398',
        borocode: 3,
        capacity: 206,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005533E44A537B52C05ABBF279EF544440',
        bldg_name: 'P.S. 398 (UDC) - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'PS',
        ps_enroll: 289,
        cartodb_id: 942,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 206,
        subdistrict: 2,
        capacityFuture: 206,
        the_geom_webmercator: '0101000020110F0000C40C05DAA1645FC1F4C476A186EE5241'
      }, {
        x: 982808,
        y: 178169,
        name: 'SUNSET PARK HS - K',
        level: 'hs',
        enroll: 1230,
        org_id: 'K667',
        source: 'bluebook',
        address: '153 35TH STREET',
        bldg_id: 'K564',
        borocode: 3,
        capacity: 1246,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000642F0326558052C055A11F48EE534440',
        bldg_name: 'SUNSET PARK HS - K',
        hs_enroll: 1230,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 859,
        dataVersion: 'november-2018',
        hs_capacity: 1246,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 1246,
        the_geom_webmercator: '0101000020110F00006FBB8336236D5FC1798CC8AE66ED5241'
      }, {
        x: 990966,
        y: 192967,
        name: 'I.S. 265 - K',
        level: 'hs',
        enroll: 261,
        org_id: 'K265',
        source: 'bluebook',
        address: '101 PARK AVENUE',
        bldg_id: 'K265',
        borocode: 3,
        capacity: 514,
        district: 13,
        excluded: false,
        the_geom: '0101000020E6100000792DA62F737E52C01954452521594440',
        bldg_name: 'I.S. 265 - K',
        hs_enroll: 261,
        ms_enroll: 109,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 744,
        dataVersion: 'november-2018',
        hs_capacity: 514,
        ms_capacity: 214,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 514,
        the_geom_webmercator: '0101000020110F0000D69A278DF0695FC117FE120C39F35241'
      }, {
        x: 988405,
        y: 193336,
        name: 'URBAN ASSEMBLY SCHOOL FOR MUSIC AND ART-K',
        level: 'hs',
        enroll: 221,
        org_id: 'K350',
        source: 'bluebook',
        address: '49 FLATBUSH AVENUE EXTENSION',
        bldg_id: 'K805',
        borocode: 3,
        capacity: 457,
        district: 13,
        excluded: false,
        the_geom: '0101000020E6100000710DAB7F0A7F52C0A7DC9B6242594440',
        bldg_name: 'WATERS EDGE EDUCATIONAL COMPLEX - K',
        hs_enroll: 221,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 754,
        dataVersion: 'november-2018',
        hs_capacity: 457,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 457,
        the_geom_webmercator: '0101000020110F000002562E92F16A5FC122D735485EF35241'
      }, {
        x: 995677,
        y: 191627,
        name: 'BROOKLYN COMMUNITY ARTS & MEDIA HIGH SCHOOL (BCAM) - K',
        level: 'hs',
        enroll: 425,
        org_id: 'K412',
        source: 'bluebook',
        address: '300 WILLOUGHBY AVENUE',
        bldg_id: 'K117',
        borocode: 3,
        capacity: 619,
        district: 13,
        excluded: false,
        the_geom: '0101000020E6100000B60AEADF5C7D52C0349E8D77A8584440',
        bldg_name: 'I.S. 117 - K',
        hs_enroll: 425,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 756,
        dataVersion: 'november-2018',
        hs_capacity: 619,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 619,
        the_geom_webmercator: '0101000020110F0000FE18B5CF17685FC12F6F3DDEB1F25241'
      }, {
        x: 988405,
        y: 193336,
        name: 'SCIENCE SKILLS CENTER HS - K',
        level: 'hs',
        enroll: 562,
        org_id: 'K419',
        source: 'bluebook',
        address: '49 FLATBUSH AVENUE EXTENSION',
        bldg_id: 'K805',
        borocode: 3,
        capacity: 680,
        district: 13,
        excluded: false,
        the_geom: '0101000020E6100000710DAB7F0A7F52C0A7DC9B6242594440',
        bldg_name: 'WATERS EDGE EDUCATIONAL COMPLEX - K',
        hs_enroll: 562,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 757,
        dataVersion: 'november-2018',
        hs_capacity: 680,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 680,
        the_geom_webmercator: '0101000020110F000002562E92F16A5FC122D735485EF35241'
      }, {
        x: 988405,
        y: 193336,
        name: 'BROOKLYN INTERNATIONAL HS - K',
        level: 'hs',
        enroll: 354,
        org_id: 'K439',
        source: 'bluebook',
        address: '49 FLATBUSH AVENUE EXTENSION',
        bldg_id: 'K805',
        borocode: 3,
        capacity: 464,
        district: 13,
        excluded: false,
        the_geom: '0101000020E6100000710DAB7F0A7F52C0A7DC9B6242594440',
        bldg_name: 'WATERS EDGE EDUCATIONAL COMPLEX - K',
        hs_enroll: 354,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 758,
        dataVersion: 'november-2018',
        hs_capacity: 464,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 464,
        the_geom_webmercator: '0101000020110F000002562E92F16A5FC122D735485EF35241'
      }, {
        x: 987341,
        y: 192368,
        name: 'URBAN ASSEMBLY HS - K',
        level: 'hs',
        enroll: 449,
        org_id: 'K483',
        source: 'bluebook',
        address: '283 ADAMS STREET',
        bldg_id: 'K313',
        borocode: 3,
        capacity: 437,
        district: 13,
        excluded: false,
        the_geom: '0101000020E61000003C5D8B5F497F52C0318C4656EB584440',
        bldg_name: 'ADAMS STREET EDUCATIONAL CAMPUS - K',
        hs_enroll: 449,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 759,
        dataVersion: 'november-2018',
        hs_capacity: 437,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 437,
        the_geom_webmercator: '0101000020110F0000697EA55E5C6B5FC186EAABC5FCF25241'
      }, {
        x: 987341,
        y: 192368,
        name: 'I.S. 527 - K',
        level: 'hs',
        enroll: 298,
        org_id: 'K527',
        source: 'bluebook',
        address: '283 ADAMS STREET',
        bldg_id: 'K313',
        borocode: 3,
        capacity: 381,
        district: 13,
        excluded: false,
        the_geom: '0101000020E61000003C5D8B5F497F52C0318C4656EB584440',
        bldg_name: 'ADAMS STREET EDUCATIONAL CAMPUS - K',
        hs_enroll: 298,
        ms_enroll: 182,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 761,
        dataVersion: 'november-2018',
        hs_capacity: 381,
        ms_capacity: 233,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 381,
        the_geom_webmercator: '0101000020110F0000697EA55E5C6B5FC186EAABC5FCF25241'
      }, {
        x: 998907,
        y: 188846,
        name: 'BROOKLYN ACADEMY HS - K',
        level: 'hs',
        enroll: 157,
        org_id: 'K553',
        source: 'bluebook',
        address: '832 MARCY AVENUE',
        bldg_id: 'K458',
        borocode: 3,
        capacity: 399,
        district: 13,
        excluded: false,
        the_geom: '0101000020E61000006365EA239E7C52C054E9A92FAE574440',
        bldg_name: 'BOYS HS (OLD) - K',
        hs_enroll: 157,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 762,
        dataVersion: 'november-2018',
        hs_capacity: 399,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 399,
        the_geom_webmercator: '0101000020110F00008F3270D4D3665FC1841DB88999F15241'
      }, {
        x: 998907,
        y: 188846,
        name: 'BED-STUYVESANT PREPARATORY HS - K',
        level: 'hs',
        enroll: 124,
        org_id: 'K575',
        source: 'bluebook',
        address: '832 MARCY AVENUE',
        bldg_id: 'K458',
        borocode: 3,
        capacity: 256,
        district: 13,
        excluded: false,
        the_geom: '0101000020E61000006365EA239E7C52C054E9A92FAE574440',
        bldg_name: 'BOYS HS (OLD) - K',
        hs_enroll: 124,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 763,
        dataVersion: 'november-2018',
        hs_capacity: 256,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 256,
        the_geom_webmercator: '0101000020110F00008F3270D4D3665FC1841DB88999F15241'
      }, {
        x: 996924,
        y: 188982,
        name: 'BEDFORD ACADEMY HS - K',
        level: 'hs',
        enroll: 364,
        org_id: 'K595',
        source: 'bluebook',
        address: '1119 BEDFORD AVENUE',
        bldg_id: 'K994',
        borocode: 3,
        capacity: 299,
        district: 13,
        excluded: false,
        the_geom: '0101000020E6100000E8A70D48137D52C0E35AB884BA574440',
        bldg_name: 'BEDFORD ACADEMY - K',
        hs_enroll: 364,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 764,
        dataVersion: 'november-2018',
        hs_capacity: 299,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 299,
        the_geom_webmercator: '0101000020110F0000EBD04ECE9A675FC1D886A059A7F15241'
      }, {
        x: 988128,
        y: 192457,
        name: 'GEORGE WESTINGHOUSE VOC. HS',
        level: 'hs',
        enroll: 756,
        org_id: 'K605',
        source: 'bluebook',
        address: '105 JOHNSON STREET',
        bldg_id: 'K580',
        borocode: 3,
        capacity: 910,
        district: 13,
        excluded: false,
        the_geom: '0101000020E610000084F5ADDF1A7F52C0E2C8E154F3584440',
        bldg_name: 'GEORGE WESTINGHOUSE VOC HS - K',
        hs_enroll: 756,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 765,
        dataVersion: 'november-2018',
        hs_capacity: 910,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 910,
        the_geom_webmercator: '0101000020110F00004CEFBB620D6B5FC1AB5434BA05F35241'
      }, {
        x: 995677,
        y: 191627,
        name: 'BKLYN HS FOR LEADERSHIP & COMM - K',
        level: 'hs',
        enroll: 205,
        org_id: 'K616',
        source: 'bluebook',
        address: '300 WILLOUGHBY AVENUE',
        bldg_id: 'K117',
        borocode: 3,
        capacity: 318,
        district: 13,
        excluded: false,
        the_geom: '0101000020E6100000B60AEADF5C7D52C0349E8D77A8584440',
        bldg_name: 'I.S. 117 - K',
        hs_enroll: 205,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 766,
        dataVersion: 'november-2018',
        hs_capacity: 318,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 318,
        the_geom_webmercator: '0101000020110F0000FE18B5CF17685FC12F6F3DDEB1F25241'
      }, {
        x: 992779,
        y: 192606,
        name: 'BENJAMIN BANNEKER ACAD. (HS LVL)-K',
        level: 'hs',
        enroll: 844,
        org_id: 'K670',
        source: 'bluebook',
        address: '71-77 CLINTON AVENUE',
        bldg_id: 'K914',
        borocode: 3,
        capacity: 930,
        district: 13,
        excluded: false,
        the_geom: '0101000020E61000005D47A212087E52C04CCF39A000594440',
        bldg_name: 'BENJAMIN BANNEKER ACADEMY - K',
        hs_enroll: 844,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 767,
        dataVersion: 'november-2018',
        hs_capacity: 930,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 930,
        the_geom_webmercator: '0101000020110F0000670ECD9B3A695FC127E6859E14F35241'
      }, {
        x: 988128,
        y: 192457,
        name: 'CITY POLYTECHNIC HIGH SCHOOL OF ENGINEERING, ARCHITECTURE, AND TECHNOLOGY - K',
        level: 'hs',
        enroll: 439,
        org_id: 'K674',
        source: 'bluebook',
        address: '105 JOHNSON STREET',
        bldg_id: 'K580',
        borocode: 3,
        capacity: 506,
        district: 13,
        excluded: false,
        the_geom: '0101000020E610000084F5ADDF1A7F52C0E2C8E154F3584440',
        bldg_name: 'GEORGE WESTINGHOUSE VOC HS - K',
        hs_enroll: 439,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 768,
        dataVersion: 'november-2018',
        hs_capacity: 506,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 506,
        the_geom_webmercator: '0101000020110F00004CEFBB620D6B5FC1AB5434BA05F35241'
      }, {
        x: 997241,
        y: 195629,
        name: 'I.S. 71 - K',
        level: 'hs',
        enroll: 319,
        org_id: 'K071',
        source: 'bluebook',
        address: '215 HEYWARD STREET',
        bldg_id: 'K071',
        borocode: 3,
        capacity: 565,
        district: 14,
        excluded: false,
        the_geom: '0101000020E610000071013858007D52C050689656105A4440',
        bldg_name: 'I.S. 71 - K',
        hs_enroll: 319,
        ms_enroll: 215,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 778,
        dataVersion: 'november-2018',
        hs_capacity: 565,
        ms_capacity: 380,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 565,
        the_geom_webmercator: '0101000020110F0000FEE4C5A37A675FC15552B20045F45241'
      }, {
        x: 999875,
        y: 197932,
        name: 'THE WILLIAMSBURG HIGH SCHOOL OF ART AND TECHNOLOGY - K',
        level: 'hs',
        enroll: 269,
        org_id: 'K454',
        source: 'bluebook',
        address: '223 GRAHAM AVENUE',
        bldg_id: 'K049',
        borocode: 3,
        capacity: 423,
        district: 14,
        excluded: false,
        the_geom: '0101000020E6100000453A4C9C647C52C0624FD854DF5A4440',
        bldg_name: 'I.S. 49 - K',
        hs_enroll: 269,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 794,
        dataVersion: 'november-2018',
        hs_capacity: 423,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 423,
        the_geom_webmercator: '0101000020110F000069EB181C72665FC1D6A119E92CF55241'
      }, {
        x: 1000961,
        y: 198750,
        name: 'PROGRESS HS - K',
        level: 'hs',
        enroll: 766,
        org_id: 'K474',
        source: 'bluebook',
        address: '850 GRAND STREET',
        bldg_id: 'K450',
        borocode: 3,
        capacity: 1124,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000000563E565247C52C06A636DD6285B4440',
        bldg_name: 'GRAND STREET CAMPUS (OLD E.D.HS)-K',
        hs_enroll: 766,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 795,
        dataVersion: 'november-2018',
        hs_capacity: 1124,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 1124,
        the_geom_webmercator: '0101000020110F0000435ED10905665FC1B1AB01457FF55241'
      }, {
        x: 1000961,
        y: 198750,
        name: 'EAST WILLIAMSBURG SCHOLARS ACADEMY - K',
        level: 'hs',
        enroll: 335,
        org_id: 'K477',
        source: 'bluebook',
        address: '850 GRAND STREET',
        bldg_id: 'K450',
        borocode: 3,
        capacity: 1043,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000000563E565247C52C06A636DD6285B4440',
        bldg_name: 'GRAND STREET CAMPUS (OLD E.D.HS)-K',
        hs_enroll: 335,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 796,
        dataVersion: 'november-2018',
        hs_capacity: 1043,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 1043,
        the_geom_webmercator: '0101000020110F0000435ED10905665FC1B1AB01457FF55241'
      }, {
        x: 1000961,
        y: 198750,
        name: 'HS OF ENTERPRISE, BUS. & TECH. - K',
        level: 'hs',
        enroll: 872,
        org_id: 'K478',
        source: 'bluebook',
        address: '850 GRAND STREET',
        bldg_id: 'K450',
        borocode: 3,
        capacity: 820,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000000563E565247C52C06A636DD6285B4440',
        bldg_name: 'GRAND STREET CAMPUS (OLD E.D.HS)-K',
        hs_enroll: 872,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 797,
        dataVersion: 'november-2018',
        hs_capacity: 820,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 820,
        the_geom_webmercator: '0101000020110F0000435ED10905665FC1B1AB01457FF55241'
      }, {
        x: 996793,
        y: 199800,
        name: 'BROOKLYN PREPARATORY HS - K',
        level: 'hs',
        enroll: 512,
        org_id: 'K488',
        source: 'bluebook',
        address: '257 N 6TH STREET',
        bldg_id: 'K650',
        borocode: 3,
        capacity: 637,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000003554B1B01A7D52C037DA3080875B4440',
        bldg_name: 'HARRY VANARSDALE VOC HS(WHITNEY)-K',
        hs_enroll: 512,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 798,
        dataVersion: 'november-2018',
        hs_capacity: 637,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 637,
        the_geom_webmercator: '0101000020110F000057CFF063A7675FC11A527256E9F55241'
      }, {
        x: 996793,
        y: 199800,
        name: 'WILLIAMSBURG HS FOR ARCH & DES-K',
        level: 'hs',
        enroll: 566,
        org_id: 'K558',
        source: 'bluebook',
        address: '257 N 6TH STREET',
        bldg_id: 'K650',
        borocode: 3,
        capacity: 586,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000003554B1B01A7D52C037DA3080875B4440',
        bldg_name: 'HARRY VANARSDALE VOC HS(WHITNEY)-K',
        hs_enroll: 566,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 799,
        dataVersion: 'november-2018',
        hs_capacity: 586,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 586,
        the_geom_webmercator: '0101000020110F000057CFF063A7675FC11A527256E9F55241'
      }, {
        x: 996793,
        y: 199800,
        name: 'WIILIAMSBURG PREP- K',
        level: 'hs',
        enroll: 676,
        org_id: 'K561',
        source: 'bluebook',
        address: '257 N 6TH STREET',
        bldg_id: 'K650',
        borocode: 3,
        capacity: 626,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000003554B1B01A7D52C037DA3080875B4440',
        bldg_name: 'HARRY VANARSDALE VOC HS(WHITNEY)-K',
        hs_enroll: 676,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 800,
        dataVersion: 'november-2018',
        hs_capacity: 626,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 626,
        the_geom_webmercator: '0101000020110F000057CFF063A7675FC11A527256E9F55241'
      }, {
        x: 999875,
        y: 197932,
        name: 'LYONS COMMUNITY SCHOOL - K',
        level: 'hs',
        enroll: 331,
        org_id: 'K586',
        source: 'bluebook',
        address: '223 GRAHAM AVENUE',
        bldg_id: 'K049',
        borocode: 3,
        capacity: 411,
        district: 14,
        excluded: false,
        the_geom: '0101000020E6100000453A4C9C647C52C0624FD854DF5A4440',
        bldg_name: 'I.S. 49 - K',
        hs_enroll: 331,
        ms_enroll: 165,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 803,
        dataVersion: 'november-2018',
        hs_capacity: 411,
        ms_capacity: 205,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 411,
        the_geom_webmercator: '0101000020110F000069EB181C72665FC1D6A119E92CF55241'
      }, {
        x: 997200,
        y: 202317,
        name: 'AUTOMOTIVE TRADES VOC HS - K',
        level: 'hs',
        enroll: 399,
        org_id: 'K610',
        source: 'bluebook',
        address: '50 BEDFORD AVENUE',
        bldg_id: 'K610',
        borocode: 3,
        capacity: 1142,
        district: 14,
        excluded: false,
        the_geom: '0101000020E6100000E17A7B8E027D52C0C94377DC695C4440',
        bldg_name: 'AUTOMOTIVE TRADES VOC HS - K',
        hs_enroll: 399,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 804,
        dataVersion: 'november-2018',
        hs_capacity: 1142,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 1142,
        the_geom_webmercator: '0101000020110F000073F1A0657E675FC1B20617FDE6F65241'
      }, {
        x: 1001050,
        y: 196207,
        name: "YOUNG WOMEN'S LEADERSHIP SCHOOL - BROOKLYN",
        level: 'hs',
        enroll: 243,
        org_id: 'K614',
        source: 'bluebook',
        address: '325 BUSHWICK AVENUE',
        bldg_id: 'K147',
        borocode: 3,
        capacity: 375,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000001D06003E1F7C52C0B8411B1D445A4440',
        bldg_name: 'P.S. 147 - K',
        hs_enroll: 243,
        ms_enroll: 163,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 805,
        dataVersion: 'november-2018',
        hs_capacity: 375,
        ms_capacity: 252,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 375,
        the_geom_webmercator: '0101000020110F0000567DD747FC655FC1129F0F027FF45241'
      }, {
        x: 996557,
        y: 196457,
        name: 'EL PUENTE ACADEMY - K',
        level: 'hs',
        enroll: 236,
        org_id: 'K685',
        source: 'bluebook',
        address: '250 HOOPER STREET',
        bldg_id: 'K778',
        borocode: 3,
        capacity: 240,
        district: 14,
        excluded: false,
        the_geom: '0101000020E61000003A44FFBC287D52C07CAF48D75A5A4440',
        bldg_name: 'EL PUENTE HS - K',
        hs_enroll: 236,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 806,
        dataVersion: 'november-2018',
        hs_capacity: 240,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 240,
        the_geom_webmercator: '0101000020110F0000BF2AA040BF675FC16A836B7898F45241'
      }, {
        x: 987264,
        y: 190715,
        name: 'BROOKLYN FRONTIERS HIGH SCHOOL - K',
        level: 'hs',
        enroll: 168,
        org_id: 'K423',
        source: 'bluebook',
        address: '112 SCHERMERHORN STREET',
        bldg_id: 'K520',
        borocode: 3,
        capacity: 242,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000C55F4CEF4D7F52C086E984AA56584440',
        bldg_name: 'PACIFIC ALT HS - K',
        hs_enroll: 168,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 841,
        dataVersion: 'november-2018',
        hs_capacity: 242,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 242,
        the_geom_webmercator: '0101000020110F0000F0AC311E646B5FC1C673113E56F25241'
      }, {
        x: 986094,
        y: 188993,
        name: 'DIGITAL ARTS AND CINEMA TECHNOLOGY HIGH SCHOOL - K',
        level: 'hs',
        enroll: 206,
        org_id: 'K429',
        source: 'bluebook',
        address: '284 BALTIC STREET',
        bldg_id: 'K293',
        borocode: 3,
        capacity: 302,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000BCDBBA10937F52C0BEF67FCCBB574440',
        bldg_name: 'J.H.S. 293 - K',
        hs_enroll: 206,
        ms_enroll: 0,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 842,
        dataVersion: 'november-2018',
        hs_capacity: 302,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 302,
        the_geom_webmercator: '0101000020110F0000AAFC0F8BD96B5FC10FF2BDC8A8F15241'
      }, {
        x: 983868,
        y: 187044,
        name: 'I.S. 448 - K',
        level: 'hs',
        enroll: 415,
        org_id: 'K448',
        source: 'bluebook',
        address: '610 HENRY STREET',
        bldg_id: 'K142',
        borocode: 3,
        capacity: 465,
        district: 15,
        excluded: false,
        the_geom: '0101000020E610000039139E90168052C033A0AB820C574440',
        bldg_name: 'J.H.S. 142 - K',
        hs_enroll: 415,
        ms_enroll: 266,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 847,
        dataVersion: 'november-2018',
        hs_capacity: 465,
        ms_capacity: 298,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 465,
        the_geom_webmercator: '0101000020110F0000922290E8B86C5FC10806D577E4F05241'
      }, {
        x: 989996,
        y: 183241,
        name: 'SECONDARY SCHOOL FOR LAW - K',
        level: 'hs',
        enroll: 439,
        org_id: 'K462',
        source: 'bluebook',
        address: '237 7TH AVENUE',
        bldg_id: 'K460',
        borocode: 3,
        capacity: 609,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000DDAB62A2AC7E52C0EEC5A067B6554440',
        bldg_name: 'JOHN JAY EDUCATIONAL CAMPUS - K',
        hs_enroll: 439,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 848,
        dataVersion: 'november-2018',
        hs_capacity: 609,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 609,
        the_geom_webmercator: '0101000020110F000062B70622526A5FC12765645E65EF5241'
      }, {
        x: 989996,
        y: 183241,
        name: 'SECONDARY SCHOOL FOR JOURNALISM-K',
        level: 'hs',
        enroll: 215,
        org_id: 'K463',
        source: 'bluebook',
        address: '237 7TH AVENUE',
        bldg_id: 'K460',
        borocode: 3,
        capacity: 257,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000DDAB62A2AC7E52C0EEC5A067B6554440',
        bldg_name: 'JOHN JAY EDUCATIONAL CAMPUS - K',
        hs_enroll: 215,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 849,
        dataVersion: 'november-2018',
        hs_capacity: 257,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 257,
        the_geom_webmercator: '0101000020110F000062B70622526A5FC12765645E65EF5241'
      }, {
        x: 989996,
        y: 183241,
        name: 'PARK SLOPE COLLEGIATE - K',
        level: 'hs',
        enroll: 428,
        org_id: 'K464',
        source: 'bluebook',
        address: '237 7TH AVENUE',
        bldg_id: 'K460',
        borocode: 3,
        capacity: 427,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000DDAB62A2AC7E52C0EEC5A067B6554440',
        bldg_name: 'JOHN JAY EDUCATIONAL CAMPUS - K',
        hs_enroll: 428,
        ms_enroll: 226,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 850,
        dataVersion: 'november-2018',
        hs_capacity: 427,
        ms_capacity: 225,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 427,
        the_geom_webmercator: '0101000020110F000062B70622526A5FC12765645E65EF5241'
      }, {
        x: 986094,
        y: 188993,
        name: 'THE BOERUM HILL SCHOOL FOR INTERNATIONAL STUDIES - K',
        level: 'hs',
        enroll: 256,
        org_id: 'K497',
        source: 'bluebook',
        address: '284 BALTIC STREET',
        bldg_id: 'K293',
        borocode: 3,
        capacity: 299,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000BCDBBA10937F52C0BEF67FCCBB574440',
        bldg_name: 'J.H.S. 293 - K',
        hs_enroll: 256,
        ms_enroll: 376,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 851,
        dataVersion: 'november-2018',
        hs_capacity: 299,
        ms_capacity: 440,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 299,
        the_geom_webmercator: '0101000020110F0000AAFC0F8BD96B5FC10FF2BDC8A8F15241'
      }, {
        x: 986748,
        y: 188667,
        name: 'COBBLE HILL SCHL OF AMER. STUD-K',
        level: 'hs',
        enroll: 584,
        org_id: 'K519',
        source: 'bluebook',
        address: '347 BALTIC STREET',
        bldg_id: 'K804',
        borocode: 3,
        capacity: 844,
        district: 15,
        excluded: false,
        the_geom: '0101000020E61000004AE7A16E6C7F52C0D4BF10799E574440',
        bldg_name: 'COBBLE HILL SCL OF AMR STD (OL6)-K',
        hs_enroll: 584,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 853,
        dataVersion: 'november-2018',
        hs_capacity: 844,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 844,
        the_geom_webmercator: '0101000020110F00004E95BCEB976B5FC1049E6EF087F15241'
      }, {
        x: 986207,
        y: 173478,
        name: 'WEST BROOKLYN COMMUNITY HS - K',
        level: 'hs',
        enroll: 226,
        org_id: 'K529',
        source: 'bluebook',
        address: '1053 41ST STREET',
        bldg_id: 'K781',
        borocode: 3,
        capacity: 279,
        district: 15,
        excluded: false,
        the_geom: '0101000020E61000008712B1768C7F52C023A8715D48524440',
        bldg_name: 'WEST BROOKLYN COMMUNITY HS - K',
        hs_enroll: 226,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 854,
        dataVersion: 'november-2018',
        hs_capacity: 279,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 279,
        the_geom_webmercator: '0101000020110F0000E0C95C54CE6B5FC1A14BCD638EEB5241'
      }, {
        x: 992093,
        y: 175873,
        name: 'BROOKLYN COLLEGE ACADEMY - K',
        level: 'hs',
        enroll: 296,
        org_id: 'K555',
        source: 'bluebook',
        address: '350 CONEY ISLAND AVENUE',
        bldg_id: 'K917',
        borocode: 3,
        capacity: 492,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000132C63EC307E52C0185ECDAA1F534440',
        bldg_name: 'BKLYN COLL ACAD (BRIDGES TO K)-K',
        hs_enroll: 296,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 855,
        dataVersion: 'november-2018',
        hs_capacity: 492,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 492,
        the_geom_webmercator: '0101000020110F0000BDFF58FF7F695FC137612B637FEC5241'
      }, {
        x: 989894,
        y: 189387,
        name: 'KHALIL GIBRAN INTERNATIONAL ACADEMY',
        level: 'hs',
        enroll: 270,
        org_id: 'K592',
        source: 'bluebook',
        address: '362 SCHERMERHORN STREET',
        bldg_id: 'K806',
        borocode: 3,
        capacity: 389,
        district: 15,
        excluded: false,
        the_geom: '0101000020E61000004BC40093B27E52C08874CA2EDF574440',
        bldg_name: 'METROPOLITAN CORPORATE ACAD. - K',
        hs_enroll: 270,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 856,
        dataVersion: 'november-2018',
        hs_capacity: 389,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 389,
        the_geom_webmercator: '0101000020110F0000E2BBF2385C6A5FC1C475286AD0F15241'
      }, {
        x: 989722,
        y: 188316,
        name: 'BROOKLYN HS OF THE ARTS - K',
        level: 'hs',
        enroll: 867,
        org_id: 'K656',
        source: 'bluebook',
        address: '345 DEAN STREET',
        bldg_id: 'K655',
        borocode: 3,
        capacity: 1173,
        district: 15,
        excluded: false,
        the_geom: '0101000020E61000002654E4BFBC7E52C044A61FDC7E574440',
        bldg_name: 'BKLYN HS OF THE ARTS (OL S J HL)-K',
        hs_enroll: 867,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 857,
        dataVersion: 'november-2018',
        hs_capacity: 1173,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 1173,
        the_geom_webmercator: '0101000020110F000057379D816D6A5FC1CE527B8864F15241'
      }, {
        x: 989609,
        y: 188660,
        name: 'BROOKLYN HS OF THE ARTS - K',
        level: 'hs',
        enroll: 0,
        org_id: 'K656',
        source: 'bluebook',
        address: '500 PACIFIC STREET',
        bldg_id: 'K656',
        borocode: 3,
        capacity: 0,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000F7AD9F6BC37E52C09D613DCD9D574440',
        bldg_name: 'BKLYN HS OF THE ARTS AX (S J HL)-K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 858,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 0,
        the_geom_webmercator: '0101000020110F000019565ED6786A5FC18033FD2F87F15241'
      }, {
        x: 989996,
        y: 183241,
        name: 'MILLENIUM BROOKLYN HIGH SCHOOL - K',
        level: 'hs',
        enroll: 672,
        org_id: 'K684',
        source: 'bluebook',
        address: '237 7TH AVENUE',
        bldg_id: 'K460',
        borocode: 3,
        capacity: 779,
        district: 15,
        excluded: false,
        the_geom: '0101000020E6100000DDAB62A2AC7E52C0EEC5A067B6554440',
        bldg_name: 'JOHN JAY EDUCATIONAL CAMPUS - K',
        hs_enroll: 672,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 861,
        dataVersion: 'november-2018',
        hs_capacity: 779,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 779,
        the_geom_webmercator: '0101000020110F000062B70622526A5FC12765645E65EF5241'
      }, {
        x: 980266,
        y: 186279,
        name: 'SOUTH BROOKLYN COMM. HS - K',
        level: 'hs',
        enroll: 201,
        org_id: 'K698',
        source: 'bluebook',
        address: '173 CONOVER STREET',
        bldg_id: 'K825',
        borocode: 3,
        capacity: 235,
        district: 15,
        excluded: false,
        the_geom: '0101000020E61000008E8B4A54EB8052C0CF8139ADC7564440',
        bldg_name: 'SOUTH BROOKLYN COMM. HS - K',
        hs_enroll: 201,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 862,
        dataVersion: 'november-2018',
        hs_capacity: 235,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 235,
        the_geom_webmercator: '0101000020110F0000CA48634F226E5FC1DD459D6197F05241'
      }, {
        x: 1002998,
        y: 186822,
        name: 'BOYS & GIRLS HS - K',
        level: 'hs',
        enroll: 428,
        org_id: 'K455',
        source: 'bluebook',
        address: '1700 FULTON STREET',
        bldg_id: 'K455',
        borocode: 3,
        capacity: 2501,
        district: 16,
        excluded: false,
        the_geom: '0101000020E610000052A4BA8EAC7B52C060C5AEE4F7564440',
        bldg_name: 'BOYS & GIRLS HS - K',
        hs_enroll: 428,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 883,
        dataVersion: 'november-2018',
        hs_capacity: 2501,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 2501,
        the_geom_webmercator: '0101000020110F0000F2602A7A39655FC1C0D1F560CDF05241'
      }, {
        x: 1006185,
        y: 190246,
        name: 'BROOKLYN HIGH SCHOOL FOR LAW AND TECHNOLOGY - K',
        level: 'hs',
        enroll: 594,
        org_id: 'K498',
        source: 'bluebook',
        address: '1396 BROADWAY',
        bldg_id: 'K987',
        borocode: 3,
        capacity: 607,
        district: 16,
        excluded: false,
        the_geom: '0101000020E61000003981861EF07A52C0D3562E9C2B584440',
        bldg_name: 'BROOKLYN HIGH SCHOOL FOR LAW AND TECHNOLOGY - K',
        hs_enroll: 594,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 884,
        dataVersion: 'november-2018',
        hs_capacity: 607,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 607,
        the_geom_webmercator: '0101000020110F0000B0455365F9635FC1D2B93C0426F25241'
      }, {
        x: 1005811,
        y: 187197,
        name: 'GOTHAM PROFESSIONAL ARTS ACAD - K',
        level: 'hs',
        enroll: 168,
        org_id: 'K594',
        source: 'bluebook',
        address: '265 RALPH AVENUE',
        bldg_id: 'K040',
        borocode: 3,
        capacity: 298,
        district: 16,
        excluded: false,
        the_geom: '0101000020E6100000C257D45F067B52C0BA18436919574440',
        bldg_name: 'P.S. 40 - K',
        hs_enroll: 168,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 885,
        dataVersion: 'november-2018',
        hs_capacity: 298,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 298,
        the_geom_webmercator: '0101000020110F0000F6FFC4321F645FC1296477EAF2F05241'
      }, {
        x: 1002998,
        y: 186822,
        name: 'RESEARCH AND SERVICE HS',
        level: 'hs',
        enroll: 274,
        org_id: 'K669',
        source: 'bluebook',
        address: '1700 FULTON STREET',
        bldg_id: 'K455',
        borocode: 3,
        capacity: 286,
        district: 16,
        excluded: false,
        the_geom: '0101000020E610000052A4BA8EAC7B52C060C5AEE4F7564440',
        bldg_name: 'BOYS & GIRLS HS - K',
        hs_enroll: 274,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 888,
        dataVersion: 'november-2018',
        hs_capacity: 286,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 286,
        the_geom_webmercator: '0101000020110F0000F2602A7A39655FC1C0D1F560CDF05241'
      }, {
        x: 1002637,
        y: 191133,
        name: 'BROOKLYN ACADEMY OF GLOBAL FINANCE - K',
        level: 'hs',
        enroll: 135,
        org_id: 'K688',
        source: 'bluebook',
        address: '125 STUYVESANT AVENUE',
        bldg_id: 'K057',
        borocode: 3,
        capacity: 296,
        district: 16,
        excluded: false,
        the_geom: '0101000020E6100000DA3063B0C17B52C00FAFF7A67B584440',
        bldg_name: 'J.H.S. 57 - K',
        hs_enroll: 135,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 890,
        dataVersion: 'november-2018',
        hs_capacity: 296,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 296,
        the_geom_webmercator: '0101000020110F0000C4C2035F5D655FC1B1549FAB7FF25241'
      }, {
        x: 1002998,
        y: 186822,
        name: 'NELSON MANDELA SCHOOL FOR SOCIAL JUSTICE',
        level: 'hs',
        enroll: 273,
        org_id: 'K765',
        source: 'bluebook',
        address: '1700 FULTON STREET',
        bldg_id: 'K455',
        borocode: 3,
        capacity: 435,
        district: 16,
        excluded: false,
        the_geom: '0101000020E610000052A4BA8EAC7B52C060C5AEE4F7564440',
        bldg_name: 'BOYS & GIRLS HS - K',
        hs_enroll: 273,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 891,
        dataVersion: 'november-2018',
        hs_capacity: 435,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 435,
        the_geom_webmercator: '0101000020110F0000F2602A7A39655FC1C0D1F560CDF05241'
      }, {
        x: 1002637,
        y: 191133,
        name: 'BROOKLYN EMERGING LEADERS ACADEMY',
        level: 'hs',
        enroll: 64,
        org_id: 'K892',
        source: 'bluebook',
        address: '125 STUYVESANT AVENUE',
        bldg_id: 'K057',
        borocode: 3,
        capacity: 210,
        district: 16,
        excluded: false,
        the_geom: '0101000020E6100000DA3063B0C17B52C00FAFF7A67B584440',
        bldg_name: 'J.H.S. 57 - K',
        hs_enroll: 64,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 892,
        dataVersion: 'november-2018',
        hs_capacity: 210,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 210,
        the_geom_webmercator: '0101000020110F0000C4C2035F5D655FC1B1549FAB7FF25241'
      }, {
        x: 1001214,
        y: 185426,
        name: 'PATHWAYS IN TECHNOLOGY EARLY COLLEGE HS - K',
        level: 'hs',
        enroll: 592,
        org_id: 'K122',
        source: 'bluebook',
        address: '150 ALBANY AVENUE',
        bldg_id: 'K625',
        borocode: 3,
        capacity: 750,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005CD8EAFE157C52C064FF35747A564440',
        bldg_name: 'ROBESON HS COMP&BUS TC (A HML)-K',
        hs_enroll: 592,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 898,
        dataVersion: 'november-2018',
        hs_capacity: 750,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 750,
        the_geom_webmercator: '0101000020110F00003C111D93EC655FC14B8514E740F05241'
      }, {
        x: 995752,
        y: 176014,
        name: 'I.S. 382 - K',
        level: 'hs',
        enroll: 287,
        org_id: 'K382',
        source: 'bluebook',
        address: '911 FLATBUSH AVENUE',
        bldg_id: 'K465',
        borocode: 3,
        capacity: 480,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000EE154AE1587D52C0FA2ACA372C534440',
        bldg_name: 'ERASMUS HALL CAMPUS - K',
        hs_enroll: 287,
        ms_enroll: 135,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 917,
        dataVersion: 'november-2018',
        hs_capacity: 480,
        ms_capacity: 226,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 480,
        the_geom_webmercator: '0101000020110F0000C61DAD0611685FC1D2C9CC6F8DEC5241'
      }, {
        x: 995752,
        y: 176014,
        name: 'ACAD. OF HOSP. & TOURISM - K',
        level: 'hs',
        enroll: 208,
        org_id: 'K408',
        source: 'bluebook',
        address: '911 FLATBUSH AVENUE',
        bldg_id: 'K465',
        borocode: 3,
        capacity: 381,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000EE154AE1587D52C0FA2ACA372C534440',
        bldg_name: 'ERASMUS HALL CAMPUS - K',
        hs_enroll: 208,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 922,
        dataVersion: 'november-2018',
        hs_capacity: 381,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 381,
        the_geom_webmercator: '0101000020110F0000C61DAD0611685FC1D2C9CC6F8DEC5241'
      }, {
        x: 996741,
        y: 183415,
        name: 'W.E.B. DUBOIS HS - K',
        level: 'hs',
        enroll: 132,
        org_id: 'K489',
        source: 'bluebook',
        address: '402 EASTERN PARKWAY',
        bldg_id: 'K824',
        borocode: 3,
        capacity: 253,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000AE07E2421E7D52C094F3ACD3C5554440',
        bldg_name: 'W.E.B. DUBOIS HS - K',
        hs_enroll: 132,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 923,
        dataVersion: 'november-2018',
        hs_capacity: 253,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 253,
        the_geom_webmercator: '0101000020110F00002FE7C874AD675FC11ADD31A376EF5241'
      }, {
        x: 994761,
        y: 186470,
        name: 'ACORN COMMUNITY HS - K',
        level: 'hs',
        enroll: 193,
        org_id: 'K499',
        source: 'bluebook',
        address: '561 GRAND AVENUE',
        bldg_id: 'K909',
        borocode: 3,
        capacity: 707,
        district: 17,
        excluded: false,
        the_geom: '0101000020E610000028510D20937D52C0B7B723AED8564440',
        bldg_name: 'ACORN COMMUNITY HS - K',
        hs_enroll: 193,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 924,
        dataVersion: 'november-2018',
        hs_capacity: 707,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 707,
        the_geom_webmercator: '0101000020110F00003E641BF673685FC1E74C606CAAF05241'
      }, {
        x: 994876,
        y: 183505,
        name: 'THE INTERNATIONAL HS - K',
        level: 'hs',
        enroll: 417,
        org_id: 'K524',
        source: 'bluebook',
        address: '883 CLASSON AVENUE',
        bldg_id: 'K440',
        borocode: 3,
        capacity: 523,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000003C5CAA688C7D52C068735600CE554440',
        bldg_name: 'PROSPECT HEIGHTS HS - K',
        hs_enroll: 417,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 925,
        dataVersion: 'november-2018',
        hs_capacity: 523,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 523,
        the_geom_webmercator: '0101000020110F00000F518E8D68685FC1A40A74CA7FEF5241'
      }, {
        x: 994876,
        y: 183505,
        name: 'HS FOR GLOBAL CITIZENSHIP - K',
        level: 'hs',
        enroll: 197,
        org_id: 'K528',
        source: 'bluebook',
        address: '883 CLASSON AVENUE',
        bldg_id: 'K440',
        borocode: 3,
        capacity: 463,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000003C5CAA688C7D52C068735600CE554440',
        bldg_name: 'PROSPECT HEIGHTS HS - K',
        hs_enroll: 197,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 926,
        dataVersion: 'november-2018',
        hs_capacity: 463,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 463,
        the_geom_webmercator: '0101000020110F00000F518E8D68685FC1A40A74CA7FEF5241'
      }, {
        x: 1000215,
        y: 179334,
        name: 'I.S. 531 - K',
        level: 'hs',
        enroll: 390,
        org_id: 'K531',
        source: 'bluebook',
        address: '600 KINGSTON AVENUE',
        bldg_id: 'K470',
        borocode: 3,
        capacity: 1077,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005812543D517C52C0ACC35E9856544440',
        bldg_name: 'GEORGE W. WINGATE HS - K',
        hs_enroll: 390,
        ms_enroll: 51,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 927,
        dataVersion: 'november-2018',
        hs_capacity: 1077,
        ms_capacity: 141,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 1077,
        the_geom_webmercator: '0101000020110F00007BF3C93451665FC1525A1277DBED5241'
      }, {
        x: 995752,
        y: 176014,
        name: 'HS FOR YOUTH & COMMUNITY DEV. - K',
        level: 'hs',
        enroll: 480,
        org_id: 'K537',
        source: 'bluebook',
        address: '911 FLATBUSH AVENUE',
        bldg_id: 'K465',
        borocode: 3,
        capacity: 501,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000EE154AE1587D52C0FA2ACA372C534440',
        bldg_name: 'ERASMUS HALL CAMPUS - K',
        hs_enroll: 480,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 929,
        dataVersion: 'november-2018',
        hs_capacity: 501,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 501,
        the_geom_webmercator: '0101000020110F0000C61DAD0611685FC1D2C9CC6F8DEC5241'
      }, {
        x: 995752,
        y: 176014,
        name: 'HS FOR SERVICE AND LEARNING - K',
        level: 'hs',
        enroll: 354,
        org_id: 'K539',
        source: 'bluebook',
        address: '911 FLATBUSH AVENUE',
        bldg_id: 'K465',
        borocode: 3,
        capacity: 479,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000EE154AE1587D52C0FA2ACA372C534440',
        bldg_name: 'ERASMUS HALL CAMPUS - K',
        hs_enroll: 354,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 930,
        dataVersion: 'november-2018',
        hs_capacity: 479,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 479,
        the_geom_webmercator: '0101000020110F0000C61DAD0611685FC1D2C9CC6F8DEC5241'
      }, {
        x: 995752,
        y: 176014,
        name: 'SCIENCE TECH & RESEARCH - K',
        level: 'hs',
        enroll: 340,
        org_id: 'K543',
        source: 'bluebook',
        address: '911 FLATBUSH AVENUE',
        bldg_id: 'K465',
        borocode: 3,
        capacity: 438,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000EE154AE1587D52C0FA2ACA372C534440',
        bldg_name: 'ERASMUS HALL CAMPUS - K',
        hs_enroll: 340,
        ms_enroll: 201,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 931,
        dataVersion: 'november-2018',
        hs_capacity: 438,
        ms_capacity: 259,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 438,
        the_geom_webmercator: '0101000020110F0000C61DAD0611685FC1D2C9CC6F8DEC5241'
      }, {
        x: 1000215,
        y: 179334,
        name: 'HS FOR PUBLIC SERVICE - K',
        level: 'hs',
        enroll: 454,
        org_id: 'K546',
        source: 'bluebook',
        address: '600 KINGSTON AVENUE',
        bldg_id: 'K470',
        borocode: 3,
        capacity: 599,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005812543D517C52C0ACC35E9856544440',
        bldg_name: 'GEORGE W. WINGATE HS - K',
        hs_enroll: 454,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 932,
        dataVersion: 'november-2018',
        hs_capacity: 599,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 599,
        the_geom_webmercator: '0101000020110F00007BF3C93451665FC1525A1277DBED5241'
      }, {
        x: 994876,
        y: 183505,
        name: 'BROOKLYN HS FOR SCI & THE ENVI-K',
        level: 'hs',
        enroll: 373,
        org_id: 'K547',
        source: 'bluebook',
        address: '883 CLASSON AVENUE',
        bldg_id: 'K440',
        borocode: 3,
        capacity: 455,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000003C5CAA688C7D52C068735600CE554440',
        bldg_name: 'PROSPECT HEIGHTS HS - K',
        hs_enroll: 373,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 933,
        dataVersion: 'november-2018',
        hs_capacity: 455,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 455,
        the_geom_webmercator: '0101000020110F00000F518E8D68685FC1A40A74CA7FEF5241'
      }, {
        x: 994876,
        y: 183505,
        name: 'BROOKLYN HS FOR MUSIC &THEATER - K',
        level: 'hs',
        enroll: 296,
        org_id: 'K548',
        source: 'bluebook',
        address: '883 CLASSON AVENUE',
        bldg_id: 'K440',
        borocode: 3,
        capacity: 551,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000003C5CAA688C7D52C068735600CE554440',
        bldg_name: 'PROSPECT HEIGHTS HS - K',
        hs_enroll: 296,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 934,
        dataVersion: 'november-2018',
        hs_capacity: 551,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 551,
        the_geom_webmercator: '0101000020110F00000F518E8D68685FC1A40A74CA7FEF5241'
      }, {
        x: 1005318,
        y: 182116,
        name: 'BROWNSVILLE ACADEMY HIGH SCHOOL - K',
        level: 'hs',
        enroll: 117,
        org_id: 'K568',
        source: 'bluebook',
        address: '1150 EAST NEW YORK AVE',
        bldg_id: 'K907',
        borocode: 3,
        capacity: 424,
        district: 17,
        excluded: false,
        the_geom: '0101000020E6100000FD92A5C1237B52C07771077650554440',
        bldg_name: 'BROWNSVILLE DIPLOMA PLUS HS - K',
        hs_enroll: 117,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 935,
        dataVersion: 'november-2018',
        hs_capacity: 424,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 424,
        the_geom_webmercator: '0101000020110F0000C148551B51645FC1F4008C38F3EE5241'
      }, {
        x: 997797,
        y: 182357,
        name: 'MEDGAR EVERS COLLEGE PREPARATORY SCHOOL',
        level: 'hs',
        enroll: 901,
        org_id: 'K590',
        source: 'bluebook',
        address: '1186 CARROLL STREET',
        bldg_id: 'K590',
        borocode: 3,
        capacity: 518,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000008A1A52EDDF7C52C04B5E5A9E66554440',
        bldg_name: 'MIDDLE COLLEGE HS - K',
        hs_enroll: 901,
        ms_enroll: 356,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 936,
        dataVersion: 'november-2018',
        hs_capacity: 518,
        ms_capacity: 205,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 518,
        the_geom_webmercator: '0101000020110F00006973429343675FC148A4CF070CEF5241'
      }, {
        x: 994802,
        y: 183220,
        name: 'CLARA BARTON HS - K',
        level: 'hs',
        enroll: 1221,
        org_id: 'K600',
        source: 'bluebook',
        address: '901 CLASSON AVENUE',
        bldg_id: 'K600',
        borocode: 3,
        capacity: 1579,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000002C7267C9907D52C0FF4EFF5EB4554440',
        bldg_name: 'CLARA BARTON HS - K',
        hs_enroll: 1221,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 937,
        dataVersion: 'november-2018',
        hs_capacity: 1579,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 1579,
        the_geom_webmercator: '0101000020110F00001A453EFD6F685FC1B0DE6B1763EF5241'
      }, {
        x: 1000215,
        y: 179334,
        name: 'H.S. 745 - K',
        level: 'hs',
        enroll: 381,
        org_id: 'K745',
        source: 'bluebook',
        address: '600 KINGSTON AVENUE',
        bldg_id: 'K470',
        borocode: 3,
        capacity: 554,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005812543D517C52C0ACC35E9856544440',
        bldg_name: 'GEORGE W. WINGATE HS - K',
        hs_enroll: 381,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 940,
        dataVersion: 'november-2018',
        hs_capacity: 554,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 554,
        the_geom_webmercator: '0101000020110F00007BF3C93451665FC1525A1277DBED5241'
      }, {
        x: 1001214,
        y: 185426,
        name: 'ACADEMY OF HEALTH CAREERS - K',
        level: 'hs',
        enroll: 359,
        org_id: 'K751',
        source: 'bluebook',
        address: '150 ALBANY AVENUE',
        bldg_id: 'K625',
        borocode: 3,
        capacity: 497,
        district: 17,
        excluded: false,
        the_geom: '0101000020E61000005CD8EAFE157C52C064FF35747A564440',
        bldg_name: 'ROBESON HS COMP&BUS TC (A HML)-K',
        hs_enroll: 359,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 941,
        dataVersion: 'november-2018',
        hs_capacity: 497,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 497,
        the_geom_webmercator: '0101000020110F00003C111D93EC655FC14B8514E740F05241'
      }, {
        x: 1005847,
        y: 175609,
        name: 'IT TAKES A VILLAGE ACADEMY - K',
        level: 'hs',
        enroll: 730,
        org_id: 'K563',
        source: 'bluebook',
        address: '5800 TILDEN AVENUE',
        bldg_id: 'K415',
        borocode: 3,
        capacity: 937,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000C46720DB047B52C0CAB4732C07534440',
        bldg_name: 'SAMUEL J. TILDEN EDUCATIONAL CAMPUS - K',
        hs_enroll: 730,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 964,
        dataVersion: 'november-2018',
        hs_capacity: 937,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 937,
        the_geom_webmercator: '0101000020110F000035C1849E1C645FC168C32CF863EC5241'
      }, {
        x: 1007361,
        y: 170010,
        name: 'BROOKLYN GENERATION SCHOOL - K',
        level: 'hs',
        enroll: 195,
        org_id: 'K566',
        source: 'bluebook',
        address: '6565 FLATLANDS AVENUE',
        bldg_id: 'K515',
        borocode: 3,
        capacity: 382,
        district: 18,
        excluded: false,
        the_geom: '0101000020E61000003E2399C7AB7A52C0DF43DD770F514440',
        bldg_name: 'SOUTH SHORE EDUCATIONAL COMPLEX - K',
        hs_enroll: 195,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 965,
        dataVersion: 'november-2018',
        hs_capacity: 382,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 382,
        the_geom_webmercator: '0101000020110F00009E506E5085635FC142A9483130EA5241'
      }, {
        x: 1007361,
        y: 170010,
        name: 'BROOKLYN THEATRE ARTS H.S. - K',
        level: 'hs',
        enroll: 362,
        org_id: 'K567',
        source: 'bluebook',
        address: '6565 FLATLANDS AVENUE',
        bldg_id: 'K515',
        borocode: 3,
        capacity: 563,
        district: 18,
        excluded: false,
        the_geom: '0101000020E61000003E2399C7AB7A52C0DF43DD770F514440',
        bldg_name: 'SOUTH SHORE EDUCATIONAL COMPLEX - K',
        hs_enroll: 362,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 966,
        dataVersion: 'november-2018',
        hs_capacity: 563,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 563,
        the_geom_webmercator: '0101000020110F00009E506E5085635FC142A9483130EA5241'
      }, {
        x: 1005847,
        y: 175609,
        name: 'KURT HAHN EXPED LEARNING SCH - K',
        level: 'hs',
        enroll: 353,
        org_id: 'K569',
        source: 'bluebook',
        address: '5800 TILDEN AVENUE',
        bldg_id: 'K415',
        borocode: 3,
        capacity: 703,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000C46720DB047B52C0CAB4732C07534440',
        bldg_name: 'SAMUEL J. TILDEN EDUCATIONAL CAMPUS - K',
        hs_enroll: 353,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 967,
        dataVersion: 'november-2018',
        hs_capacity: 703,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 703,
        the_geom_webmercator: '0101000020110F000035C1849E1C645FC168C32CF863EC5241'
      }, {
        x: 1007361,
        y: 170010,
        name: 'VICTORY COLLEGIATE HS - K',
        level: 'hs',
        enroll: 401,
        org_id: 'K576',
        source: 'bluebook',
        address: '6565 FLATLANDS AVENUE',
        bldg_id: 'K515',
        borocode: 3,
        capacity: 660,
        district: 18,
        excluded: false,
        the_geom: '0101000020E61000003E2399C7AB7A52C0DF43DD770F514440',
        bldg_name: 'SOUTH SHORE EDUCATIONAL COMPLEX - K',
        hs_enroll: 401,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 968,
        dataVersion: 'november-2018',
        hs_capacity: 660,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 660,
        the_geom_webmercator: '0101000020110F00009E506E5085635FC142A9483130EA5241'
      }, {
        x: 1007361,
        y: 170010,
        name: 'BROOKLYN BRIDGE ACADEMY - K',
        level: 'hs',
        enroll: 191,
        org_id: 'K578',
        source: 'bluebook',
        address: '6565 FLATLANDS AVENUE',
        bldg_id: 'K515',
        borocode: 3,
        capacity: 340,
        district: 18,
        excluded: false,
        the_geom: '0101000020E61000003E2399C7AB7A52C0DF43DD770F514440',
        bldg_name: 'SOUTH SHORE EDUCATIONAL COMPLEX - K',
        hs_enroll: 191,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 969,
        dataVersion: 'november-2018',
        hs_capacity: 340,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 340,
        the_geom_webmercator: '0101000020110F00009E506E5085635FC142A9483130EA5241'
      }, {
        x: 1012487,
        y: 172999,
        name: 'HS FOR INNOVATION IN AD - BKLYN',
        level: 'hs',
        enroll: 255,
        org_id: 'K617',
        source: 'bluebook',
        address: '1600 ROCKAWAY PKWY',
        bldg_id: 'K500',
        borocode: 3,
        capacity: 565,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000A9D6F9FD7C7952C0B48DBBD01B524440',
        bldg_name: 'CANARSIE HS - K',
        hs_enroll: 255,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 973,
        dataVersion: 'november-2018',
        hs_capacity: 565,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 565,
        the_geom_webmercator: '0101000020110F0000D550BEFF82615FC1BD73BF865CEB5241'
      }, {
        x: 1005847,
        y: 175609,
        name: 'CULT ACAD ARTS AND SCI - K',
        level: 'hs',
        enroll: 319,
        org_id: 'K629',
        source: 'bluebook',
        address: '5800 TILDEN AVENUE',
        bldg_id: 'K415',
        borocode: 3,
        capacity: 637,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000C46720DB047B52C0CAB4732C07534440',
        bldg_name: 'SAMUEL J. TILDEN EDUCATIONAL CAMPUS - K',
        hs_enroll: 319,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 974,
        dataVersion: 'november-2018',
        hs_capacity: 637,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 637,
        the_geom_webmercator: '0101000020110F000035C1849E1C645FC168C32CF863EC5241'
      }, {
        x: 1012487,
        y: 172999,
        name: 'HS FOR MEDICAL PROFESSIONS - K',
        level: 'hs',
        enroll: 424,
        org_id: 'K633',
        source: 'bluebook',
        address: '1600 ROCKAWAY PKWY',
        bldg_id: 'K500',
        borocode: 3,
        capacity: 577,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000A9D6F9FD7C7952C0B48DBBD01B524440',
        bldg_name: 'CANARSIE HS - K',
        hs_enroll: 424,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 975,
        dataVersion: 'november-2018',
        hs_capacity: 577,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 577,
        the_geom_webmercator: '0101000020110F0000D550BEFF82615FC1BD73BF865CEB5241'
      }, {
        x: 1012489,
        y: 174372,
        name: 'OLYMPUS ACADEMY - K',
        level: 'hs',
        enroll: 206,
        org_id: 'K635',
        source: 'bluebook',
        address: '10001 FLATLANDS AVENUE',
        bldg_id: 'K242',
        borocode: 3,
        capacity: 293,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000E5E3A4C77C7952C09B30E14D97524440',
        bldg_name: 'P.S. 235 ANNEX - K',
        hs_enroll: 206,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 976,
        dataVersion: 'november-2018',
        hs_capacity: 293,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 293,
        the_geom_webmercator: '0101000020110F0000439174A382615FC11B8751BFE6EB5241'
      }, {
        x: 1007361,
        y: 170010,
        name: 'ACAD FOR CONSERV & THE ENVIR - K',
        level: 'hs',
        enroll: 285,
        org_id: 'K637',
        source: 'bluebook',
        address: '6565 FLATLANDS AVENUE',
        bldg_id: 'K515',
        borocode: 3,
        capacity: 432,
        district: 18,
        excluded: false,
        the_geom: '0101000020E61000003E2399C7AB7A52C0DF43DD770F514440',
        bldg_name: 'SOUTH SHORE EDUCATIONAL COMPLEX - K',
        hs_enroll: 285,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 977,
        dataVersion: 'november-2018',
        hs_capacity: 432,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 432,
        the_geom_webmercator: '0101000020110F00009E506E5085635FC142A9483130EA5241'
      }, {
        x: 1012487,
        y: 172999,
        name: 'URBAN ACTION ACADEMY - K',
        level: 'hs',
        enroll: 278,
        org_id: 'K642',
        source: 'bluebook',
        address: '1600 ROCKAWAY PKWY',
        bldg_id: 'K500',
        borocode: 3,
        capacity: 539,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000A9D6F9FD7C7952C0B48DBBD01B524440',
        bldg_name: 'CANARSIE HS - K',
        hs_enroll: 278,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 978,
        dataVersion: 'november-2018',
        hs_capacity: 539,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 539,
        the_geom_webmercator: '0101000020110F0000D550BEFF82615FC1BD73BF865CEB5241'
      }, {
        x: 1006344,
        y: 179418,
        name: 'EAST BKLN COMMUNITY HS - K',
        level: 'hs',
        enroll: 192,
        org_id: 'K673',
        source: 'bluebook',
        address: '9517 KINGS HIGHWAY',
        bldg_id: 'K860',
        borocode: 3,
        capacity: 255,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000F2D1AD4EE77A52C05158F7B75D544440',
        bldg_name: 'P.S. 219 ANNEX - K',
        hs_enroll: 192,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 979,
        dataVersion: 'november-2018',
        hs_capacity: 255,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 255,
        the_geom_webmercator: '0101000020110F0000670A8B6DEA635FC17D29B370E3ED5241'
      }, {
        x: 1012487,
        y: 172999,
        name: 'SCHOOL FOR COOPERATIVE TECHNICAL EDUCATION',
        level: 'hs',
        enroll: 72,
        org_id: 'M645',
        source: 'bluebook',
        address: '1600 ROCKAWAY PKWY',
        bldg_id: 'K500',
        borocode: 3,
        capacity: 144,
        district: 18,
        excluded: false,
        the_geom: '0101000020E6100000A9D6F9FD7C7952C0B48DBBD01B524440',
        bldg_name: 'CANARSIE HS - K',
        hs_enroll: 72,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 981,
        dataVersion: 'november-2018',
        hs_capacity: 144,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 144,
        the_geom_webmercator: '0101000020110F0000D550BEFF82615FC1BD73BF865CEB5241'
      }, {
        x: 1019000,
        y: 178831,
        name: 'ACAD. FOR YOUNG WRITERS - K',
        level: 'hs',
        enroll: 326,
        org_id: 'K404',
        source: 'bluebook',
        address: '1065 ELTON STREET',
        bldg_id: 'K422',
        borocode: 3,
        capacity: 342,
        district: 19,
        excluded: false,
        the_geom: '0101000020E61000009EB334FFFB7752C050FA979727544440',
        bldg_name: 'SPRING CREEK EDUCATIONAL CAMPUS - K',
        hs_enroll: 326,
        ms_enroll: 220,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1011,
        dataVersion: 'november-2018',
        hs_capacity: 342,
        ms_capacity: 230,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 342,
        the_geom_webmercator: '0101000020110F0000E12FC10BF55E5FC1B8AACDD7A6ED5241'
      }, {
        x: 1015617,
        y: 180143,
        name: 'EAST NY FAMILY ACAD. - K',
        level: 'hs',
        enroll: 151,
        org_id: 'K409',
        source: 'bluebook',
        address: '2057 LINDEN BLVD',
        bldg_id: 'K819',
        borocode: 3,
        capacity: 167,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000393D12AAC37852C0C7FC29029E544440',
        bldg_name: 'EAST NY FAMILY ACADEMY - K**',
        hs_enroll: 151,
        ms_enroll: 120,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1012,
        dataVersion: 'november-2018',
        hs_capacity: 167,
        ms_capacity: 133,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 167,
        the_geom_webmercator: '0101000020110F00000D427F3348605FC1A8C8F76A2BEE5241'
      }, {
        x: 1015617,
        y: 180143,
        name: 'EAST NY FAMILY ACAD. - K',
        level: 'hs',
        enroll: 125,
        org_id: 'K409',
        source: 'bluebook',
        address: '2057 LINDEN BOULEVARD',
        bldg_id: 'K947',
        borocode: 3,
        capacity: 0,
        district: 19,
        excluded: true,
        the_geom: '0101000020E6100000393D12AAC37852C0C7FC29029E544440',
        bldg_name: 'EAST NY FAMILY ACADEMY TRANSPORTABLE - K',
        hs_enroll: 125,
        ms_enroll: 100,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1013,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 0,
        the_geom_webmercator: '0101000020110F00000D427F3348605FC1A8C8F76A2BEE5241'
      }, {
        x: 1019000,
        y: 178831,
        name: 'SPRING CREEK COMMUNITY SCHOOL',
        level: 'hs',
        enroll: 242,
        org_id: 'K422',
        source: 'bluebook',
        address: '1065 ELTON STREET',
        bldg_id: 'K422',
        borocode: 3,
        capacity: 236,
        district: 19,
        excluded: false,
        the_geom: '0101000020E61000009EB334FFFB7752C050FA979727544440',
        bldg_name: 'SPRING CREEK EDUCATIONAL CAMPUS - K',
        hs_enroll: 242,
        ms_enroll: 236,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1014,
        dataVersion: 'november-2018',
        hs_capacity: 236,
        ms_capacity: 230,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 236,
        the_geom_webmercator: '0101000020110F0000E12FC10BF55E5FC1B8AACDD7A6ED5241'
      }, {
        x: 1013449,
        y: 182466,
        name: 'FDNY - CAPTAIN VERNON A. RICHARDS HIGH SCHOOL FOR FIRE AND LIFE SAFETY-K',
        level: 'hs',
        enroll: 333,
        org_id: 'K502',
        source: 'bluebook',
        address: '400 PENNSYLVANIA AVENUE',
        bldg_id: 'K435',
        borocode: 3,
        capacity: 459,
        district: 19,
        excluded: false,
        the_geom: '0101000020E610000027261687437952C00C27192F6F554440',
        bldg_name: 'THOMAS JEFFERSON HS - K',
        hs_enroll: 333,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1016,
        dataVersion: 'november-2018',
        hs_capacity: 459,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 459,
        the_geom_webmercator: '0101000020110F00005F42D16321615FC1C1EC079F15EF5241'
      }, {
        x: 1013449,
        y: 182466,
        name: 'HIGH SCHOOL FOR CIVIL RIGHTS - K',
        level: 'hs',
        enroll: 264,
        org_id: 'K504',
        source: 'bluebook',
        address: '400 PENNSYLVANIA AVENUE',
        bldg_id: 'K435',
        borocode: 3,
        capacity: 390,
        district: 19,
        excluded: false,
        the_geom: '0101000020E610000027261687437952C00C27192F6F554440',
        bldg_name: 'THOMAS JEFFERSON HS - K',
        hs_enroll: 264,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1017,
        dataVersion: 'november-2018',
        hs_capacity: 390,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 390,
        the_geom_webmercator: '0101000020110F00005F42D16321615FC1C1EC079F15EF5241'
      }, {
        x: 1013449,
        y: 182466,
        name: 'PERFORMING ARTS AND TECH. HS - K',
        level: 'hs',
        enroll: 450,
        org_id: 'K507',
        source: 'bluebook',
        address: '400 PENNSYLVANIA AVENUE',
        bldg_id: 'K435',
        borocode: 3,
        capacity: 548,
        district: 19,
        excluded: false,
        the_geom: '0101000020E610000027261687437952C00C27192F6F554440',
        bldg_name: 'THOMAS JEFFERSON HS - K',
        hs_enroll: 450,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1018,
        dataVersion: 'november-2018',
        hs_capacity: 548,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 548,
        the_geom_webmercator: '0101000020110F00005F42D16321615FC1C1EC079F15EF5241'
      }, {
        x: 1013449,
        y: 182466,
        name: 'WORLD ACAD FOR COMMUNITY HEALTH-K',
        level: 'hs',
        enroll: 262,
        org_id: 'K510',
        source: 'bluebook',
        address: '400 PENNSYLVANIA AVENUE',
        bldg_id: 'K435',
        borocode: 3,
        capacity: 450,
        district: 19,
        excluded: false,
        the_geom: '0101000020E610000027261687437952C00C27192F6F554440',
        bldg_name: 'THOMAS JEFFERSON HS - K',
        hs_enroll: 262,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1019,
        dataVersion: 'november-2018',
        hs_capacity: 450,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 450,
        the_geom_webmercator: '0101000020110F00005F42D16321615FC1C1EC079F15EF5241'
      }, {
        x: 1020765,
        y: 191107,
        name: 'MULTICULTURAL HS - K',
        level: 'hs',
        enroll: 243,
        org_id: 'K583',
        source: 'bluebook',
        address: '999 JAMAICA AVENUE',
        bldg_id: 'K420',
        borocode: 3,
        capacity: 443,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000D031F1AE927752C01DC5A77877584440',
        bldg_name: 'FRANKLIN K. LANE HS - K',
        hs_enroll: 243,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1021,
        dataVersion: 'november-2018',
        hs_capacity: 443,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 443,
        the_geom_webmercator: '0101000020110F000036A50829425E5FC16F4EC3FC7AF25241'
      }, {
        x: 1018655,
        y: 186415,
        name: 'E NY VOC HS OF TRANSIT TECH.-K',
        level: 'hs',
        enroll: 713,
        org_id: 'K615',
        source: 'bluebook',
        address: '1 WELLS STREET',
        bldg_id: 'K615',
        borocode: 3,
        capacity: 1231,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000204131BC0F7852C0ACF441BFD1564440',
        bldg_name: 'E NY VOC HS OF TRANSIT TECH.-K',
        hs_enroll: 713,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1022,
        dataVersion: 'november-2018',
        hs_capacity: 1231,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 1231,
        the_geom_webmercator: '0101000020110F0000DBBFC292165F5FC1877BB5A8A2F05241'
      }, {
        x: 1020765,
        y: 191107,
        name: 'ACADEMY OF INNOV TECH - K',
        level: 'hs',
        enroll: 403,
        org_id: 'K618',
        source: 'bluebook',
        address: '999 JAMAICA AVENUE',
        bldg_id: 'K420',
        borocode: 3,
        capacity: 766,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000D031F1AE927752C01DC5A77877584440',
        bldg_name: 'FRANKLIN K. LANE HS - K',
        hs_enroll: 403,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1023,
        dataVersion: 'november-2018',
        hs_capacity: 766,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 766,
        the_geom_webmercator: '0101000020110F000036A50829425E5FC16F4EC3FC7AF25241'
      }, {
        x: 1020765,
        y: 191107,
        name: 'BROOKLYN LAB SCHOOL - K',
        level: 'hs',
        enroll: 384,
        org_id: 'K639',
        source: 'bluebook',
        address: '999 JAMAICA AVENUE',
        bldg_id: 'K420',
        borocode: 3,
        capacity: 373,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000D031F1AE927752C01DC5A77877584440',
        bldg_name: 'FRANKLIN K. LANE HS - K',
        hs_enroll: 384,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1024,
        dataVersion: 'november-2018',
        hs_capacity: 373,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 373,
        the_geom_webmercator: '0101000020110F000036A50829425E5FC16F4EC3FC7AF25241'
      }, {
        x: 1020765,
        y: 191107,
        name: 'CYPRESS HILLS COLLEGIATE - K',
        level: 'hs',
        enroll: 251,
        org_id: 'K659',
        source: 'bluebook',
        address: '999 JAMAICA AVENUE',
        bldg_id: 'K420',
        borocode: 3,
        capacity: 478,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000D031F1AE927752C01DC5A77877584440',
        bldg_name: 'FRANKLIN K. LANE HS - K',
        hs_enroll: 251,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1026,
        dataVersion: 'november-2018',
        hs_capacity: 478,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 478,
        the_geom_webmercator: '0101000020110F000036A50829425E5FC16F4EC3FC7AF25241'
      }, {
        x: 1012979,
        y: 184899,
        name: 'WILLIAM MAXWELL VOC HS - K',
        level: 'hs',
        enroll: 407,
        org_id: 'K660',
        source: 'bluebook',
        address: '145 PENNSYLVANIA AVENUE',
        bldg_id: 'K660',
        borocode: 3,
        capacity: 1068,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000900F9A1D5F7952C07A206C0F4A564440',
        bldg_name: 'WILLIAM MAXWELL CTE HS - K',
        hs_enroll: 407,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1027,
        dataVersion: 'november-2018',
        hs_capacity: 1068,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 1068,
        the_geom_webmercator: '0101000020110F000018D7354050615FC1BE25C4B50AF05241'
      }, {
        x: 1019145,
        y: 183664,
        name: 'SCHOOL FOR CLASSICS - K',
        level: 'hs',
        enroll: 287,
        org_id: 'K683',
        source: 'bluebook',
        address: '370 FOUNTAIN AVENUE',
        bldg_id: 'K218',
        borocode: 3,
        capacity: 423,
        district: 19,
        excluded: false,
        the_geom: '0101000020E610000066115706F37752C0C1C2E441DA554440',
        bldg_name: 'I.S. 218 - K',
        hs_enroll: 287,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1033,
        dataVersion: 'november-2018',
        hs_capacity: 423,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 423,
        the_geom_webmercator: '0101000020110F000071094CCEE55E5FC16883BF838DEF5241'
      }, {
        x: 1020765,
        y: 191107,
        name: 'THE URBAN ASSEMBLY SCHOOL FOR COLLABORATIVE HEALTHCARE',
        level: 'hs',
        enroll: 313,
        org_id: 'K764',
        source: 'bluebook',
        address: '999 JAMAICA AVENUE',
        bldg_id: 'K420',
        borocode: 3,
        capacity: 360,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000D031F1AE927752C01DC5A77877584440',
        bldg_name: 'FRANKLIN K. LANE HS - K',
        hs_enroll: 313,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1035,
        dataVersion: 'november-2018',
        hs_capacity: 360,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 360,
        the_geom_webmercator: '0101000020110F000036A50829425E5FC16F4EC3FC7AF25241'
      }, {
        x: 1015697,
        y: 184108,
        name: 'PASSAGES ACADEMY',
        level: 'hs',
        enroll: 43,
        org_id: 'X695',
        source: 'bluebook',
        address: '619 BELMONT AVENUE',
        bldg_id: 'K932',
        borocode: 3,
        capacity: 164,
        district: 19,
        excluded: false,
        the_geom: '0101000020E6100000787914A3BE7852C01BB74A9D02564440',
        bldg_name: 'HIGH SCHOOL SUSPENSION CENTER - K',
        hs_enroll: 43,
        ms_enroll: 12,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1036,
        dataVersion: 'november-2018',
        hs_capacity: 164,
        ms_capacity: 44,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 164,
        the_geom_webmercator: '0101000020110F00006CB569A93F605FC1447586B4BAEF5241'
      }, {
        x: 983305,
        y: 162704,
        name: 'NEW UTRECHT HS - K',
        level: 'hs',
        enroll: 3563,
        org_id: 'K445',
        source: 'bluebook',
        address: '1601 80 STREET',
        bldg_id: 'K445',
        borocode: 3,
        capacity: 2253,
        district: 20,
        excluded: false,
        the_geom: '0101000020E61000003E8A05C4378052C0C750E2577F4E4440',
        bldg_name: 'NEW UTRECHT HS - K',
        hs_enroll: 3563,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1074,
        dataVersion: 'november-2018',
        hs_capacity: 2253,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 2253,
        the_geom_webmercator: '0101000020110F0000B001A84DF16C5FC11B5F660352E75241'
      }, {
        x: 977631,
        y: 171456,
        name: 'TELECOM. ARTS & TECH. HS - K',
        level: 'hs',
        enroll: 1278,
        org_id: 'K485',
        source: 'bluebook',
        address: '350 67 STREET',
        bldg_id: 'K485',
        borocode: 3,
        capacity: 1220,
        district: 20,
        excluded: false,
        the_geom: '0101000020E6100000A5A692BC868152C0ABBB186E92514440',
        bldg_name: 'TELECOM. ARTS & TECH. - K',
        hs_enroll: 1278,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1075,
        dataVersion: 'november-2018',
        hs_capacity: 1220,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 1220,
        the_geom_webmercator: '0101000020110F00002061FE482A6F5FC11A50A1C2C2EA5241'
      }, {
        x: 973134,
        y: 168016,
        name: 'FORT HAMILTON HS - K',
        level: 'hs',
        enroll: 4582,
        org_id: 'K490',
        source: 'bluebook',
        address: '8301 SHORE ROAD',
        bldg_id: 'K490',
        borocode: 3,
        capacity: 2391,
        district: 20,
        excluded: false,
        the_geom: '0101000020E61000009803FD1C908252C0F9E680E25C504440',
        bldg_name: 'FORT HAMILTON HS - K',
        hs_enroll: 4582,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1076,
        dataVersion: 'november-2018',
        hs_capacity: 2391,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 2391,
        the_geom_webmercator: '0101000020110F0000C5EAD40DED705FC144D2AF5768E95241'
      }, {
        x: 973056,
        y: 167821,
        name: 'FORT HAMILTON HS - K',
        level: 'hs',
        enroll: 0,
        org_id: 'K490',
        source: 'bluebook',
        address: '8339 SHORE ROAD',
        bldg_id: 'K934',
        borocode: 3,
        capacity: 0,
        district: 20,
        excluded: false,
        the_geom: '0101000020E610000073333AB6948252C00FE1CF574B504440',
        bldg_name: 'FORT HAMILTON HS MODULAR UNIT - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1077,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 0,
        the_geom_webmercator: '0101000020110F0000119A7DDDF4705FC1DEB372B654E95241'
      }, {
        x: 989304,
        y: 165322,
        name: 'FRANKLIN D. ROOSEVELT HS - K',
        level: 'hs',
        enroll: 3061,
        org_id: 'K505',
        source: 'bluebook',
        address: '5800 20 AVENUE',
        bldg_id: 'K505',
        borocode: 3,
        capacity: 2552,
        district: 20,
        excluded: false,
        the_geom: '0101000020E610000023B380B9D57E52C08783A3C36A4F4440',
        bldg_name: 'FRANKLIN D. ROOSEVELT HS - K',
        hs_enroll: 3061,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1079,
        dataVersion: 'november-2018',
        hs_capacity: 2552,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 2552,
        the_geom_webmercator: '0101000020110F0000A434CEED976A5FC104E9476A59E85241'
      }, {
        x: 989377,
        y: 170579,
        name: 'URBN ASSY SCHL CRIM JUSTICE - K',
        level: 'hs',
        enroll: 328,
        org_id: 'K609',
        source: 'bluebook',
        address: '4200 16 AVENUE',
        bldg_id: 'K223',
        borocode: 3,
        capacity: 324,
        district: 20,
        excluded: false,
        the_geom: '0101000020E61000004ACDD459D17E52C00C3D859543514440',
        bldg_name: 'I.S. 223 - K',
        hs_enroll: 328,
        ms_enroll: 228,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1081,
        dataVersion: 'november-2018',
        hs_capacity: 324,
        ms_capacity: 226,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 324,
        the_geom_webmercator: '0101000020110F0000164AEE7F906A5FC1717F83846AEA5241'
      }, {
        x: 988202,
        y: 155793,
        name: 'INTERNATIONAL HIGH SCHOOL - K',
        level: 'hs',
        enroll: 337,
        org_id: 'K337',
        source: 'bluebook',
        address: '2630 BENSON AVENUE',
        bldg_id: 'K400',
        borocode: 3,
        capacity: 508,
        district: 21,
        excluded: false,
        the_geom: '0101000020E610000060BE7CDA167F52C098AFFFBA114C4440',
        bldg_name: 'LAFAYETTE EDUCATIONAL CAMPUS - K',
        hs_enroll: 337,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1116,
        dataVersion: 'november-2018',
        hs_capacity: 508,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 508,
        the_geom_webmercator: '0101000020110F00005C2A8C8E066B5FC14D8D9BA79AE45241'
      }, {
        x: 991716,
        y: 151498,
        name: 'R. CARSON SCL OF COASTAL ST-K',
        level: 'hs',
        enroll: 639,
        org_id: 'K344',
        source: 'bluebook',
        address: '501 WEST AVENUE',
        bldg_id: 'K303',
        borocode: 3,
        capacity: 498,
        district: 21,
        excluded: false,
        the_geom: '0101000020E61000005AD1D29F477E52C079C9675B8F4A4440',
        bldg_name: 'I.S. 303 - K',
        hs_enroll: 639,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1117,
        dataVersion: 'november-2018',
        hs_capacity: 498,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 498,
        the_geom_webmercator: '0101000020110F0000E91CA88EA6695FC13FBCFA8AEAE25241'
      }, {
        x: 988202,
        y: 155793,
        name: 'HIGH SCHOOL OF SPORTS MGMT- K',
        level: 'hs',
        enroll: 242,
        org_id: 'K348',
        source: 'bluebook',
        address: '2630 BENSON AVENUE',
        bldg_id: 'K400',
        borocode: 3,
        capacity: 543,
        district: 21,
        excluded: false,
        the_geom: '0101000020E610000060BE7CDA167F52C098AFFFBA114C4440',
        bldg_name: 'LAFAYETTE EDUCATIONAL CAMPUS - K',
        hs_enroll: 242,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1118,
        dataVersion: 'november-2018',
        hs_capacity: 543,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 543,
        the_geom_webmercator: '0101000020110F00005C2A8C8E066B5FC14D8D9BA79AE45241'
      }, {
        x: 993331,
        y: 151200,
        name: 'ABRAHAM LINCOLN HS - K',
        level: 'hs',
        enroll: 1998,
        org_id: 'K410',
        source: 'bluebook',
        address: '2800 OCEAN PARKWAY',
        bldg_id: 'K410',
        borocode: 3,
        capacity: 1957,
        district: 21,
        excluded: false,
        the_geom: '0101000020E61000002BB7185FE87D52C0BF984A81744A4440',
        bldg_name: 'ABRAHAM LINCOLN HS - K',
        hs_enroll: 1998,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1119,
        dataVersion: 'november-2018',
        hs_capacity: 1957,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 1957,
        the_geom_webmercator: '0101000020110F00005E80BEC204695FC10C43D783CCE25241'
      }, {
        x: 988202,
        y: 155793,
        name: 'KINGSBOROUGH EARLY COLLEGE SCHOOL',
        level: 'hs',
        enroll: 310,
        org_id: 'K468',
        source: 'bluebook',
        address: '2630 BENSON AVENUE',
        bldg_id: 'K400',
        borocode: 3,
        capacity: 411,
        district: 21,
        excluded: false,
        the_geom: '0101000020E610000060BE7CDA167F52C098AFFFBA114C4440',
        bldg_name: 'LAFAYETTE EDUCATIONAL CAMPUS - K',
        hs_enroll: 310,
        ms_enroll: 247,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1120,
        dataVersion: 'november-2018',
        hs_capacity: 411,
        ms_capacity: 326,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 411,
        the_geom_webmercator: '0101000020110F00005C2A8C8E066B5FC14D8D9BA79AE45241'
      }, {
        x: 995553,
        y: 165353,
        name: 'EDWARD R. MURROW HS - K',
        level: 'hs',
        enroll: 3789,
        org_id: 'K525',
        source: 'bluebook',
        address: '1600 AVENUE L',
        bldg_id: 'K525',
        borocode: 3,
        capacity: 3165,
        district: 21,
        excluded: false,
        the_geom: '0101000020E6100000B67F1BEC647D52C0A1EA055D6D4F4440',
        bldg_name: 'EDWARD R. MURROW HS - K',
        hs_enroll: 3789,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1121,
        dataVersion: 'november-2018',
        hs_capacity: 3165,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 3165,
        the_geom_webmercator: '0101000020110F00000222277B25685FC17440CA525CE85241'
      }, {
        x: 989322,
        y: 153963,
        name: 'JOHN DEWEY HS - K',
        level: 'hs',
        enroll: 2309,
        org_id: 'K540',
        source: 'bluebook',
        address: '50 AVENUE X',
        bldg_id: 'K540',
        borocode: 3,
        capacity: 2219,
        district: 21,
        excluded: false,
        the_geom: '0101000020E6100000A8A853CDD47E52C05A3F611E6D4B4440',
        bldg_name: 'JOHN DEWEY HS - K',
        hs_enroll: 2309,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1122,
        dataVersion: 'november-2018',
        hs_capacity: 2219,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 2219,
        the_geom_webmercator: '0101000020110F0000220BA35C966A5FC17A6A0C8CE2E35241'
      }, {
        x: 988202,
        y: 155793,
        name: 'LIFE ACADEMY H.S. FILM & MUSIC - K',
        level: 'hs',
        enroll: 303,
        org_id: 'K559',
        source: 'bluebook',
        address: '2630 BENSON AVENUE',
        bldg_id: 'K400',
        borocode: 3,
        capacity: 384,
        district: 21,
        excluded: false,
        the_geom: '0101000020E610000060BE7CDA167F52C098AFFFBA114C4440',
        bldg_name: 'LAFAYETTE EDUCATIONAL CAMPUS - K',
        hs_enroll: 303,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1123,
        dataVersion: 'november-2018',
        hs_capacity: 384,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 384,
        the_geom_webmercator: '0101000020110F00005C2A8C8E066B5FC14D8D9BA79AE45241'
      }, {
        x: 988202,
        y: 155793,
        name: 'EXPED LEARN SCHOOL FOR COMM - K',
        level: 'hs',
        enroll: 347,
        org_id: 'K572',
        source: 'bluebook',
        address: '2630 BENSON AVENUE',
        bldg_id: 'K400',
        borocode: 3,
        capacity: 517,
        district: 21,
        excluded: false,
        the_geom: '0101000020E610000060BE7CDA167F52C098AFFFBA114C4440',
        bldg_name: 'LAFAYETTE EDUCATIONAL CAMPUS - K',
        hs_enroll: 347,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1124,
        dataVersion: 'november-2018',
        hs_capacity: 517,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 517,
        the_geom_webmercator: '0101000020110F00005C2A8C8E066B5FC14D8D9BA79AE45241'
      }, {
        x: 994395,
        y: 151530,
        name: 'WILLIAM E. GRADY VOC HS - K',
        level: 'hs',
        enroll: 458,
        org_id: 'K620',
        source: 'bluebook',
        address: '25 BRIGHTON 4TH ROAD',
        bldg_id: 'K620',
        borocode: 3,
        capacity: 1413,
        district: 21,
        excluded: false,
        the_geom: '0101000020E61000008AC9E09AA97D52C02D6FE225924A4440',
        bldg_name: 'WILLIAM E. GRADY VOC HS - K',
        hs_enroll: 458,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1125,
        dataVersion: 'november-2018',
        hs_capacity: 1413,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 1413,
        the_geom_webmercator: '0101000020110F00005D1642259A685FC1BABFF7A9EDE25241'
      }, {
        x: 985804,
        y: 159650,
        name: 'BKLYN STUDIO SEC. SCL - K',
        level: 'hs',
        enroll: 634,
        org_id: 'K690',
        source: 'bluebook',
        address: '8310 21ST AVE',
        bldg_id: 'K721',
        borocode: 3,
        capacity: 537,
        district: 21,
        excluded: false,
        the_geom: '0101000020E610000078B8D24EA47F52C0D6B8C9A86C4D4440',
        bldg_name: 'BROOKLYN STUDIO (TANDEM K128) - K',
        hs_enroll: 634,
        ms_enroll: 308,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1126,
        dataVersion: 'november-2018',
        hs_capacity: 537,
        ms_capacity: 261,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 537,
        the_geom_webmercator: '0101000020110F0000530DD8D4F66B5FC1A77DB0B71EE65241'
      }, {
        x: 988152,
        y: 149569,
        name: 'LIBERATION DIPLOMA PLUS H.S. - K',
        level: 'hs',
        enroll: 225,
        org_id: 'K728',
        source: 'bluebook',
        address: '2865 WEST 19 STREET',
        bldg_id: 'K728',
        borocode: 3,
        capacity: 240,
        district: 21,
        excluded: false,
        the_geom: '0101000020E6100000E3D3B0DC197F52C0AFA535EFE1494440',
        bldg_name: 'LIBERATION DIPLOMA PLUS H.S. - K',
        hs_enroll: 225,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1127,
        dataVersion: 'november-2018',
        hs_capacity: 240,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 240,
        the_geom_webmercator: '0101000020110F000095CAD0AA0B6B5FC182678E9D28E25241'
      }, {
        x: 997315,
        y: 169985,
        name: 'MIDWOOD HS - K',
        level: 'hs',
        enroll: 4058,
        org_id: 'K405',
        source: 'bluebook',
        address: '2839 BEDFORD AVENUE',
        bldg_id: 'K405',
        borocode: 3,
        capacity: 2462,
        district: 22,
        excluded: false,
        the_geom: '0101000020E6100000BEAB3FC9FC7C52C01CD852E40D514440',
        bldg_name: 'MIDWOOD HS - K',
        hs_enroll: 4058,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1165,
        dataVersion: 'november-2018',
        hs_capacity: 2462,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 2462,
        the_geom_webmercator: '0101000020110F00000C12669874675FC1AE5AAB6D2EEA5241'
      }, {
        x: 997351,
        y: 169695,
        name: 'MIDWOOD HS - K',
        level: 'hs',
        enroll: 0,
        org_id: 'K405',
        source: 'bluebook',
        address: '2886 BEDFORD AVENUE',
        bldg_id: 'K705',
        borocode: 3,
        capacity: 0,
        district: 22,
        excluded: false,
        the_geom: '0101000020E6100000855199ABFA7C52C08684A4CEF3504440',
        bldg_name: 'MIDWOOD HS ADDITION - K',
        hs_enroll: 0,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1166,
        dataVersion: 'november-2018',
        hs_capacity: 0,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 0,
        the_geom_webmercator: '0101000020110F00008B1C5A0071675FC17225863C11EA5241'
      }, {
        x: 998546,
        y: 161488,
        name: 'JAMES MADISON HS - K',
        level: 'hs',
        enroll: 3657,
        org_id: 'K425',
        source: 'bluebook',
        address: '3787 BEDFORD AVENUE',
        bldg_id: 'K425',
        borocode: 3,
        capacity: 2253,
        district: 22,
        excluded: false,
        the_geom: '0101000020E6100000FE0C906AB47C52C03B1B2A99114E4440',
        bldg_name: 'JAMES MADISON HS - K',
        hs_enroll: 3657,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1167,
        dataVersion: 'november-2018',
        hs_capacity: 2253,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 2253,
        the_geom_webmercator: '0101000020110F0000A54CF5AAF9665FC15D89F53BD7E65241'
      }, {
        x: 1002041,
        y: 150918,
        name: 'KINGSBOROUGH EARLY COLLEGE SCHOOL',
        level: 'hs',
        enroll: 56,
        org_id: 'K468',
        source: 'bluebook',
        address: '1830 SHORE BOULEVARD',
        bldg_id: 'K535',
        borocode: 3,
        capacity: 58,
        district: 22,
        excluded: false,
        the_geom: '0101000020E610000022265AA3E67B52C0CF747BB55A4A4440',
        bldg_name: 'LEON GOLDSTEIN HS - K',
        hs_enroll: 56,
        ms_enroll: 44,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1168,
        dataVersion: 'november-2018',
        hs_capacity: 58,
        ms_capacity: 46,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 58,
        the_geom_webmercator: '0101000020110F0000669E04229C655FC121D410ABAFE25241'
      }, {
        x: 1002041,
        y: 150918,
        name: 'LEON GOLDSTEIN HS - K',
        level: 'hs',
        enroll: 1011,
        org_id: 'K535',
        source: 'bluebook',
        address: '1830 SHORE BOULEVARD',
        bldg_id: 'K535',
        borocode: 3,
        capacity: 885,
        district: 22,
        excluded: false,
        the_geom: '0101000020E610000022265AA3E67B52C0CF747BB55A4A4440',
        bldg_name: 'LEON GOLDSTEIN HS - K',
        hs_enroll: 1011,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1169,
        dataVersion: 'november-2018',
        hs_capacity: 885,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 885,
        the_geom_webmercator: '0101000020110F0000669E04229C655FC121D410ABAFE25241'
      }, {
        x: 997391,
        y: 169422,
        name: 'BROOKLYN COLLEGE ACADEMY - K',
        level: 'hs',
        enroll: 326,
        org_id: 'K555',
        source: 'bluebook',
        address: '2900 BEDFORD AVENUE',
        bldg_id: 'K555',
        borocode: 3,
        capacity: 177,
        district: 22,
        excluded: false,
        the_geom: '0101000020E610000042016151F87C52C00A945540DB504440',
        bldg_name: 'BKLYN COLL ACAD (AT BKLYN COLL)-K',
        hs_enroll: 326,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1170,
        dataVersion: 'november-2018',
        hs_capacity: 177,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 177,
        the_geom_webmercator: '0101000020110F00009DB76B016D675FC1334074C1F5E95241'
      }, {
        x: 1001553,
        y: 155738,
        name: 'ORIGINS HIGH SCHOOL',
        level: 'hs',
        enroll: 427,
        org_id: 'K611',
        source: 'bluebook',
        address: '3000 AVENUE X',
        bldg_id: 'K495',
        borocode: 3,
        capacity: 758,
        district: 22,
        excluded: false,
        the_geom: '0101000020E61000006D342E38037C52C0B8AB54420C4C4440',
        bldg_name: 'FRANK J. MACCHIAROLA EDUCATIONAL COMPLEX - K',
        hs_enroll: 427,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1171,
        dataVersion: 'november-2018',
        hs_capacity: 758,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 758,
        the_geom_webmercator: '0101000020110F0000C62363AECC655FC1607AFE8894E45241'
      }, {
        x: 1001553,
        y: 155738,
        name: 'PROFESSIONAL PATHWAYS HIGH SCHOOL',
        level: 'hs',
        enroll: 205,
        org_id: 'K630',
        source: 'bluebook',
        address: '3000 AVENUE X',
        bldg_id: 'K495',
        borocode: 3,
        capacity: 413,
        district: 22,
        excluded: false,
        the_geom: '0101000020E61000006D342E38037C52C0B8AB54420C4C4440',
        bldg_name: 'FRANK J. MACCHIAROLA EDUCATIONAL COMPLEX - K',
        hs_enroll: 205,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1172,
        dataVersion: 'november-2018',
        hs_capacity: 413,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 3,
        capacityFuture: 413,
        the_geom_webmercator: '0101000020110F0000C62363AECC655FC1607AFE8894E45241'
      }, {
        x: 1008307,
        y: 184882,
        name: 'BROOKLYNCOLLEGIAT: A COLLEGE BOARD SCHOOL',
        level: 'hs',
        enroll: 271,
        org_id: 'K493',
        source: 'bluebook',
        address: '2021 BERGEN STREET',
        bldg_id: 'K055',
        borocode: 3,
        capacity: 636,
        district: 23,
        excluded: false,
        the_geom: '0101000020E6100000B74FAF11737A52C07B58D8FC48564440',
        bldg_name: 'I.S. 55 - K',
        hs_enroll: 271,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1192,
        dataVersion: 'november-2018',
        hs_capacity: 636,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 636,
        the_geom_webmercator: '0101000020110F0000574B4BFC24635FC10B534A8209F05241'
      }, {
        x: 1008745,
        y: 181886,
        name: 'FREDERICK DOUGLAS ACAD VII HS - K',
        level: 'hs',
        enroll: 157,
        org_id: 'K514',
        source: 'bluebook',
        address: '226 BRISTOL STREET',
        bldg_id: 'K175',
        borocode: 3,
        capacity: 374,
        district: 23,
        excluded: false,
        the_geom: '0101000020E610000025DC7D60597A52C07E5D577C3B554440',
        bldg_name: 'TEACHERS PREP HS(OL REDIRECTION)-K',
        hs_enroll: 157,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1193,
        dataVersion: 'november-2018',
        hs_capacity: 374,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 374,
        the_geom_webmercator: '0101000020110F0000D7084558F9625FC145A333BCDBEE5241'
      }, {
        x: 1009986,
        y: 178313,
        name: 'BROOKLYN DEMOCRACY ACADEMY - K',
        level: 'hs',
        enroll: 230,
        org_id: 'K643',
        source: 'bluebook',
        address: '985 ROCKAWAY AVENUE',
        bldg_id: 'K275',
        borocode: 3,
        capacity: 330,
        district: 23,
        excluded: false,
        the_geom: '0101000020E6100000ADBF3F4F107A52C064FCF602FA534440',
        bldg_name: 'I.S. 275 - K',
        hs_enroll: 230,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1197,
        dataVersion: 'november-2018',
        hs_capacity: 330,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 330,
        the_geom_webmercator: '0101000020110F00000165883B7D625FC18A0D7ED073ED5241'
      }, {
        x: 1007593,
        y: 186230,
        name: 'EAGLE ACAD YOUNG MEN AT OCEAN - K',
        level: 'hs',
        enroll: 363,
        org_id: 'K644',
        source: 'bluebook',
        address: '1137 HERKIMER STREET',
        bldg_id: 'K271',
        borocode: 3,
        capacity: 500,
        district: 23,
        excluded: false,
        the_geom: '0101000020E6100000A0C7432A9D7A52C0C1CF584AC2564440',
        bldg_name: 'I.S. 271 - K',
        hs_enroll: 363,
        ms_enroll: 284,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1198,
        dataVersion: 'november-2018',
        hs_capacity: 500,
        ms_capacity: 391,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 500,
        the_geom_webmercator: '0101000020110F0000634C667D6C635FC1D741705991F05241'
      }, {
        x: 1010891,
        y: 185995,
        name: 'ASPIRATIONS DIPLOMA HS - K',
        level: 'hs',
        enroll: 214,
        org_id: 'K646',
        source: 'bluebook',
        address: '1495 HERKIMER STREET',
        bldg_id: 'K894',
        borocode: 3,
        capacity: 338,
        district: 23,
        excluded: false,
        the_geom: '0101000020E61000007090695FDA7952C07B1C68D9AC564440',
        bldg_name: 'HS FOR PUBLIC SERVICE-ENY - K',
        hs_enroll: 214,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1199,
        dataVersion: 'november-2018',
        hs_capacity: 338,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 338,
        the_geom_webmercator: '0101000020110F00003F63889D21625FC1325D875679F05241'
      }, {
        x: 1009986,
        y: 178313,
        name: 'METRO DIPLOMA PLUS - K',
        level: 'hs',
        enroll: 217,
        org_id: 'K647',
        source: 'bluebook',
        address: '985 ROCKAWAY AVENUE',
        bldg_id: 'K275',
        borocode: 3,
        capacity: 317,
        district: 23,
        excluded: false,
        the_geom: '0101000020E6100000ADBF3F4F107A52C064FCF602FA534440',
        bldg_name: 'I.S. 275 - K',
        hs_enroll: 217,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1200,
        dataVersion: 'november-2018',
        hs_capacity: 317,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 317,
        the_geom_webmercator: '0101000020110F00000165883B7D625FC18A0D7ED073ED5241'
      }, {
        x: 1008745,
        y: 181886,
        name: 'TEACHERS PREP (HS LVL) - K',
        level: 'hs',
        enroll: 200,
        org_id: 'K697',
        source: 'bluebook',
        address: '226 BRISTOL STREET',
        bldg_id: 'K175',
        borocode: 3,
        capacity: 532,
        district: 23,
        excluded: false,
        the_geom: '0101000020E610000025DC7D60597A52C07E5D577C3B554440',
        bldg_name: 'TEACHERS PREP HS(OL REDIRECTION)-K',
        hs_enroll: 200,
        ms_enroll: 50,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1204,
        dataVersion: 'november-2018',
        hs_capacity: 532,
        ms_capacity: 133,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 532,
        the_geom_webmercator: '0101000020110F0000D7084558F9625FC145A333BCDBEE5241'
      }, {
        x: 1008987,
        y: 193214,
        name: 'BROOKLYN SCHOOL FOR MATH AND RESEARCH - K',
        level: 'hs',
        enroll: 269,
        org_id: 'K168',
        source: 'bluebook',
        address: '400 IRVING AVENUE',
        bldg_id: 'K480',
        borocode: 3,
        capacity: 314,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000E2A652674A7A52C0084DBF4F36594440',
        bldg_name: 'BUSHWICK EDUCATIONAL CAMPUS - K',
        hs_enroll: 269,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1698,
        dataVersion: 'november-2018',
        hs_capacity: 314,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 314,
        the_geom_webmercator: '0101000020110F0000F62B3FE9DF625FC196B9D1C150F35241'
      }, {
        x: 1008987,
        y: 193214,
        name: 'ACAD. FOR ENVIRON. LDRSHP - K',
        level: 'hs',
        enroll: 280,
        org_id: 'K403',
        source: 'bluebook',
        address: '400 IRVING AVENUE',
        bldg_id: 'K480',
        borocode: 3,
        capacity: 344,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000E2A652674A7A52C0084DBF4F36594440',
        bldg_name: 'BUSHWICK EDUCATIONAL CAMPUS - K',
        hs_enroll: 280,
        ms_enroll: 3,
        org_level: 'HS',
        ps_enroll: 8,
        cartodb_id: 1709,
        dataVersion: 'november-2018',
        hs_capacity: 344,
        ms_capacity: 4,
        ps_capacity: 10,
        subdistrict: 2,
        capacityFuture: 344,
        the_geom_webmercator: '0101000020110F0000F62B3FE9DF625FC196B9D1C150F35241'
      }, {
        x: 1003909,
        y: 192312,
        name: 'EBC HS FOR PUB. SERV. - BUSHWICK-K',
        level: 'hs',
        enroll: 498,
        org_id: 'K545',
        source: 'bluebook',
        address: '1155 DEKALB AVENUE',
        bldg_id: 'K913',
        borocode: 3,
        capacity: 580,
        district: 32,
        excluded: false,
        the_geom: '0101000020E61000009E45807B767B52C0EF31409AE5584440',
        bldg_name: 'EBC HS FOR PUB. SERV. - BUSHWICK-K',
        hs_enroll: 498,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1710,
        dataVersion: 'november-2018',
        hs_capacity: 580,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 580,
        the_geom_webmercator: '0101000020110F000034BC0CA0DD645FC106E33F59F6F25241'
      }, {
        x: 1008987,
        y: 193214,
        name: 'THE BROOKLYN SCHOOL FOR SOCIAL JUSTICE - K',
        level: 'hs',
        enroll: 364,
        org_id: 'K549',
        source: 'bluebook',
        address: '400 IRVING AVENUE',
        bldg_id: 'K480',
        borocode: 3,
        capacity: 565,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000E2A652674A7A52C0084DBF4F36594440',
        bldg_name: 'BUSHWICK EDUCATIONAL CAMPUS - K',
        hs_enroll: 364,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1711,
        dataVersion: 'november-2018',
        hs_capacity: 565,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 565,
        the_geom_webmercator: '0101000020110F0000F62B3FE9DF625FC196B9D1C150F35241'
      }, {
        x: 1008987,
        y: 193214,
        name: 'THE ACADEMY OF URBAN PLANNING AND ENGINEERING - K',
        level: 'hs',
        enroll: 197,
        org_id: 'K552',
        source: 'bluebook',
        address: '400 IRVING AVENUE',
        bldg_id: 'K480',
        borocode: 3,
        capacity: 396,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000E2A652674A7A52C0084DBF4F36594440',
        bldg_name: 'BUSHWICK EDUCATIONAL CAMPUS - K',
        hs_enroll: 197,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1712,
        dataVersion: 'november-2018',
        hs_capacity: 396,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 396,
        the_geom_webmercator: '0101000020110F0000F62B3FE9DF625FC196B9D1C150F35241'
      }, {
        x: 1008327,
        y: 193359,
        name: 'I.S. 554 - K',
        level: 'hs',
        enroll: 226,
        org_id: 'K554',
        source: 'bluebook',
        address: '321 PALMETTO STREET',
        bldg_id: 'K554',
        borocode: 3,
        capacity: 224,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000D69C3764717A52C009979B6943594440',
        bldg_name: 'ALL CITY LEADERSHIP SECONDARY SCHOOL - K',
        hs_enroll: 226,
        ms_enroll: 159,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1713,
        dataVersion: 'november-2018',
        hs_capacity: 224,
        ms_capacity: 158,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 224,
        the_geom_webmercator: '0101000020110F0000C7DBCC2222635FC1C2C2D26E5FF35241'
      }, {
        x: 1008123,
        y: 193511,
        name: 'I.S. 554 - K',
        level: 'hs',
        enroll: 29,
        org_id: 'K554',
        source: 'bluebook',
        address: '1474 GATES AVENUE',
        bldg_id: 'K779',
        borocode: 3,
        capacity: 33,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000EAD9A66F7D7A52C0F716021A51594440',
        bldg_name: 'ALL CITY LEADERSHIP SCHOOL - K',
        hs_enroll: 29,
        ms_enroll: 21,
        org_level: 'ISHS',
        ps_enroll: 0,
        cartodb_id: 1714,
        dataVersion: 'november-2018',
        hs_capacity: 33,
        ms_capacity: 23,
        ps_capacity: 0,
        subdistrict: 2,
        capacityFuture: 33,
        the_geom_webmercator: '0101000020110F000013F5529836635FC178937CC46EF35241'
      }, {
        x: 1004177,
        y: 192512,
        name: 'BUSHWICK LEADERS - K',
        level: 'hs',
        enroll: 281,
        org_id: 'K556',
        source: 'bluebook',
        address: '797 BUSHWICK AVENUE',
        bldg_id: 'K865',
        borocode: 3,
        capacity: 557,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000E61170A3667B52C0D2922D92F7584440',
        bldg_name: 'SECOND OPPORTUNITY SCHOOL - K',
        hs_enroll: 281,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1715,
        dataVersion: 'november-2018',
        hs_capacity: 557,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 557,
        the_geom_webmercator: '0101000020110F00003C086BB6C2645FC1A796EA790AF35241'
      }, {
        x: 1007781,
        y: 192627,
        name: 'BUSHWICK COMMUNITY HS - K',
        level: 'hs',
        enroll: 263,
        org_id: 'K564',
        source: 'bluebook',
        address: '231 PALMETTO STREET',
        bldg_id: 'K291',
        borocode: 3,
        capacity: 423,
        district: 32,
        excluded: false,
        the_geom: '0101000020E6100000BD8BB6B1917A52C03310CC9F01594440',
        bldg_name: 'I.S. 291 - K',
        hs_enroll: 263,
        ms_enroll: 0,
        org_level: 'HS',
        ps_enroll: 0,
        cartodb_id: 1717,
        dataVersion: 'november-2018',
        hs_capacity: 423,
        ms_capacity: 0,
        ps_capacity: 0,
        subdistrict: 1,
        capacityFuture: 423,
        the_geom_webmercator: '0101000020110F000002FD5E0159635FC17991CEBC15F35241'
      }, {
        name: 'P.S. 583',
        level: 'ps',
        enroll: 115,
        grades: 'K - 05',
        org_id: 'X583',
        source: 'lcgms',
        address: '1028 WHITE PLAINS ROAD',
        bldg_id: 'X317',
        capacity: '',
        district: 17,
        the_geom: '0101000020E6100000ADDC0BCC0A7752C0FAEB1516DC694440',
        hs_enroll: 0,
        is_enroll: 0,
        org_level: 'PS',
        ps_enroll: 115,
        cartodb_id: 4,
        subdistrict: 2
      }, {
        name: 'P.S. 553',
        level: 'ps',
        enroll: 155,
        grades: 'K - 05',
        org_id: 'X583',
        source: 'lcgms',
        address: '1028 WHITE PLAINS ROAD',
        bldg_id: 'X317',
        capacity: '',
        district: 17,
        the_geom: '0101000020E6100000ADDC0BCC0A7752C0FAEB1516DC694440',
        hs_enroll: 0,
        is_enroll: 0,
        org_level: 'PS',
        ps_enroll: 155,
        cartodb_id: 6,
        subdistrict: 1
      }];
    },
    scaProjects: function scaProjects() {
      return [];
    },
    doeUtilChanges: function doeUtilChanges() {
      return [{
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Temporary Co-location of the Ninth and Tenth Grades of Unity Preparatory Charter School of Brooklyn (84K757) with Brownsville Academy High School (17K568) in Building K907 Beginning in 2016-2017',
        org_id: 'K757',
        bldg_id: 'K907',
        vote_date: '4/4/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 120,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-Siting and Consolidation of Aspirations Diploma Plus High School (23K646) with W.E.B. Dubois Academic High School (17K489) in Building K824 Beginning in the 2018-2019 School Year',
        org_id: 'K646',
        bldg_id: 'K824',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 255,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-Siting and Consolidation of Aspirations Diploma Plus High School (23K646) with W.E.B. Dubois Academic High School (17K489) in Building K824 Beginning in the 2018-2019 School Year',
        org_id: 'K489',
        bldg_id: 'K824',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-Siting and Consolidation of Aspirations Diploma Plus High School (23K646) with W.E.B. Dubois Academic High School (17K489) in Building K824 Beginning in the 2018-2019 School Year',
        org_id: 'K981',
        bldg_id: 'K824',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 80,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-Siting and Consolidation of Aspirations Diploma Plus High School (23K646) with W.E.B. Dubois Academic High School (17K489) in Building K824 Beginning in the 2018-2019 School Year',
        org_id: 'M973',
        bldg_id: 'K824',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 30,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of the Elementary School Grades of Uncommon Ocean Hill Collegiate Charter School (84K777) with P.S. 221 Toussaint L’Ouverture in Buildings K221 and K892 Beginning in the 2018-2019 School Year',
        org_id: 'K221',
        bldg_id: 'K221',
        vote_date: '4/25/2018',
        at_scale_year: '2022-23',
        at_scale_enroll: 294,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of the Elementary School Grades of Uncommon Ocean Hill Collegiate Charter School (84K777) with P.S. 221 Toussaint L’Ouverture in Buildings K221 and K892 Beginning in the 2018-2019 School Year',
        org_id: 'K692',
        bldg_id: 'K221',
        vote_date: '4/25/2018',
        at_scale_year: '2022-23',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of the Elementary School Grades of Uncommon Ocean Hill Collegiate Charter School (84K777) with P.S. 221 Toussaint L’Ouverture in Buildings K221 and K892 Beginning in the 2018-2019 School Year',
        org_id: 'K777',
        bldg_id: 'K221',
        vote_date: '4/25/2018',
        at_scale_year: '2022-23',
        at_scale_enroll: 425,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-Siting and Co-location of Gotham Professional Arts Academy (16K594) with ACORN Community High School (13K499) in Building K909 Beginning in the 2018-2019 School Year',
        org_id: 'K594',
        bldg_id: 'K909',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 205,
        bldg_id_additional: 'K040'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-Siting and Co-location of Gotham Professional Arts Academy (16K594) with ACORN Community High School (13K499) in Building K909 Beginning in the 2018-2019 School Year',
        org_id: 'K499',
        bldg_id: 'K909',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 220,
        bldg_id_additional: 'K040'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Grade Expansion of Brooklyn Prospect Charter School – Clinton Hill (84K879) in Building K117 and One-Year Extension of the Temporary Co-location with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leadership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in the 2018-2019 School Year',
        org_id: 'K369',
        bldg_id: 'K117',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 36,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Grade Expansion of Brooklyn Prospect Charter School – Clinton Hill (84K879) in Building K117 and One-Year Extension of the Temporary Co-location with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leadership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in the 2018-2019 School Year',
        org_id: 'K879',
        bldg_id: 'K117',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 320,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Grade Expansion of Brooklyn Prospect Charter School – Clinton Hill (84K879) in Building K117 and One-Year Extension of the Temporary Co-location with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leadership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in the 2018-2019 School Year',
        org_id: 'K616',
        bldg_id: 'K117',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 230,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Grade Expansion of Brooklyn Prospect Charter School – Clinton Hill (84K879) in Building K117 and One-Year Extension of the Temporary Co-location with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leadership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in the 2018-2019 School Year',
        org_id: 'K412',
        bldg_id: 'K117',
        vote_date: '4/25/2018',
        at_scale_year: '2018-19',
        at_scale_enroll: 465,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Brooklyn East Collegiate Charter School (84K780) to Building K458 in the 2018-2019 School Year',
        org_id: 'Q950',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 219,
        bldg_id_additional: 'K009'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Brooklyn East Collegiate Charter School (84K780) to Building K458 in the 2018-2019 School Year',
        org_id: 'K373',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 12,
        bldg_id_additional: 'K009'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Brooklyn East Collegiate Charter School (84K780) to Building K458 in the 2018-2019 School Year',
        org_id: 'K780',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 340,
        bldg_id_additional: 'K009'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Brooklyn East Collegiate Charter School (84K780) to Building K458 in the 2018-2019 School Year',
        org_id: 'K517',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 630,
        bldg_id_additional: 'K009'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Brooklyn East Collegiate Charter School (84K780) to Building K458 in the 2018-2019 School Year',
        org_id: 'K553',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 290,
        bldg_id_additional: 'K009'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Brooklyn East Collegiate Charter School (84K780) to Building K458 in the 2018-2019 School Year',
        org_id: 'K575',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: null,
        bldg_id_additional: 'K009'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Consolidation of Bedford Stuyvesant Preparatory High School (13K575) with Brooklyn Academy High School (13K553) in Building K458 Beginning in the 2018-2019 School Year',
        org_id: 'Q950',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 219,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Consolidation of Bedford Stuyvesant Preparatory High School (13K575) with Brooklyn Academy High School (13K553) in Building K458 Beginning in the 2018-2019 School Year',
        org_id: 'K373',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 12,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Consolidation of Bedford Stuyvesant Preparatory High School (13K575) with Brooklyn Academy High School (13K553) in Building K458 Beginning in the 2018-2019 School Year',
        org_id: 'K780',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 340,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Consolidation of Bedford Stuyvesant Preparatory High School (13K575) with Brooklyn Academy High School (13K553) in Building K458 Beginning in the 2018-2019 School Year',
        org_id: 'K517',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 630,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Consolidation of Bedford Stuyvesant Preparatory High School (13K575) with Brooklyn Academy High School (13K553) in Building K458 Beginning in the 2018-2019 School Year',
        org_id: 'K553',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 290,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/april-25-2018-school-utilization-proposals',
        title: 'The Proposed Consolidation of Bedford Stuyvesant Preparatory High School (13K575) with Brooklyn Academy High School (13K553) in Building K458 Beginning in the 2018-2019 School Year',
        org_id: 'K575',
        bldg_id: 'K458',
        vote_date: '4/25/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/february-28-2018-school-utilization-proposals',
        title: 'The Proposed Grade Expansion of Unity Preparatory Charter School of Brooklyn (84K757) in Building K907 and One-Year Extension of the Temporary Co-location with Brownsville Academy High School (17K568) through the 2018-2019 School Year',
        org_id: 'K568',
        bldg_id: 'K907',
        vote_date: '2/28/2018',
        at_scale_year: '2019-20',
        at_scale_enroll: 150,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/february-28-2018-school-utilization-proposals',
        title: 'The Proposed Grade Expansion of Unity Preparatory Charter School of Brooklyn (84K757) in Building K907 and One-Year Extension of the Temporary Co-location with Brownsville Academy High School (17K568) through the 2018-2019 School Year',
        org_id: 'K757',
        bldg_id: 'K907',
        vote_date: '2/28/2018',
        at_scale_year: '2019-20',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/january-24-2018-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of the Elementary School Grades of Achievement First Voyager Charter School (84K876) with P.S. 91 The Albany Avenue School (17K091) in Buildings K091 and K891 Beginning in the 2018-2019 School Year',
        org_id: 'K091',
        bldg_id: 'K091',
        vote_date: '1/24/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 306,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2017-2018-pages/january-24-2018-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of the Elementary School Grades of Achievement First Voyager Charter School (84K876) with P.S. 91 The Albany Avenue School (17K091) in Buildings K091 and K891 Beginning in the 2018-2019 School Year',
        org_id: 'K876',
        bldg_id: 'K091',
        vote_date: '1/24/2018',
        at_scale_year: '2021-22',
        at_scale_enroll: 450,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/june-21-2017-school-utilization-proposals',
        title: 'The Proposed Temporary Re-Siting of East New York Family Academy (19K409) and Co-location with W. H. Maxwell Career and Technical Education High School (19K660) in Building K660 Beginning in the 2018-2019 School Year',
        org_id: 'K409',
        bldg_id: 'K660',
        vote_date: '6/21/2017',
        at_scale_year: '2021-22',
        at_scale_enroll: null,
        bldg_id_additional: 'K819'
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/june-21-2017-school-utilization-proposals',
        title: 'The Proposed Temporary Re-Siting of East New York Family Academy (19K409) and Co-location with W. H. Maxwell Career and Technical Education High School (19K660) in Building K660 Beginning in the 2018-2019 School Year',
        org_id: 'M973',
        bldg_id: 'K660',
        vote_date: '6/21/2017',
        at_scale_year: '2021-22',
        at_scale_enroll: 30,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/june-21-2017-school-utilization-proposals',
        title: 'The Proposed Temporary Re-Siting of East New York Family Academy (19K409) and Co-location with W. H. Maxwell Career and Technical Education High School (19K660) in Building K660 Beginning in the 2018-2019 School Year',
        org_id: 'K660',
        bldg_id: 'K660',
        vote_date: '6/21/2017',
        at_scale_year: '2021-22',
        at_scale_enroll: 490,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Consolidation of Francis Perkins Academy (14K632) with Automotive High School (14K610) in Building K610 Beginning in the 2017-2018 School Year',
        org_id: 'K610',
        bldg_id: 'K610',
        vote_date: '3/22/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 475,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Consolidation of Francis Perkins Academy (14K632) with Automotive High School (14K610) in Building K610 Beginning in the 2017-2018 School Year',
        org_id: 'K632',
        bldg_id: 'K610',
        vote_date: '3/22/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Consolidation of Frederick Douglass Academy IV Secondary School (16K393) with The Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'K688',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 215,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Consolidation of Frederick Douglass Academy IV Secondary School (16K393) with The Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'K393',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Consolidation of Frederick Douglass Academy IV Secondary School (16K393) with The Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'K057',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 210,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Consolidation of Frederick Douglass Academy IV Secondary School (16K393) with The Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'KTBD',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 390,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Opening and Co-Location of Brooklyn Emerging Leaders Academy Charter School (84KTBD) with J.H.S. 57 Whitelaw Reid (16K057) and the consolidated Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'K688',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 815,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Opening and Co-Location of Brooklyn Emerging Leaders Academy Charter School (84KTBD) with J.H.S. 57 Whitelaw Reid (16K057) and the consolidated Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'K393',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Opening and Co-Location of Brooklyn Emerging Leaders Academy Charter School (84KTBD) with J.H.S. 57 Whitelaw Reid (16K057) and the consolidated Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'K057',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 210,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/march-22-2017-school-utilization',
        title: 'The Proposed Opening and Co-Location of Brooklyn Emerging Leaders Academy Charter School (84KTBD) with J.H.S. 57 Whitelaw Reid (16K057) and the consolidated Brooklyn Academy of Global Finance (16K688) in Building K057 Beginning in the 2017-2018 School Year',
        org_id: 'KTBD',
        bldg_id: 'K057',
        vote_date: '3/22/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 390,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/february-28-2017-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of Uncommon Leadership Charter High School with Multicultural High School (19K583), Academy for Innovative Technology (19K618), Brooklyn Lab School (19K639), Cypress Hills Collegiate Preparatory School (19K659) and The Urban Assembly School for Collaborative Healthcare (19K764)',
        org_id: 'K583',
        bldg_id: 'K420',
        vote_date: '2/28/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 230,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/february-28-2017-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of Uncommon Leadership Charter High School with Multicultural High School (19K583), Academy for Innovative Technology (19K618), Brooklyn Lab School (19K639), Cypress Hills Collegiate Preparatory School (19K659) and The Urban Assembly School for Collaborative Healthcare (19K764)',
        org_id: 'K618',
        bldg_id: 'K420',
        vote_date: '2/28/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 430,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/february-28-2017-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of Uncommon Leadership Charter High School with Multicultural High School (19K583), Academy for Innovative Technology (19K618), Brooklyn Lab School (19K639), Cypress Hills Collegiate Preparatory School (19K659) and The Urban Assembly School for Collaborative Healthcare (19K764)',
        org_id: 'K639',
        bldg_id: 'K420',
        vote_date: '2/28/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 340,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/february-28-2017-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of Uncommon Leadership Charter High School with Multicultural High School (19K583), Academy for Innovative Technology (19K618), Brooklyn Lab School (19K639), Cypress Hills Collegiate Preparatory School (19K659) and The Urban Assembly School for Collaborative Healthcare (19K764)',
        org_id: 'K659',
        bldg_id: 'K420',
        vote_date: '2/28/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 265,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/february-28-2017-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of Uncommon Leadership Charter High School with Multicultural High School (19K583), Academy for Innovative Technology (19K618), Brooklyn Lab School (19K639), Cypress Hills Collegiate Preparatory School (19K659) and The Urban Assembly School for Collaborative Healthcare (19K764)',
        org_id: 'K764',
        bldg_id: 'K420',
        vote_date: '2/28/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 335,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/february-28-2017-school-utilization-proposals',
        title: 'The Proposed Opening and Co-location of Uncommon Leadership Charter High School with Multicultural High School (19K583), Academy for Innovative Technology (19K618), Brooklyn Lab School (19K639), Cypress Hills Collegiate Preparatory School (19K659) and The Urban Assembly School for Collaborative Healthcare (19K764)',
        org_id: 'KTBD',
        bldg_id: 'K420',
        vote_date: '2/28/2017',
        at_scale_year: '2020-21',
        at_scale_enroll: 660,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: Expansion of Success Academy Charter School - Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and a District 75 Program (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K497',
        bldg_id: 'K293',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 670,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: Expansion of Success Academy Charter School - Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and a District 75 Program (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K429',
        bldg_id: 'K293',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 200,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: Expansion of Success Academy Charter School - Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and a District 75 Program (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K368',
        bldg_id: 'K293',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 30,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: Expansion of Success Academy Charter School - Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and a District 75 Program (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K129',
        bldg_id: 'K293',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 390,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: New Public Charter School Success Academy Charter School – Brooklyn 5 (84KTBD) with Existing Schools Dr. Susan S. McKinney Secondary School of the Arts (13K265) and a District 75 Inclusion Program P369K@265K (75K369) in Building K265',
        org_id: 'K265',
        bldg_id: 'K265',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 435,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: New Public Charter School Success Academy Charter School – Brooklyn 5 (84KTBD) with Existing Schools Dr. Susan S. McKinney Secondary School of the Arts (13K265) and a District 75 Inclusion Program P369K@265K (75K369) in Building K265',
        org_id: 'K369',
        bldg_id: 'K265',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 24,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: New Public Charter School Success Academy Charter School – Brooklyn 5 (84KTBD) with Existing Schools Dr. Susan S. McKinney Secondary School of the Arts (13K265) and a District 75 Inclusion Program P369K@265K (75K369) in Building K265',
        org_id: 'K752',
        bldg_id: 'K265',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 395,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: Success Academy Charter School – Crown Heights (84K741) with Existing School P.S. 161 The Crown (17K161) in Building K161 Beginning in 2014-2015',
        org_id: 'K161',
        bldg_id: 'K161',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 406,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'Revised Building UtilizationPla: Success Academy Charter School – Crown Heights (84K741) with Existing School P.S. 161 The Crown (17K161) in Building K161 Beginning in 2014-2015',
        org_id: 'K741',
        bldg_id: 'K161',
        vote_date: '1/18/2017',
        at_scale_year: '2017-18',
        at_scale_enroll: 430,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'The Proposed Consolidation of The School for Democracy and Leadership (17K533) with The School for Human Rights (17K531) and Truncation of Grades 6-8 at the Consolidated School for Human Rights in Building K470 Beginning in the 2017-2018 School Year',
        org_id: 'K531',
        bldg_id: 'K470',
        vote_date: '1/18/2017',
        at_scale_year: '2019-20',
        at_scale_enroll: 525,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'The Proposed Consolidation of The School for Democracy and Leadership (17K533) with The School for Human Rights (17K531) and Truncation of Grades 6-8 at the Consolidated School for Human Rights in Building K470 Beginning in the 2017-2018 School Year',
        org_id: 'K533',
        bldg_id: 'K470',
        vote_date: '1/18/2017',
        at_scale_year: '2019-20',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'The Proposed Consolidation of The School for Democracy and Leadership (17K533) with The School for Human Rights (17K531) and Truncation of Grades 6-8 at the Consolidated School for Human Rights in Building K470 Beginning in the 2017-2018 School Year',
        org_id: 'K546',
        bldg_id: 'K470',
        vote_date: '1/18/2017',
        at_scale_year: '2019-20',
        at_scale_enroll: 480,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'The Proposed Consolidation of The School for Democracy and Leadership (17K533) with The School for Human Rights (17K531) and Truncation of Grades 6-8 at the Consolidated School for Human Rights in Building K470 Beginning in the 2017-2018 School Year',
        org_id: 'K745',
        bldg_id: 'K470',
        vote_date: '1/18/2017',
        at_scale_year: '2019-20',
        at_scale_enroll: 365,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/january-18-2017-school-utilization-proposals',
        title: 'The Proposed Consolidation of The School for Democracy and Leadership (17K533) with The School for Human Rights (17K531) and Truncation of Grades 6-8 at the Consolidated School for Human Rights in Building K470 Beginning in the 2017-2018 School Year',
        org_id: 'K036',
        bldg_id: 'K470',
        vote_date: '1/18/2017',
        at_scale_year: '2019-20',
        at_scale_enroll: 10,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Achievement First Voyager Middle School (84K876) to Building K092 with P.S. 92 Adrian Hegeman (17K092) Beginning in the 2017-2018 School Year',
        org_id: 'K876',
        bldg_id: 'K092',
        vote_date: '12/21/2016',
        at_scale_year: '2019-20',
        at_scale_enroll: 360,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Re-siting and Co-location of Achievement First Voyager Middle School (84K876) to Building K092 with P.S. 92 Adrian Hegeman (17K092) Beginning in the 2017-2018 School Year',
        org_id: 'K092',
        bldg_id: 'K092',
        vote_date: '12/21/2016',
        at_scale_year: '2019-20',
        at_scale_enroll: 464,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'K576',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: 550,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'K589',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'K566',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: 235,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'K567',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: 390,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'K578',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: 230,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'K775',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: 725,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'K637',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: 295,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Consolidation of Arts and Media Preparatory Academy (18K589) with Victory Collegiate High School (18K576) in Building K515 Beginning in the 2017-2018 School Year',
        org_id: 'Q950',
        bldg_id: 'K515',
        vote_date: '12/21/2016',
        at_scale_year: '2020-21',
        at_scale_enroll: 100,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Grade Truncation of Teachers Preparatory High School (23K697) from a Secondary School to a High School Beginning in the 2017-2018 School Year',
        org_id: 'K697',
        bldg_id: 'K175',
        vote_date: '12/21/2016',
        at_scale_year: '2019-20',
        at_scale_enroll: 275,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/2016-2017-pages/december-21-2016-school-utilization-proposals',
        title: 'The Proposed Grade Truncation of Teachers Preparatory High School (23K697) from a Secondary School to a High School Beginning in the 2017-2018 School Year',
        org_id: 'K514',
        bldg_id: 'K175',
        vote_date: '12/21/2016',
        at_scale_year: '2019-20',
        at_scale_enroll: 230,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Temporary Co-location of Brooklyn Prospect Charter School - Clinton Hill (84KTBD) with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leardership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in 2016-2017',
        org_id: 'K412',
        bldg_id: 'K117',
        vote_date: '4/11/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 435,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Temporary Co-location of Brooklyn Prospect Charter School - Clinton Hill (84KTBD) with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leardership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in 2016-2017',
        org_id: 'K596',
        bldg_id: 'K117',
        vote_date: '4/11/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Temporary Co-location of Brooklyn Prospect Charter School - Clinton Hill (84KTBD) with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leardership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in 2016-2017',
        org_id: 'K616',
        bldg_id: 'K117',
        vote_date: '4/11/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 235,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Temporary Co-location of Brooklyn Prospect Charter School - Clinton Hill (84KTBD) with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leardership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in 2016-2017',
        org_id: 'K369',
        bldg_id: 'K117',
        vote_date: '4/11/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 48,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Temporary Co-location of Brooklyn Prospect Charter School - Clinton Hill (84KTBD) with Brooklyn Community High School of Communication, Arts, and Media (13K412), Brooklyn High School for Leardership and Community Service (13K616), and P.S. K369 Coy L. Cox (75K369) in Building K117 Beginning in 2016-2017',
        org_id: 'K707',
        bldg_id: 'K117',
        vote_date: '4/11/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 250,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New Site of an Existing District 75 School P.S. K753 (75K753) with High School of Innovation in Advertising and Media (18K617), High School for Medical Professions (18K633), Urban Action Academy (18K642), and School for Cooperative Technical Education (79M645) in Building K500 Beginning in 2016-2017',
        org_id: 'M645',
        bldg_id: 'K500',
        vote_date: '4/5/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 75,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New Site of an Existing District 75 School P.S. K753 (75K753) with High School of Innovation in Advertising and Media (18K617), High School for Medical Professions (18K633), Urban Action Academy (18K642), and School for Cooperative Technical Education (79M645) in Building K500 Beginning in 2016-2017',
        org_id: 'K753',
        bldg_id: 'K500',
        vote_date: '4/5/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 64,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Temporary Co-location of the Ninth and Tenth Grades of Unity Preparatory Charter School of Brooklyn (84K757) with Brownsville Academy High School (17K568) in Building K907 Beginning in 2016-2017',
        org_id: 'K568',
        bldg_id: 'K907',
        vote_date: '4/4/2016',
        at_scale_year: '2017-18',
        at_scale_enroll: 175,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Grade Truncation of Brooklyn School for Global Studies (15K429) in Building K293 from a 6-12 School to a 9-12 School Beginning in the 2016-2017 School Year',
        org_id: 'K429',
        bldg_id: 'K293',
        vote_date: '3/9/2016',
        at_scale_year: '2018-19',
        at_scale_enroll: 225,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Grade Truncation of Brooklyn School for Global Studies (15K429) in Building K293 from a 6-12 School to a 9-12 School Beginning in the 2016-2017 School Year',
        org_id: 'K497',
        bldg_id: 'K293',
        vote_date: '3/9/2016',
        at_scale_year: '2018-19',
        at_scale_enroll: 700,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Grade Truncation of Brooklyn School for Global Studies (15K429) in Building K293 from a 6-12 School to a 9-12 School Beginning in the 2016-2017 School Year',
        org_id: 'K368',
        bldg_id: 'K293',
        vote_date: '3/9/2016',
        at_scale_year: '2018-19',
        at_scale_enroll: 30,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Grade Truncation of Brooklyn School for Global Studies (15K429) in Building K293 from a 6-12 School to a 9-12 School Beginning in the 2016-2017 School Year',
        org_id: 'K129',
        bldg_id: 'K293',
        vote_date: '3/9/2016',
        at_scale_year: '2018-19',
        at_scale_enroll: 588,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Expansion of Success Academy Charter School – Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and One Site of an Existing District 75 Program P368K (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K429',
        bldg_id: 'K293',
        vote_date: '6/10/2015',
        at_scale_year: '2017-18',
        at_scale_enroll: 280,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Expansion of Success Academy Charter School – Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and One Site of an Existing District 75 Program P368K (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K497',
        bldg_id: 'K293',
        vote_date: '6/10/2015',
        at_scale_year: '2017-18',
        at_scale_enroll: 620,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Expansion of Success Academy Charter School – Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and One Site of an Existing District 75 Program P368K (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K129',
        bldg_id: 'K293',
        vote_date: '6/10/2015',
        at_scale_year: '2017-18',
        at_scale_enroll: 603,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Expansion of Success Academy Charter School – Cobble Hill (84K129) in Building K293 with Brooklyn School for Global Studies (15K429), School for International Studies (15K497), and One Site of an Existing District 75 Program P368K (75K368) Beginning in the 2016-2017 School Year',
        org_id: 'K368',
        bldg_id: 'K293',
        vote_date: '6/10/2015',
        at_scale_year: '2017-18',
        at_scale_enroll: 30,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of a New District High School\n(16K765) with Existing Schools Boys and Girls High School\n(16K455) and Research and Service High School (16K669) in\nBuilding K455 Beginning in 2014-2015',
        org_id: 'K455',
        bldg_id: 'K455',
        vote_date: '12/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 905,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of a New District High School\n(16K765) with Existing Schools Boys and Girls High School\n(16K455) and Research and Service High School (16K669) in\nBuilding K455 Beginning in 2014-2015',
        org_id: 'K669',
        bldg_id: 'K455',
        vote_date: '12/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 270,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of a New District High School\n(16K765) with Existing Schools Boys and Girls High School\n(16K455) and Research and Service High School (16K669) in\nBuilding K455 Beginning in 2014-2015',
        org_id: 'K765',
        bldg_id: 'K455',
        vote_date: '12/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 460,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Grades Six through Eight of\nExplore Exceed Charter School’s (84K333) with Existing Schools\nP.S. 375 Jackie Robinson School (17K375) and M.S. 352 Ebbets\nField Middle School (17K352) in Building K320 Beginning in\n2015-2016',
        org_id: 'K352',
        bldg_id: 'K320',
        vote_date: '10/30/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 285,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Grades Six through Eight of\nExplore Exceed Charter School’s (84K333) with Existing Schools\nP.S. 375 Jackie Robinson School (17K375) and M.S. 352 Ebbets\nField Middle School (17K352) in Building K320 Beginning in\n2015-2016',
        org_id: 'K333',
        bldg_id: 'K320',
        vote_date: '10/30/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 210,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Grades Six through Eight of\nExplore Exceed Charter School’s (84K333) with Existing Schools\nP.S. 375 Jackie Robinson School (17K375) and M.S. 352 Ebbets\nField Middle School (17K352) in Building K320 Beginning in\n2015-2016',
        org_id: 'K375',
        bldg_id: 'K320',
        vote_date: '10/30/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 731,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-Siting and Co-Location of Success Academy Charter School – Crown Heights (84K741) with Existing School P.S. 161 The Crown (17K161) in Building K161 Beginning in 2014-2015',
        org_id: 'K741',
        bldg_id: 'K161',
        vote_date: '10/15/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 540,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-Siting and Co-Location of Success Academy Charter School – Crown Heights (84K741) with Existing School P.S. 161 The Crown (17K161) in Building K161 Beginning in 2014-2015',
        org_id: 'K161',
        bldg_id: 'K161',
        vote_date: '10/15/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 390,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Uncommon Charter High School 3 with\nExisting Schools Brooklyn Generation School (18K566), Brooklyn\nTheatre Arts High School (18K567), Victory Collegiate High School\n(18K576), Brooklyn Bridge Academy (18K578), Academy for\nConservation and the Environment (18K637) and GED Plus (79Q950)\nin Building K515 Beginning in 2014-2015',
        org_id: 'K576',
        bldg_id: 'K515',
        vote_date: '10/15/2013',
        at_scale_year: '2019-20',
        at_scale_enroll: 350,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Uncommon Charter High School 3 with\nExisting Schools Brooklyn Generation School (18K566), Brooklyn\nTheatre Arts High School (18K567), Victory Collegiate High School\n(18K576), Brooklyn Bridge Academy (18K578), Academy for\nConservation and the Environment (18K637) and GED Plus (79Q950)\nin Building K515 Beginning in 2014-2015',
        org_id: 'K567',
        bldg_id: 'K515',
        vote_date: '10/15/2013',
        at_scale_year: '2019-20',
        at_scale_enroll: 405,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Uncommon Charter High School 3 with\nExisting Schools Brooklyn Generation School (18K566), Brooklyn\nTheatre Arts High School (18K567), Victory Collegiate High School\n(18K576), Brooklyn Bridge Academy (18K578), Academy for\nConservation and the Environment (18K637) and GED Plus (79Q950)\nin Building K515 Beginning in 2014-2015',
        org_id: 'K566',
        bldg_id: 'K515',
        vote_date: '10/15/2013',
        at_scale_year: '2019-20',
        at_scale_enroll: 290,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Uncommon Charter High School 3 with\nExisting Schools Brooklyn Generation School (18K566), Brooklyn\nTheatre Arts High School (18K567), Victory Collegiate High School\n(18K576), Brooklyn Bridge Academy (18K578), Academy for\nConservation and the Environment (18K637) and GED Plus (79Q950)\nin Building K515 Beginning in 2014-2015',
        org_id: 'K578',
        bldg_id: 'K515',
        vote_date: '10/15/2013',
        at_scale_year: '2019-20',
        at_scale_enroll: 205,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Uncommon Charter High School 3 with\nExisting Schools Brooklyn Generation School (18K566), Brooklyn\nTheatre Arts High School (18K567), Victory Collegiate High School\n(18K576), Brooklyn Bridge Academy (18K578), Academy for\nConservation and the Environment (18K637) and GED Plus (79Q950)\nin Building K515 Beginning in 2014-2015',
        org_id: 'K517',
        bldg_id: 'K515',
        vote_date: '10/15/2013',
        at_scale_year: '2019-20',
        at_scale_enroll: 745,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Uncommon Charter High School 3 with\nExisting Schools Brooklyn Generation School (18K566), Brooklyn\nTheatre Arts High School (18K567), Victory Collegiate High School\n(18K576), Brooklyn Bridge Academy (18K578), Academy for\nConservation and the Environment (18K637) and GED Plus (79Q950)\nin Building K515 Beginning in 2014-2015',
        org_id: 'Q950',
        bldg_id: 'K515',
        vote_date: '10/15/2013',
        at_scale_year: '2019-20',
        at_scale_enroll: 75,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Uncommon Charter High School 3 with\nExisting Schools Brooklyn Generation School (18K566), Brooklyn\nTheatre Arts High School (18K567), Victory Collegiate High School\n(18K576), Brooklyn Bridge Academy (18K578), Academy for\nConservation and the Environment (18K637) and GED Plus (79Q950)\nin Building K515 Beginning in 2014-2015',
        org_id: 'K637',
        bldg_id: 'K515',
        vote_date: '10/15/2013',
        at_scale_year: '2019-20',
        at_scale_enroll: 300,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of New District High School (19K764) with Multicultural High School (19K583), Academy of Innovative Technology (19K618), Brooklyn Lab School (19K639), and Cypress Hills Collegiate Preparatory School (19K659) in Building K420 Beginning in 2014-2015',
        org_id: 'K583',
        bldg_id: 'K420',
        vote_date: '10/15/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 350,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of New District High School (19K764) with Multicultural High School (19K583), Academy of Innovative Technology (19K618), Brooklyn Lab School (19K639), and Cypress Hills Collegiate Preparatory School (19K659) in Building K420 Beginning in 2014-2015',
        org_id: 'K764',
        bldg_id: 'K420',
        vote_date: '10/15/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 340,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of New District High School (19K764) with Multicultural High School (19K583), Academy of Innovative Technology (19K618), Brooklyn Lab School (19K639), and Cypress Hills Collegiate Preparatory School (19K659) in Building K420 Beginning in 2014-2015',
        org_id: 'K659',
        bldg_id: 'K420',
        vote_date: '10/15/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 400,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of New District High School (19K764) with Multicultural High School (19K583), Academy of Innovative Technology (19K618), Brooklyn Lab School (19K639), and Cypress Hills Collegiate Preparatory School (19K659) in Building K420 Beginning in 2014-2015',
        org_id: 'K618',
        bldg_id: 'K420',
        vote_date: '10/15/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 410,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of New District High School (19K764) with Multicultural High School (19K583), Academy of Innovative Technology (19K618), Brooklyn Lab School (19K639), and Cypress Hills Collegiate Preparatory School (19K659) in Building K420 Beginning in 2014-2015',
        org_id: 'K639',
        bldg_id: 'K420',
        vote_date: '10/15/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 400,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of The New American Academy Charter School (84KTBD) in Building K415 with Existing Schools Kurt Hahn Expeditionary School (18K569), It Takes a Village Academy (18K563), and Cultural Academy for the Arts and Sciences (18K629) Beginning in the 2013-2014 School Year',
        org_id: 'K736',
        bldg_id: 'K415',
        vote_date: '3/20/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 410,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of The New American Academy Charter School (84KTBD) in Building K415 with Existing Schools Kurt Hahn Expeditionary School (18K569), It Takes a Village Academy (18K563), and Cultural Academy for the Arts and Sciences (18K629) Beginning in the 2013-2014 School Year',
        org_id: 'K563',
        bldg_id: 'K415',
        vote_date: '3/20/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 440,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of The New American Academy Charter School (84KTBD) in Building K415 with Existing Schools Kurt Hahn Expeditionary School (18K569), It Takes a Village Academy (18K563), and Cultural Academy for the Arts and Sciences (18K629) Beginning in the 2013-2014 School Year',
        org_id: 'K569',
        bldg_id: 'K415',
        vote_date: '3/20/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 300,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of The New American Academy Charter School (84KTBD) in Building K415 with Existing Schools Kurt Hahn Expeditionary School (18K569), It Takes a Village Academy (18K563), and Cultural Academy for the Arts and Sciences (18K629) Beginning in the 2013-2014 School Year',
        org_id: 'K629',
        bldg_id: 'K415',
        vote_date: '3/20/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 325,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Phase-out of Sheepshead Bay High School (22K495) Beginning in 2013-2014',
        org_id: 'K495',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 0,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Phase-out of Sheepshead Bay High School (22K495) Beginning in 2013-2014',
        org_id: 'K611',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 460,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Phase-out of Sheepshead Bay High School (22K495) Beginning in 2013-2014',
        org_id: 'K738',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 640,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Phase-out of Sheepshead Bay High School (22K495) Beginning in 2013-2014',
        org_id: 'K739',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 640,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Phase-out of Sheepshead Bay High School (22K495) Beginning in 2013-2014',
        org_id: 'K811',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 15,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Phase-out of Sheepshead Bay High School (22K495) Beginning in 2013-2014',
        org_id: 'K630',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 270,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New District High School 22K611 (22K611) and a New District Transfer High School (22K630) with Sheepshead Bay High School (22K495), P811K@K495 (75K811), New Visions Charter High School for Applied Math and Science III\n(84KTBD), and New Visions Charter High School for the Humanities III\n(84KTBD) in School Building K495, Beginning in 2013-2014',
        org_id: 'K739',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 640,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New District High School 22K611 (22K611) and a New District Transfer High School (22K630) with Sheepshead Bay High School (22K495), P811K@K495 (75K811), New Visions Charter High School for Applied Math and Science III\n(84KTBD), and New Visions Charter High School for the Humanities III\n(84KTBD) in School Building K495, Beginning in 2013-2014',
        org_id: 'K495',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New District High School 22K611 (22K611) and a New District Transfer High School (22K630) with Sheepshead Bay High School (22K495), P811K@K495 (75K811), New Visions Charter High School for Applied Math and Science III\n(84KTBD), and New Visions Charter High School for the Humanities III\n(84KTBD) in School Building K495, Beginning in 2013-2014',
        org_id: 'K630',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 270,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New District High School 22K611 (22K611) and a New District Transfer High School (22K630) with Sheepshead Bay High School (22K495), P811K@K495 (75K811), New Visions Charter High School for Applied Math and Science III\n(84KTBD), and New Visions Charter High School for the Humanities III\n(84KTBD) in School Building K495, Beginning in 2013-2014',
        org_id: 'K611',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 460,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New District High School 22K611 (22K611) and a New District Transfer High School (22K630) with Sheepshead Bay High School (22K495), P811K@K495 (75K811), New Visions Charter High School for Applied Math and Science III\n(84KTBD), and New Visions Charter High School for the Humanities III\n(84KTBD) in School Building K495, Beginning in 2013-2014',
        org_id: 'K811',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 15,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of a New District High School 22K611 (22K611) and a New District Transfer High School (22K630) with Sheepshead Bay High School (22K495), P811K@K495 (75K811), New Visions Charter High School for Applied Math and Science III\n(84KTBD), and New Visions Charter High School for the Humanities III\n(84KTBD) in School Building K495, Beginning in 2013-2014',
        org_id: 'K738',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 640,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of New Visions Charter High\nSchool for Applied Math and Science III (84KTBD) and New Visions\nCharter High School for the Humanities III (84KTBD) with Sheepshead\nBay High School (22K495), P811K@K495 (75K811), a New District\nHigh School 22K611(22K611) and a New Transfer School (22K630), in\nSchool Building K495 Beginning in 2013-2014',
        org_id: 'K495',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of New Visions Charter High\nSchool for Applied Math and Science III (84KTBD) and New Visions\nCharter High School for the Humanities III (84KTBD) with Sheepshead\nBay High School (22K495), P811K@K495 (75K811), a New District\nHigh School 22K611(22K611) and a New Transfer School (22K630), in\nSchool Building K495 Beginning in 2013-2014',
        org_id: 'K811',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 15,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of New Visions Charter High\nSchool for Applied Math and Science III (84KTBD) and New Visions\nCharter High School for the Humanities III (84KTBD) with Sheepshead\nBay High School (22K495), P811K@K495 (75K811), a New District\nHigh School 22K611(22K611) and a New Transfer School (22K630), in\nSchool Building K495 Beginning in 2013-2014',
        org_id: 'K739',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 640,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of New Visions Charter High\nSchool for Applied Math and Science III (84KTBD) and New Visions\nCharter High School for the Humanities III (84KTBD) with Sheepshead\nBay High School (22K495), P811K@K495 (75K811), a New District\nHigh School 22K611(22K611) and a New Transfer School (22K630), in\nSchool Building K495 Beginning in 2013-2014',
        org_id: 'K630',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 270,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of New Visions Charter High\nSchool for Applied Math and Science III (84KTBD) and New Visions\nCharter High School for the Humanities III (84KTBD) with Sheepshead\nBay High School (22K495), P811K@K495 (75K811), a New District\nHigh School 22K611(22K611) and a New Transfer School (22K630), in\nSchool Building K495 Beginning in 2013-2014',
        org_id: 'K611',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 460,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Opening and Co-location of New Visions Charter High\nSchool for Applied Math and Science III (84KTBD) and New Visions\nCharter High School for the Humanities III (84KTBD) with Sheepshead\nBay High School (22K495), P811K@K495 (75K811), a New District\nHigh School 22K611(22K611) and a New Transfer School (22K630), in\nSchool Building K495 Beginning in 2013-2014',
        org_id: 'K738',
        bldg_id: 'K495',
        vote_date: '3/11/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 640,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Citizens of the World Charter School New York 2 (84KTBD) in Building K221 and Building K892 with Existing School P.S. 221 Toussaint L’Ouverture (17K221) Beginning in the 2013-2014 School Year',
        org_id: 'K221',
        bldg_id: 'K221',
        vote_date: '1/16/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 546,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of Citizens of the World Charter School New York 2 (84KTBD) in Building K221 and Building K892 with Existing School P.S. 221 Toussaint L’Ouverture (17K221) Beginning in the 2013-2014 School Year',
        org_id: 'K692',
        bldg_id: 'K221',
        vote_date: '1/16/2013',
        at_scale_year: '2017-18',
        at_scale_enroll: 432,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of a New Public Charter School Success Academy Charter School Brooklyn 5 (84KTBD) with Existing Schools Dr. Susan S. McKinney Secondary School of the Arts (13K265) and a District 75 Inclusion Program P369K@265K (75K369) in Building K265 Beginning in 2013-2014',
        org_id: 'K752',
        bldg_id: 'K265',
        vote_date: '12/20/2012',
        at_scale_year: '2017-18',
        at_scale_enroll: 566,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of a New Public Charter School Success Academy Charter School Brooklyn 5 (84KTBD) with Existing Schools Dr. Susan S. McKinney Secondary School of the Arts (13K265) and a District 75 Inclusion Program P369K@265K (75K369) in Building K265 Beginning in 2013-2014',
        org_id: 'K265',
        bldg_id: 'K265',
        vote_date: '12/20/2012',
        at_scale_year: '2017-18',
        at_scale_enroll: 480,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Co-location of a New Public Charter School Success Academy Charter School Brooklyn 5 (84KTBD) with Existing Schools Dr. Susan S. McKinney Secondary School of the Arts (13K265) and a District 75 Inclusion Program P369K@265K (75K369) in Building K265 Beginning in 2013-2014',
        org_id: 'K369',
        bldg_id: 'K265',
        vote_date: '12/20/2012',
        at_scale_year: '2017-18',
        at_scale_enroll: 24,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'Revise: The Proposed Re-siting of a District 75 School, 75K053, to Building K422 Beginning in the 2012-2013 School Year',
        org_id: 'K422',
        bldg_id: 'K422',
        vote_date: '5/23/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 580,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'Revise: The Proposed Re-siting of a District 75 School, 75K053, to Building K422 Beginning in the 2012-2013 School Year',
        org_id: 'K053',
        bldg_id: 'K422',
        vote_date: '5/23/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 90,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'Revise: The Proposed Re-siting of a District 75 School, 75K053, to Building K422 Beginning in the 2012-2013 School Year',
        org_id: 'K404',
        bldg_id: 'K422',
        vote_date: '5/23/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 580,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-siting of a District 75 School, 75K053, to Building K422 Beginning in the 2012-2013 School Year',
        org_id: 'K422',
        bldg_id: 'K422',
        vote_date: '3/21/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 580,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-siting of a District 75 School, 75K053, to Building K422 Beginning in the 2012-2013 School Year',
        org_id: 'K053',
        bldg_id: 'K422',
        vote_date: '3/21/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 95,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-siting of a District 75 School, 75K053, to Building K422 Beginning in the 2012-2013 School Year',
        org_id: 'K404',
        bldg_id: 'K422',
        vote_date: '3/21/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 580,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-siting, Grade Expansion, and Co-Location\nof Academy for Young Writers (14K404) with a New\nSecondary School (19K422) and a District 75 Program\n(75KTBD) at Building K422 Beginning in the 2012-2013\nSchool Year',
        org_id: 'K053',
        bldg_id: 'K422',
        vote_date: '1/18/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 95,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-siting, Grade Expansion, and Co-Location\nof Academy for Young Writers (14K404) with a New\nSecondary School (19K422) and a District 75 Program\n(75KTBD) at Building K422 Beginning in the 2012-2013\nSchool Year',
        org_id: 'K404',
        bldg_id: 'K422',
        vote_date: '1/18/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 580,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'The Proposed Re-siting, Grade Expansion, and Co-Location\nof Academy for Young Writers (14K404) with a New\nSecondary School (19K422) and a District 75 Program\n(75KTBD) at Building K422 Beginning in the 2012-2013\nSchool Year',
        org_id: 'K422',
        bldg_id: 'K422',
        vote_date: '1/18/2012',
        at_scale_year: '2018-19',
        at_scale_enroll: 580,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'Siting of Ocean Hill Collegiate and Leadership Prep Ocean Hill with Existing Schools in School Building K271',
        org_id: 'K644',
        bldg_id: 'K271',
        vote_date: '2/24/2010',
        at_scale_year: '2017-18',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'Siting of Ocean Hill Collegiate and Leadership Prep Ocean Hill with Existing Schools in School Building K271',
        org_id: 'K522',
        bldg_id: 'K271',
        vote_date: '2/24/2010',
        at_scale_year: '2017-18',
        at_scale_enroll: null,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'Siting of Ocean Hill Collegiate and Leadership Prep Ocean Hill with Existing Schools in School Building K271',
        org_id: 'K775',
        bldg_id: 'K271',
        vote_date: '2/24/2010',
        at_scale_year: '2017-18',
        at_scale_enroll: 630,
        bldg_id_additional: ''
      }, {
        url: 'http://www.schools.nyc.gov/about-us/leadership/panel-for-education-policy/pep-meetings-archive',
        title: 'Siting of Ocean Hill Collegiate and Leadership Prep Ocean Hill with Existing Schools in School Building K271',
        org_id: 'K777',
        bldg_id: 'K271',
        vote_date: '2/24/2010',
        at_scale_year: '2017-18',
        at_scale_enroll: 270,
        bldg_id_additional: ''
      }];
    },
    residentialDevelopments: function residentialDevelopments() {
      return [];
    },
    schoolsWithAction: function schoolsWithAction() {
      return [{
        id: 172,
        name: 'William Gardner School for the Amaaaazing',
        sdName: 'District 17 - Subdistrict 2',
        district: 17,
        hs_seats: 200,
        is_seats: 300,
        ps_seats: 50,
        cartodb_id: 23,
        subdistrict: 2
      }];
    },
    hsProjections: function hsProjections() {
      return [{
        hs: 79875,
        year: 2020,
        borough: 'Brooklyn'
      }];
    },
    hsStudentsFromHousing: 4802,
    futureEnrollmentProjections: function futureEnrollmentProjections() {
      return [{
        ms: 4368,
        ps: 9198,
        district: 17,
        school_year: '2020-21'
      }];
    },
    futureEnrollmentNewHousing: function futureEnrollmentNewHousing() {
      return [{
        level: 'PS',
        district: 17,
        students: 838,
        subdistrict: 2
      }, {
        level: 'MS',
        district: 17,
        students: 323,
        subdistrict: 2
      }, {
        level: 'PS',
        district: 17,
        students: 938,
        subdistrict: 1
      }, {
        level: 'MS',
        district: 17,
        students: 223,
        subdistrict: 1
      }];
    }
  });

  _exports.default = _default;
});