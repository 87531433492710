define("labs-ceqr/helpers/timeout", ["exports", "ember-needs-async/helpers/timeout"], function (_exports, _timeout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _timeout.default;
    }
  });
  Object.defineProperty(_exports, "timeout", {
    enumerable: true,
    get: function get() {
      return _timeout.timeout;
    }
  });
});