define("labs-ceqr/templates/components/public-schools/existing-conditions/existing-conditions-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qVm7rCQx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui pointing menu\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"item\",\"project.show.public-schools.existing-conditions.schools\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"    Public Schools Serving Study Area\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"right menu\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"item\",\"project.show.public-schools.existing-conditions.study-area\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"      Study Area\\n\"],[4,\"if\",[[24,[\"displayWarning\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"ui basic label orange\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"attention orange icon\"],[10,\"style\",\"margin: 0\"],[8],[9],[0,\"  \\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"item\",\"project.show.public-schools.existing-conditions.new-schools\",[24,[\"project\",\"id\"]]]],{\"statements\":[[0,\"      Newly Opened Schools\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"ui label \",[28,\"if\",[[28,\"gt\",[[24,[\"analysis\",\"newlyOpenedSchools\",\"length\"]],0],null],\"orange\",\"green\"],null]]]],[8],[0,\"\\n        \"],[1,[24,[\"analysis\",\"newlyOpenedSchools\",\"length\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/public-schools/existing-conditions/existing-conditions-steps.hbs"
    }
  });

  _exports.default = _default;
});