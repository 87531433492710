define("labs-ceqr/templates/project/show/transportation/tdf/planning-factors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ThqUCsE8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column sticky-transportation-bar\"],[8],[0,\"\\n\"],[4,\"sticky-element\",null,[[\"top\"],[79]],{\"statements\":[[0,\"      \"],[5,\"transportation/land-use-menu\",[],[[\"@tdfSection\",\"@model\"],[\"planning-factors\",[22,\"model\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui icon message\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"attention icon\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n        Explore Census Data for Residential and Office Land Uses\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        The CEQR Transportation chapter offers a census data explorer allowing you to select\\n      census tracts for your project and gather mode split data from ACS and CTPP data sources.\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[7,\"font\",true],[10,\"color\",\"red\"],[8],[0,\"We currently only offer data for Residential and Office land uses.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show/transportation/tdf/planning-factors.hbs"
    }
  });

  _exports.default = _default;
});