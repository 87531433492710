define("labs-ceqr/helpers/get-aggregate-value", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAggregateValue = getAggregateValue;
  _exports.default = void 0;

  /**
   * Helper for CensusTractsTable component that calculates an aggregate modal split estimate
   * for a given variable, for a full set of modal split data from all census tracts in a
   * study selection.
   */
  function getAggregateValue(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        allModalSplitData = _params[0],
        variables = _params[1];

    if (allModalSplitData && Array.isArray(allModalSplitData) && variables && Array.isArray(variables)) {
      return allModalSplitData.reduce(function (runningSum, modalSplit) {
        return runningSum + variables.reduce(function (runningSum, variable) {
          return modalSplit[variable] ? runningSum + parseFloat(modalSplit[variable].value) : runningSum;
        }, 0);
      }, 0);
    }
  }

  var _default = Ember.Helper.helper(getAggregateValue);

  _exports.default = _default;
});