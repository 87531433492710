define("labs-ceqr/helpers/human-readable-census-tract", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanReadableCensusTract = humanReadableCensusTract;
  _exports.default = void 0;
  var COUNTY_LOOKUP = {
    '085': 'Richmond County',
    '081': 'King\'s County',
    '061': 'New York County',
    '047': 'Queen\'s County',
    '005': 'Bronx County'
  };
  /**
   * Helper for CensusTractsTable that composes a human-readable version of a census
   * tract from the geoid, for use as column headers
   */

  function humanReadableCensusTract(params
  /* , hash */
  ) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        geoid = _params[0];

    var countyCode = geoid.substring(2, 5);
    var county = COUNTY_LOOKUP[countyCode];
    var tractCode = geoid.substring(5);
    return "".concat(tractCode, ", ").concat(county);
  }

  var _default = Ember.Helper.helper(humanReadableCensusTract);

  _exports.default = _default;
});