define("labs-ceqr/app", ["exports", "ember-load-initializers", "@sentry/browser", "ember-resolver", "labs-ceqr/config/environment"], function (_exports, _emberLoadInitializers, Sentry, _emberResolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Sentry.init({
    dsn: _environment.default.SENTRY_DSN,
    integrations: [new Sentry.Integrations.Ember()],
    environment: _environment.default.environment
  });
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});