define("labs-ceqr/templates/components/mapbox/layer-registry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SkkbU6Fi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"registerLayer\",\"unregisterLayer\"],[[28,\"action\",[[23,0,[]],\"_registerLayer\"],null],[28,\"action\",[[23,0,[]],\"_unregisterLayer\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/mapbox/layer-registry.hbs"
    }
  });

  _exports.default = _default;
});