define("labs-ceqr/helpers/map-color-for", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "labs-ceqr/utils/mapColors"], function (_exports, _slicedToArray2, _mapColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapColorFor = mapColorFor;
  _exports.default = void 0;

  function mapColorFor(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        level = _ref2[0];

    return _mapColors.default.styleFor(level);
  }

  var _default = Ember.Helper.helper(mapColorFor);

  _exports.default = _default;
});