define("labs-ceqr/utils/mapColors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ps: '#414F70',
    psis: '#9197AE',
    is: '#A1A66B',
    ishs: '#82735C',
    hs: '#566144',
    styleFor: function styleFor(level) {
      return Ember.String.htmlSafe("color: ".concat(this[level.toLowerCase()]));
    }
  };
  _exports.default = _default;
});