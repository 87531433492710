define("labs-ceqr/templates/project/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GDww3b04",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[23,0,[\"model\",\"project\",\"name\"]]],[[\"separator\",\"replace\"],[\" \",true]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n    \"],[5,\"project-header\",[],[[\"@project\"],[[22,\"project\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/project/show.hbs"
    }
  });

  _exports.default = _default;
});