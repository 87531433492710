define("labs-ceqr/models/project", ["exports", "ember-data", "labs-ceqr/utils/boroughToAbbr"], function (_exports, _emberData, _boroughToAbbr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    editors: _emberData.default.hasMany('user', {
      inverse: 'editable_projects'
    }),
    viewers: _emberData.default.hasMany('user', {
      inverse: 'viewable_projects'
    }),
    projectPermissions: _emberData.default.hasMany('project-permissions'),
    dataPackage: _emberData.default.belongsTo('dataPackage'),
    viewOnly: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    updated_by: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    buildYear: _emberData.default.attr('number'),
    ceqrNumber: _emberData.default.attr('string'),
    bbls: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    bblsVersion: _emberData.default.attr('string'),
    bblsGeojson: _emberData.default.attr(''),
    borough: Ember.computed('boroCode', function () {
      switch (this.boroCode) {
        case 1:
          return 'Manhattan';

        case 2:
          return 'Bronx';

        case 3:
          return 'Brooklyn';

        case 4:
          return 'Queens';

        case 5:
          return 'Staten Island';

        default:
          return null;
      }
    }),
    boroCode: Ember.computed('bbls.[]', function () {
      if (this.bbls.length === 0) return null;
      return parseFloat(this.bbls.firstObject.charAt(0));
    }),
    boroAbbr: Ember.computed('borough', function () {
      return (0, _boroughToAbbr.default)(this.borough);
    }),
    // Analysis Framework
    totalUnits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    seniorUnits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    affordableUnits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    commercialLandUse: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    industrialLandUse: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    communityFacilityLandUse: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    parkingLandUse: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // Should probably move to PublicSchoolsAnalysis.
    // Any computed property that is specifically relevant to a given analyses should be its responsibility
    netUnits: Ember.computed('totalUnits', 'seniorUnits', function () {
      return this.get('totalUnits') - this.get('seniorUnits');
    }),
    // Analyses Relationships
    publicSchoolsAnalysis: _emberData.default.belongsTo('public-schools-analysis'),
    transportationAnalysis: _emberData.default.belongsTo('transportation-analysis'),
    communityFacilitiesAnalysis: _emberData.default.belongsTo('community-facilities-analysis')
  });

  _exports.default = _default;
});