define("labs-ceqr/routes/project/show/public-schools/no-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    controllerName: 'project'
  });

  _exports.default = _default;
});