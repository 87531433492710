define("labs-ceqr/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);

      this._loadCurrentUser(); // Not sure this is working
      // test environment doesn't have crisp injected


      if (window.$crisp) {
        window.$crisp.push(['set', 'user:email', [this.currentUser.user.email]]);
      }
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get('currentUser').load().catch(function () {
        return _this.get('session').invalidate();
      });
    },
    actions: {
      error: function error(_error) {
        if (_error.status === '401') {
          this.session.invalidate();
          this.replaceWith('login');
        }
      }
    }
  });

  _exports.default = _default;
});