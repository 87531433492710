define("labs-ceqr/components/public-schools/existing-conditions/table-existing-conditions", ["exports", "labs-ceqr/utils/mapColors"], function (_exports, _mapColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeSchoolsLevel: 'ps',
    activeSdId: null,
    mapColors: _mapColors.default,
    styleFor: function styleFor(level) {
      return _mapColors.default.styleFor(level);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var analysis = this.analysis;

      if (analysis) {
        this.set('activeSdId', analysis.subdistricts[0].id);
      }
    },
    // ceqr_school_buildings dataset is a combination of two datasets with different metadata: bluebook dataset and lcgms dataset
    bluebookMetadata: Ember.computed('analysis.dataPackage.schemas.ceqr_school_buildings.sources', function () {
      return this.analysis.get('dataPackage.schemas').ceqr_school_buildings.sources.find(function (source) {
        return source.name === 'bluebook';
      });
    }),
    table: Ember.computed('activeSdId', 'activeSchoolsLevel', function () {
      var _this = this;

      if (this.activeSchoolsLevel === 'hs') {
        return this.analysis.subdistrictTotals.findBy('level', 'hs');
      }

      return this.analysis.subdistrictTotals.find(function (total) {
        return parseFloat(total.id) === parseFloat(_this.activeSdId) && total.level === _this.activeSchoolsLevel;
      });
    }),
    buildings: Ember.computed('table', function () {
      return this.table.buildings.sortBy('org_id');
    }),
    sd: Ember.computed('activeSdId', function () {
      return this.analysis.subdistricts.findBy('id', this.activeSdId);
    }),
    actions: {
      setSdId: function setSdId(sdId) {
        this.set('activeSdId', sdId);
      }
    }
  });

  _exports.default = _default;
});