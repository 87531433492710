define("labs-ceqr/fragments/public-schools/LevelTotals", ["exports", "labs-ceqr/utils/round", "labs-ceqr/utils/sumMapBy"], function (_exports, _round, _sumMapBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * LevelTotals is an EmberObject that aggregates the output of a list of SubdistrictTotals
   * Three LevelTotals obejcts are created per analysis, one for each school level: ps, is, hs.
   *
   * @constructor
   * @param {SubdistrictTotal[]} subdistrictTotals - Array of SubdistrictTotal objects filtered by relevant level
   * @param {integer} studentsWithAction - Number of students to be added by project under analysis
   */
  var _default = Ember.Object.extend({
    // Existing Conditions
    existingConditionsEnrollment: Ember.computed('subdistrictTotals', function () {
      return (0, _sumMapBy.default)(this.get('subdistrictTotals').mapBy('enrollmentTotal'));
    }),
    existingConditionsCapacity: Ember.computed('subdistrictTotals', function () {
      return (0, _sumMapBy.default)(this.get('subdistrictTotals').mapBy('capacityTotal'));
    }),
    existingConditionsUtilization: Ember.computed('existingConditionsEnrollment', 'existingConditionsCapacity', function () {
      return (0, _round.default)(this.existingConditionsEnrollment / this.existingConditionsCapacity, 4);
    }),
    existingConditionsSeats: Ember.computed('subdistrictTotals', function () {
      return (0, _sumMapBy.default)(this.get('subdistrictTotals').mapBy('seatsTotal'));
    }),
    // No Action
    noActionEnrollment: Ember.computed.alias('enrollNoActionTotal'),
    noActionEnrollmentDelta: Ember.computed.alias('enrollNoActionDeltaTotal'),
    noActionCapacity: Ember.computed.alias('capacityNoActionTotal'),
    noActionCapacityDelta: Ember.computed('subdistrictTotals', function () {
      return (0, _sumMapBy.default)(this.get('subdistrictTotals').mapBy('capacityNoActionDelta'));
    }),
    noActionUtilization: Ember.computed.alias('utilizationNoActionTotal'),
    noActionSeats: Ember.computed.alias('seatsNoActionTotal'),
    // With Action
    withActionEnrollment: Ember.computed.alias('enrollWithActionTotal'),
    withActionEnrollmentDelta: Ember.computed.alias('enrollWithActionDeltaTotal'),
    withActionCapacity: Ember.computed.alias('capacityWithActionTotal'),
    withActionCapacityDelta: Ember.computed.alias('newSchoolSeats'),
    withActionUtilization: Ember.computed.alias('utilizationWithActionTotal'),
    withActionSeats: Ember.computed.alias('seatsWithActionTotal'),
    // Individual Attribute Totals
    scaCapacityIncrease: Ember.computed('subdistrictTotals', function () {
      return (0, _sumMapBy.default)(this.get('subdistrictTotals').mapBy('scaCapacityIncrease'));
    }),
    // Older methods
    enrollTotal: Ember.computed('subdistrictTotals', function () {
      return this.get('subdistrictTotals').mapBy('enroll').reduce(function (acc, value) {
        return acc + parseFloat(value);
      }, 0);
    }),
    studentsTotal: Ember.computed('subdistrictTotals', function () {
      return this.get('subdistrictTotals').mapBy('students').reduce(function (acc, value) {
        return acc + parseFloat(value);
      }, 0);
    }),
    enrollNoActionTotal: Ember.computed('enrollTotal', 'studentsTotal', function () {
      return this.get('enrollTotal') + this.get('studentsTotal');
    }),
    enrollWithActionTotal: Ember.computed('subdistrictTotals', function () {
      return this.get('enrollNoActionTotal') + this.studentsWithAction;
    }),
    enrollNoActionDeltaTotal: Ember.computed('enrollNoActionTotal', 'enrollTotal', function () {
      return this.get('enrollNoActionTotal') - this.get('enrollTotal');
    }),
    enrollWithActionDeltaTotal: Ember.computed('enrollWithActionTotal', 'enrollTotal', function () {
      return this.get('enrollWithActionTotal') - this.get('enrollTotal');
    }),
    enrollDifferenceTotal: Ember.computed('enrollWithActionTotal', 'enrollNoActionTotal', function () {
      return this.get('enrollWithActionTotal') - this.get('enrollNoActionTotal');
    }),
    enrollDeltaDifferenceTotal: Ember.computed('enrollNoActionDeltaTotal', 'enrollWithActionDeltaTotal', function () {
      return this.get('enrollWithActionDeltaTotal') - this.get('enrollNoActionDeltaTotal');
    }),
    capacityNoActionTotal: Ember.computed('subdistrictTotals', function () {
      return this.get('subdistrictTotals').mapBy('capacityNoAction').reduce(function (acc, value) {
        return acc + parseFloat(value);
      }, 0);
    }),
    newSchoolSeats: Ember.computed('subdistrictTotals', function () {
      return this.get('subdistrictTotals').mapBy('newCapacityWithAction').reduce(function (acc, value) {
        return acc + parseFloat(value);
      }, 0);
    }),
    capacityWithActionTotal: Ember.computed('capacityNoActionTotal', function () {
      return this.get('capacityNoActionTotal') + this.get('newSchoolSeats');
    }),
    seatsNoActionTotal: Ember.computed('enrollNoActionTotal', 'capacityNoActionTotal', function () {
      return this.get('capacityNoActionTotal') - this.get('enrollNoActionTotal');
    }),
    seatsWithActionTotal: Ember.computed('subdistrictTotals', function () {
      return this.get('capacityWithActionTotal') - this.get('enrollWithActionTotal');
    }),
    seatsDifferenceTotal: Ember.computed('seatsNoActionTotal', 'seatsWithActionTotal', function () {
      return this.get('seatsWithActionTotal') - this.get('seatsNoActionTotal');
    }),
    utilizationNoActionTotal: Ember.computed('enrollNoActionTotal', 'capacityNoActionTotal', function () {
      return (0, _round.default)(this.get('enrollNoActionTotal') / this.get('capacityNoActionTotal'), 4);
    }),
    utilizationWithActionTotal: Ember.computed('enrollWithActionTotal', 'capacityNoActionTotal', function () {
      return (0, _round.default)(this.get('enrollWithActionTotal') / this.get('capacityWithActionTotal'), 4);
    }),
    utilizationChangeTotal: Ember.computed('utilizationWithActionTotal', 'utilizationNoActionTotal', function () {
      return (0, _round.default)(this.get('utilizationWithActionTotal') - this.get('utilizationNoActionTotal'), 4);
    }),
    impact: Ember.computed('utilizationChangeTotal', 'utilizationWithActionTotal', function () {
      return this.get('utilizationChangeTotal') >= 0.05 && this.get('utilizationWithActionTotal') >= 1;
    }),
    // Mitigation
    mitigateSeatCount: Ember.computed('enrollWithActionTotal', 'utilizationNoActionTotal', 'capacityWithActionTotal', function () {
      var seatsToMitigateUtilization = this.get('enrollWithActionTotal') - (this.get('capacityWithActionTotal') - 1);
      var seatsToMitigateChange = Math.ceil(this.get('enrollWithActionTotal') / (this.get('utilizationNoActionTotal') + 0.0499) - this.get('capacityWithActionTotal'));
      return seatsToMitigateUtilization < seatsToMitigateChange ? seatsToMitigateUtilization : seatsToMitigateChange;
    }),
    mitigateUnitCount: Ember.computed('mitigateSeatCount', function () {
      return Math.ceil(this.get('mitigateSeatCount') / this.get('subdistrictTotals')[0].get('studentMultiplier'));
    })
  });

  _exports.default = _default;
});