define("labs-ceqr/templates/components/transportation/tdf/truck-directional-distribution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V+Z6M5qy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"ui header\"],[10,\"style\",\"margin-bottom: 10px\"],[8],[0,\"Truck Directional Distribution\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"ui celled table\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"two wide\"],[8],[0,\"Day\"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"two wide\"],[8],[0,\"In\"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"two wide\"],[8],[0,\"Out\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n      \"],[5,\"transportation/tdf/in-out-splits/table-row\",[],[[\"@data\",\"@label\"],[[24,[\"data\",\"allDay\"]],\"All Day\"]]],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"3\"],[8],[0,\"\\n          \"],[5,\"transportation/tdf/table-note\",[],[[\"@tableName\",\"@factor\"],[\"truckDirectionalDistribution\",[22,\"factor\"]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ceqr/templates/components/transportation/tdf/truck-directional-distribution.hbs"
    }
  });

  _exports.default = _default;
});