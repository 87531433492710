define("labs-ceqr/mirage/fixtures/dummy-mapbox-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    version: 8,
    name: 'NYCPlanning Positron',
    metadata: {
      attribution: 'Based on OpenMapTiles Positron style: https://github.com/openmaptiles/positron-gl-style'
    },
    center: [-73.869324, 40.815888],
    zoom: 9.72,
    bearing: 0,
    pitch: 0,
    sources: {},
    sprite: '',
    glyphs: 'https://tiles.planninglabs.nyc/fonts/{fontstack}/{range}.pbf',
    layers: []
  };
  _exports.default = _default;
});